import React from "react";
import img_language from "@/assets/images/language.png";
import { switchLanguage, selectedIntl } from "@/slices/IntlSlice";
import { useAppSelector, useAppDispatch } from "@/store/hooks";

const LanguageSwitch: React.FC = () => {
  const language = useAppSelector(selectedIntl);
  const dispatch = useAppDispatch();

  const handleSwitchLanguage = () => {
    const lang = language === "en" ? "zh" : "en";
    dispatch(switchLanguage(lang));
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "10px",
        right: "10px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
      }}
      onClick={handleSwitchLanguage}>
      <img src={img_language} alt="" style={{ width: 16, height: 16, marginRight: 8 }} />
      <span>{language === "en" ? "简体中文" : "English"}</span>
    </div>
  );
};

export default LanguageSwitch;
