import React, { ReactNode, Suspense } from "react";
import {
  IInjectIntlProps,
  IPercentageData,
  IAssetSituationalData,
  IFlexibleProducts,
  Intl,
  IkycInfo,
  IHistory
} from "@/model/index";
import { Row, Col, Spin, Button } from "antd";
import styles from "./Home.module.less";
import { getAssetsSituational, yieldProducts } from "@/services/request";
import WithCoinList from "@/components/withCoinList/WithCoinList";
import KYCInfoLayout from "@/components/withKycInfo/WithKYCInfo";
import { getWalletInfo } from "@/services/request";
import {
  getSpecifiedDate,
  transformTime,
  appendScript,
  getDeviceId,
  getCookie,
  reportGaInfo
} from "@/utils/index";
import { ANT_DESDIGN_CHART_SRC } from "@/config/constants";

// import AssetSituationalInFunds from "./components/AssetSituationalInFunds";
// import AssetSituationalNoDeposit from "./components/AssetSituationalNoDeposit";
// import AssetDistribution from "./components/AssetDistribution";
// import MarketsCard from "./components/MarketsCard";
// import Announcements from "./components/Announcements";
// import PopularSavings from "./components/PopularSavings";
// import WellStrategy from "./components/WellStrategy";

const AssetDistribution = React.lazy(
  () => import(/* webpackChunkName: "AssetDistribution" */ "./components/AssetDistribution")
);
const MarketsCard = React.lazy(
  () => import(/* webpackChunkName: "MarketsCard" */ "./components/MarketsCard")
);
const Announcements = React.lazy(
  () => import(/* webpackChunkName: "Announcements" */ "./components/Announcements")
);
const AssetSituationalInFunds = React.lazy(
  () =>
    import(/* webpackChunkName: "AssetSituationalInFunds" */ "./components/AssetSituationalInFunds")
);
const AssetSituationalNoDeposit = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AssetSituationalNoDeposit" */ "./components/AssetSituationalNoDeposit"
    )
);
const WellStrategy = React.lazy(
  () => import(/* webpackChunkName: "WellStrategy" */ "./components/WellStrategy")
);
const PopularSavings = React.lazy(
  () => import(/* webpackChunkName: "PopularSavings" */ "./components/PopularSavings")
);

interface IState {
  pieColors: string[];
  processColors: string[];
  percentageData: IPercentageData[];
  showModal: boolean;
  limit: number;
  offset: number;
  yieldProducts: IFlexibleProducts[];
  disabledPrev: boolean;
  disabledNext: boolean;
  assetsSituationalData: IAssetSituationalData[];
  walletInfo: {
    spotAmount: number;
    strategyAmount: number;
    yieldAmount: number;
    totalAmount: number;
    computeTime: number | string;
  };
  assetLoaded: boolean; // 钱包接口和资产打点接否是否均已完成请求
  isDeposit: boolean; // 是否入金
  walletInfoLoaded: boolean; // 钱包接口是否完成请求
}
interface IProps {
  fetchAllCoinList: () => void;
  showGuideModal: () => void;
  auditStatus: number | string;
  getKYCInfo: () => Promise<IkycInfo>;
}

class Overview extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    const intl: Intl = window.__intl__.messages;

    this.state = {
      pieColors: ["#303342", "#303342", "#303342"],
      processColors: ["#D24A4A", "#F17E00", "#B01DFC"],
      assetsSituationalData: [],
      showModal: props?.auditStatus == 1 ? false : true,
      limit: 30,
      offset: 0,
      isDeposit: false,
      disabledPrev: true,
      disabledNext: true,
      yieldProducts: [],
      walletInfoLoaded: false,
      assetLoaded: false,
      walletInfo: {
        spotAmount: 0,
        strategyAmount: 0,
        yieldAmount: 0,
        totalAmount: 0,
        computeTime: ""
      },
      percentageData: [
        {
          key: "spotAmount",
          type: intl["overview.myAsset.spot"],
          value: 0,
          percent: 0
        },
        {
          key: "yieldAmount",
          type: intl["menu.yield"],
          value: 0,
          percent: 0
        },
        {
          key: "strategyAmount",
          type: intl["menu.strategies"],
          value: 0,
          percent: 0
        }
      ]
    };
    // appendScript(ANT_DESDIGN_CHART_SRC);
    this.handleClick = this.handleClick.bind(this);
    this.reOrgAssetaSituationData = this.reOrgAssetaSituationData.bind(this);
  }

  render(): ReactNode {
    const { percentageData, walletInfo } = this.state;
    const {
      assetsSituationalData,
      pieColors,
      processColors,
      yieldProducts,
      disabledPrev,
      disabledNext,
      isDeposit,
      assetLoaded,
      walletInfoLoaded
    } = this.state;
    const popularSavings = yieldProducts.slice(2, 5);
    const intl: Intl = window.__intl__.messages;

    return (
      <section className={styles.overview}>
        <Suspense
          fallback={
            <div className={styles.marketsCard + " middleAlign"}>
              <Spin />
            </div>
          }>
          <MarketsCard volume24={intl["home.24h.volume"]} />
        </Suspense>
        <Row gutter={16}>
          <Col span={8}>
            <Suspense
              fallback={
                <div className={styles.assetDisbution + " middleAlign"} style={{ height: "480" }}>
                  <Spin />
                </div>
              }>
              <AssetDistribution
                walletInfoLoaded={walletInfoLoaded}
                totalNumber={Number(walletInfo.totalAmount)}
                chartData={percentageData}
                percentageData={percentageData}
                pieColors={pieColors}
                processColors={processColors}
              />
            </Suspense>
          </Col>
          <Col span={16}>
            <Suspense
              fallback={
                <div className={styles.notice + " middleAlign"}>
                  <Spin />
                </div>
              }>
              <Announcements />
            </Suspense>
            {(!assetLoaded || !walletInfoLoaded) && (
              <section className={styles.assetSituational}>
                <Spin spinning={!assetLoaded}> </Spin>
              </section>
            )}
            <Suspense
              fallback={
                <div className={styles.assetSituational + " middleAlign"}>
                  <Spin />
                </div>
              }>
              {assetLoaded && walletInfoLoaded && isDeposit && (
                <AssetSituationalInFunds
                  disabledPrev={disabledPrev}
                  disabledNext={disabledNext}
                  getAssetSituationalData={this.getAssetSituationalData}
                  assetsSituationalData={assetsSituationalData}
                />
              )}
            </Suspense>
            <Suspense
              fallback={
                <div className={styles.assetSituational + " middleAlign"}>
                  <Spin />
                </div>
              }>
              {assetLoaded && walletInfoLoaded && !isDeposit && (
                <AssetSituationalNoDeposit
                  auditStatus={this.state.showModal}
                  showKYCModal={this.props.showGuideModal}
                  yieldProducts={yieldProducts.slice(0, 2)}
                />
              )}
            </Suspense>

            <div className={styles.quickEentrance}>
              <div className={styles.entrance} onClick={() => this.handleClick("/wallet/deposit")}>
                <img
                  className={styles.quickIcon}
                  src={require("@/assets/images/ic_deposit@2x.png").default}
                  alt=""
                />
                {intl["home.deposit"]}
              </div>
              <div
                className={styles.entrance}
                onClick={() =>
                  window.__history__.push({
                    pathname: "/trade",
                    query: { coinCode: "USDT_USD" }
                  })
                }>
                <img
                  className={styles.quickIcon}
                  src={require("@/assets/images/ic_purchase@2x.png").default}
                  alt=""
                />
                {intl["home.deposit.fiat"]}
              </div>
              <div className={styles.entrance} onClick={() => this.handleClick("/trade")}>
                <img
                  className={styles.quickIcon}
                  src={require("@/assets/images/ic_trade@2x.png").default}
                  alt=""
                />
                {intl["menu.trade"]}
              </div>
              <div className={styles.entrance} onClick={() => this.handleClick("/intelligence")}>
                <img
                  className={styles.quickIcon}
                  src={require("@/assets/images/calander@2x.png").default}
                  alt=""
                />
                <p>{intl["intelligence.dailyBrifing"]}</p>
              </div>
            </div>
          </Col>
        </Row>
        <div className={styles.automaticEntrance}>
          <p className={styles.title}>{intl["overview.savings.automatic.title"]}</p>
          <p className={styles.container}>{intl["overview.savings.automatic.content"]}</p>
          <Button
            type="primary"
            className={styles.automaticEntranceBtn}
            onClick={() => {
              // window.__history__.push("/yield/investmentPlan");
              window.__history__.push({
                pathname: "/yield",
                query: { tabType: "2" }
              });
            }}>
            {intl["button.start.now"]}
          </Button>
        </div>

        <Suspense
          fallback={
            <div className={styles.wellStrategy + " middleAlign"} style={{ height: "533px" }}>
              <Spin />
            </div>
          }>
          <WellStrategy />
        </Suspense>

        <Suspense
          fallback={
            <div className={styles.popularSavings + " middleAlign"} style={{ height: "521px" }}>
              <Spin />
            </div>
          }>
          <PopularSavings
            yieldProducts={popularSavings}
            getFlexibleProducts={this.getFlexibleProducts}
            type={isDeposit ? "inFunds" : "noDeposit"}
          />
        </Suspense>
      </section>
    );
  }

  shouldComponentUpdate(nextProps, nextState): boolean {
    let shouldUpdate = false;
    if (
      JSON.stringify(this.state.pieColors) != JSON.stringify(nextState.pieColors) ||
      JSON.stringify(this.state.assetsSituationalData) !=
        JSON.stringify(nextState.assetsSituationalData) ||
      JSON.stringify(nextState.yieldProducts != this.state.yieldProducts) ||
      this.state.disabledPrev != nextState.disabledPrev ||
      this.state.disabledNext != nextState.disabledNext ||
      this.state.isDeposit != nextState.isDeposit ||
      this.state.assetLoaded != nextState.assetLoaded ||
      this.state.walletInfoLoaded != nextState.walletInfoLoaded ||
      this.props.auditStatus != nextProps.auditStatus
    ) {
      shouldUpdate = true;
    }
    return shouldUpdate;
  }

  componentDidMount: () => void = async () => {
    this.setState({
      assetLoaded: false,
      walletInfoLoaded: false
    });
    this.getCurrentWalletInfo();
    this.getAssetSituationalData(0);
    this.getFlexibleProducts();
    this.props.fetchAllCoinList();
    // 登录上报设备信息
    !localStorage.getItem("deviceId") && reportGaInfo(2);
    // 首页调用一次，确保KYC信息是当前登陆账号的
    const res = await this.props.getKYCInfo();
    if (this.props?.auditStatus != 1) {
      if (res?.auditStatus != 1) {
        this.props.showGuideModal();
      }
      this.setState({
        showModal: res?.auditStatus != 1
      });
    }
  };

  componentWillUnmount: () => void = () => {
    this.setState({
      assetLoaded: false,
      isDeposit: false,
      walletInfoLoaded: false
    });
  };

  // 获取钱包数据
  getCurrentWalletInfo: () => void = async () => {
    getWalletInfo().then((res) => {
      this.setState({
        walletInfoLoaded: true
      });
      if (res?.code == "0") {
        let walletInfo = { ...(res?.data ?? []) };
        const percentageData = [...this.state.percentageData];
        // 策略持仓不加YTD, 录入时已经计算过了， edit 2022/02/09 by elaine
        percentageData.map((item) => {
          if (Object.keys(walletInfo).includes(item.key)) {
            item.value = Number(walletInfo[item.key]);
            let value = walletInfo[item.key];
            item.percent = walletInfo.totalAmount > 0 ? (value * 100) / walletInfo.totalAmount : 0;
          }
        });
        this.setState(
          {
            walletInfo: walletInfo,
            percentageData: percentageData
          },
          () => {
            this.checkIsInFunds();
          }
        );
        if (walletInfo.totalAmount > 0) {
          this.setState({
            isDeposit: true,
            pieColors: ["#D24A4A", "#F17E00", "#B01DFC"]
          });
        } else {
          this.setState({
            isDeposit: false,
            pieColors: ["#303342", "#303342", "#303342"]
          });
        }
      }
    });
  };

  handleClick(route: string, coinCode?: string): void {
    window.__history__.push({
      pathname: route,
      query: { currency: coinCode }
    });
  }

  reOrgAssetaSituationData() {
    const { assetsSituationalData, walletInfo } = this.state;
    let { totalAmount, computeTime } = walletInfo;
    if (!computeTime) return;
    computeTime = transformTime(Number(computeTime))?.split(" ")[0];
    const timeBefore = getSpecifiedDate(-1).split(" ")[0];
    if (assetsSituationalData.length == 0) {
      const situationalData = [
        {
          asset: 0,
          assetDate: timeBefore
        },
        {
          asset: totalAmount,
          assetDate: computeTime
        }
      ];
      this.setState({
        disabledNext: true,
        disabledPrev: true,
        assetsSituationalData: situationalData
      });
    }
    if (assetsSituationalData.length == 1) {
      const data1 = {
        asset: totalAmount,
        assetDate: computeTime
      };
      const situationalData = [...assetsSituationalData, data1];
      this.setState({
        assetsSituationalData: situationalData
      });
    }
  }

  // 判断是否已入金
  checkIsInFunds() {
    const { assetsSituationalData, walletInfo } = this.state;
    if (walletInfo?.totalAmount == 0 && assetsSituationalData.length == 0) {
      return this.setState({
        isDeposit: false
      });
    }
    if (walletInfo?.totalAmount != 0 || assetsSituationalData.length > 0) {
      this.reOrgAssetaSituationData();
      this.setState({
        isDeposit: true
      });
    }
  }

  // 理财产品列表
  getFlexibleProducts: () => void = () => {
    const param = {
      limit: 5
    };
    yieldProducts(param)
      .then((res) => {
        if (res?.code == "0") {
          this.setState({
            yieldProducts: res?.data?.financingProducts ?? []
          });
        }
      })
      .catch((e) => {})
      .finally(() => {});
  };

  // Asset situation每天00:00打点一次
  getAssetSituationalData: (timeRange: string | number, offset?: number) => void = async (
    timeRange = 0,
    customerOffset = 0
  ) => {
    let { limit, offset: currentoffset, disabledNext, disabledPrev } = this.state;
    let assetsSituationalData = [];
    currentoffset = currentoffset + customerOffset * limit;
    const param = {
      type: Number(timeRange),
      offset: currentoffset,
      limit: limit
    };
    await getAssetsSituational(param)
      .then((res) => {
        if (res?.code == 0) {
          this.checkIsInFunds();
          assetsSituationalData = res?.data?.rows ?? [];
          const _customerOffset = currentoffset / limit;
          // 如果偏移量为0 ，则为最新的30条数据， 禁止向后
          if (currentoffset == 0) {
            disabledNext = true;
            // 如果偏移量为0 ，并且返回的数据小于limit， 禁止向前
            if (assetsSituationalData.length < limit) {
              disabledPrev = true;
            } else {
              // 如果偏移量为0， 总数大于limit, 则允许向前
              disabledPrev = false;
            }
          }
          // 向前
          if (customerOffset > 0) {
            disabledNext = false;
            if (res?.data?.totalCount > currentoffset + assetsSituationalData.length) {
              disabledPrev = false;
            } else {
              disabledPrev = true;
            }
          }

          if (customerOffset < 0) {
            disabledPrev = false;
            if (
              res?.data?.totalCount > currentoffset + assetsSituationalData.length &&
              currentoffset > 0
            ) {
              disabledNext = false;
            } else {
              disabledNext = true;
            }
          }

          this.setState(
            {
              assetsSituationalData: assetsSituationalData.reverse(),
              disabledNext: disabledNext,
              disabledPrev: disabledPrev
            },
            () => {
              this.reOrgAssetaSituationData();
            }
          );
        }
      })
      .finally(() => {
        this.setState({
          offset: currentoffset,
          assetLoaded: true
        });
      });
  };
}
export default WithCoinList(KYCInfoLayout(Overview));
//export default (KYCInfoLayout(Overview));
