import React, { Suspense } from "react";

import { Intl } from "@/model/index";
import { Col, Spin } from "antd";
import styles from "./Strategy.module.less";
import { getStrategyList } from "@/services/request";

const StrategyItem = React.lazy(
  () => import(/* webpackChunkName: "StrategyItem" */ "@/components/strategy/StrategyItem")
);

interface IProps {}

interface IState {
  strategyList: any[];
  loading: boolean;
}
class Strategy extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      strategyList: [],
      loading: false
    };
  }

  componentDidMount() {
    // this.setState({ loading: true });
    // 默认不分页 显示全部策略
    let params = {
      limit: 100,
      nextToken: null
    };
    getStrategyList(params)
      .then((res) => {
        if (res?.code == 0) {
          this.setState({ strategyList: res?.data?.rows ?? [] });
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }
  render(): React.ReactNode {
    const { strategyList, loading } = this.state;
    const intl: Intl = window.__intl__.messages;
    const title: string = intl["strategies.title"];
    return (
      <div className={styles.savings + " " + "customer"}>
        <div className="customerTop">
          <div className="top">
            <Col span={12}>
              <div>
                <p className="title">{title}</p>
              </div>
            </Col>
          </div>
        </div>
        <Spin spinning={loading}>
          <Suspense
            fallback={
              <div className={styles.strategy + " middleAlign"}>
                <Spin />
              </div>
            }>
            <div className="section">
              <div className={styles.strategy} style={{ marginTop: "34px" }}>
                <div className={styles.strategyList}>
                  {strategyList.map((item, index) => (
                    <div className={styles.strategyListItem} key={index}>
                      <StrategyItem key={item.id} data={item} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Suspense>
        </Spin>
      </div>
    );
  }
}
export default Strategy;
