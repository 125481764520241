export default {
  available: "Available",
  logout: "Log Out",
  resetPsw: "Reset Password",
  failed: "Failed",
  canceled: "Canceled",
  settled: "Settled",
  unsettled: "Unsettled",
  copied: "Copied",
  go: "Go",
  registered: "Registered", // 状态
  verified: "Verified", // 状态
  chinese: "Chinese",
  english: "English",
  "empty.list": "No available data",

  /* 菜单 */
  "menu.home": "Home",
  "menu.yield": "Yield",
  "menu.trade": "Trade",
  "menu.strategies": "Strategies",
  "menu.intelligence": "Intelligence",
  "menu.history": "My Investment",
  "menu.wallet": "Wallet",

  /* button按钮 */
  "button.deposit": "Deposit",
  "button.deposit.crypto.currency": "Deposit Crypto",
  "button.deposit.fiat.currency": "Deposit Fiat - USDC",
  "button.deposit.fiat.circle": "Deposit Fiat via Circle",
  "button.withdraw.crypto.currency": "Withdraw Crypto",
  "button.withdraw.fiat.circle": "Withdraw Fiat via Circle",
  "button.transfer.from.bank": "Transfer Fiat via Circle",
  "button.cancel": "Cancel",
  "button.processing": "Cancel Processing",
  "button.confirm": "Confirm",
  "button.ok": "OK",
  "button.resubmit": "Re-submit",
  "button.continue": "Continue",
  "button.view": "View",
  "button.add": "Add",
  "button.search": "Search",
  "button.manage": "Manage",
  "button.refresh": "Refresh",
  "button.submit": "Submit",
  "button.next": "Next",
  "button.invest": "Invest",
  "button.next.step": "Next Step",
  "button.start.now": "Start Now",
  "button.noCode": "Continue without invitation code",
  "button.confirm.submit": "Confirm & Submit",
  "button.confirm.subscribe": "Confirm Subscribe",
  "button.delete": "Delete",
  "button.sure.delete": "Sure to Delete",
  "button.close": "Close",
  "button.closed": "Closed",
  "button.loadMore": "Load More…",
  "button.back": "Back",
  "button.subscribe": "Subscribe",
  "button.redeem": "Redeem",
  "button.submit.application": "Submit Application",
  "button.apply": "Apply",
  "button.turn.on": "Turn On",
  "button.turn.off": "Turn Off",
  "button.copy": "Copy",
  "button.authorize": "Authorize",
  "button.reject": "Reject",
  "button.my.invitation": "My Invitation",
  "button.my.auto.invest.plan": "My Auto-Invest Plan",
  "button.comingSoon": "Coming Soon",

  /* 登录注册 */
  hiddenAmount: "****",
  register: "Register Account",
  "register.now": "Register Now",
  register01: "Please fill in your invitation code.",
  inviteErrCode: "Your invitation code is incorrect, please check it and try again.",
  register03: "Invitation Code",
  "register.back": "Back",
  "register.name": "Name",
  "register.email": "Email Address",
  "register.password": "Set Password",
  "register.passwordConfirm": "Confirm Password",
  "register.verify": "Verification Code",
  "register.sendverify": "Send Code",
  "register.resend": "Resend",
  "register.enterCode": "Please enter your code",
  "register.email.incorrect": "Email error",
  "register.t&c.01": "I have read and agreed to",
  "register.t&c.02": "Aspen Digital User Service Agreement",
  "register.t&c.03": "Aspen Digital Privacy Policy",
  "register.t&c.04": "and",
  "register.sub.title": "Multi-login Registration",
  "register.sub.main": "Main Account",
  "register.sub.email": "Multi-login Email Address",
  "reset.login": "Reset Login Password",
  "reset.login.confirm": "Confirm Again",
  "reset.login.text":
    "The password is a combination of 6-40 uppercase letters, lowercase letters and numbers.",
  "new.email": "Enter new email address",
  "new.password": "New Password",
  "old.password": "Current password",
  "different.password": "Please input a different new password",
  "confirm.password": "Confirm New Password",
  "forget.password": "Forgot Password",
  "forget.password.text": "First you need to enter your account name below",
  "forget.account": "Account Name",
  "forget.email.label": "Account Email",
  login: "Login",
  "logout.out": "Logged out,please log in again.",
  "login.text": "Welcome to Aspen Digital",
  "login.text.CMS": "ASPEN DIGITAL CMS Login",
  "login.text.SAS": "SAS Login",
  "login.email": "Email Address",
  "login.email.verify.code": "Email Verification Code",
  "login.google.verify.code": "Google Verification Code",
  "login.password": "Password",
  "login.forget": "Forgot Password",
  "login.noAccount": "No account yet?",
  "login.network.error": "Network error, please try again later.",
  "login.title": "Your Digital Asset Management Platform",
  "login.title.CMS": "Client Management System",
  "login.title.SAS": "Service Account System",
  "link.to.login": "Go to Login",

  /* KYC */
  "kyc.rejected": "Reject Reason",
  "kyc.basic.info": "Basic Information",
  "kyc.individual.fullname": "Full Name",
  "kyc.individual.age": "Age",
  "kyc.individual.gender": "Gender",
  "kyc.individual.birth": "Date of Birth",
  "kyc.individual.nationality": "Nationality",
  "kyc.individual.passportId": "Passport/ID Number",
  "kyc.individual.cardId": "Passport/ID card number",
  "kyc.individual.contact": "Contact Information",
  "kyc.individual.address": "Residential Address",
  "kyc.individual.funds": [
    {
      label: "Annual Income (USD)",
      name: "annualIncome",
      options: [
        "0 - 1,000,000",
        "1,000,001 - 5,000,000",
        "5,000,001 - 10,000,000",
        "More than 10,000,000"
      ]
    },
    {
      label: "Primary Source of Income",
      name: "sourceOfIncome",
      options: [
        "Self-Employed / Business Income",
        "Employment Income",
        "Investment",
        "Inheritance",
        "Gift",
        "Bank Loan"
      ]
    },
    {
      label: "Source of Investment Funds",
      name: "sourceOfFund",
      options: ["Client’s Investment", "Business Income", "Family Wealth", "Inheritance"],
      tips: "Amount of funds available at any time"
    },
    {
      label: "Liquid Assets (USD)",
      name: "liquidAssets",
      options: [
        "0 - 1,000,000",
        "1,000,001 - 5,000,000",
        "5,000,001 - 10,000,000",
        "More than 10,000,000"
      ],
      tips: "Amount of funds available at any time"
    },
    {
      label: "Total Assets (USD)",
      name: "totalAssets",
      options: [
        "0 - 1,000,000",
        "1,000,001 - 5,000,000",
        "5,000,001 - 10,000,000",
        "More than 10,000,000"
      ],
      tips: "Total asset quota, including stocks, bonds, funds, investment gold and other valuable investment products. It does not include the pledged assets, cars, real estate, jewelry and other consumer investment products."
    }
  ],
  "kyc.certify.institutions.name": "Company Name",
  "kyc.certify.institutions.authorizedRepresentative": "Authorized Representative",
  "kyc.certify.institutions.authorizedName": "Representative Name",
  "kyc.certify.institutions.authorizedTitle": "Representative Title",
  "kyc.certify.institutions.authorizedPhone": "Phone Number",
  "kyc.certify.institutions.authorizedEmail": "Representative Email",
  "kyc.certify.institutions.business.nature": "Business Nature (MC)",
  "kyc.certify.institutions.business.nature.tips":
    "Please provide a detailed description of your entity's business activities (including industry, services provided, typical customer profile, jurisdictions of operation, nature of payments accepted, etc.)",
  "kyc.certify.institutions.taxIdentificationNumber": "TIN (BR Number)",
  "kyc.certify.institutions.taxIdentificationNumberTips":
    "Taxpayer Identification Number ep:123-45-6789,If not applicable, please input Nil",
  "kyc.certify": "KYC",
  "kyc.certify.institutions.CountryIncorporation": "Country of Incorporation",
  "kyc.certify.institutions.address": "Contact Address", //Principle address of office
  "kyc.certify.institutions.entity.type": "Institution Type",
  "kyc.certify.institutions.entityTypes": ["Private Limited", "Public Limited", "Unincorporated"],
  "kyc.certify.institutions.fund": "Source of Wealth",
  "kyc.certify.institutions.fundTypes": [
    {
      label: "Source of Funding",
      name: "sourceOfFund",
      options: ["Investment", "Business Income"],
      tips: ""
    },
    {
      label: "Liquid Assets (USD)",
      name: "liquidAssets",
      options: [
        "0 - 1,000,000",
        "1,000,001 - 5,000,000",
        "5,000,001 - 10,000,000",
        "More than 10,000,000"
      ],
      tips: "Amount of funds available at any time"
    },
    {
      label: "Total Assets (USD)",
      name: "totalAssets",
      options: [
        "0 - 1,000,000",
        "1,000,001 - 5,000,000",
        "5,000,001 - 10,000,000",
        "More than 10,000,000"
      ],
      tips: "Total asset quota, including stocks, bonds, funds, investment gold and other valuable investment products. It does not include the pledged assets, cars, real estate, jewelry and other consumer investment products."
    }
  ],
  "kyc.certify.tips":
    "Please fill in the following information carefully in order to pass the KYC audit smoothly",
  "kyc.certify.comfirm.tips":
    "After checking that the KYC information filled in is correct, click the button below to submit",
  "kyc.step1": "Fill in KYC information",
  "kyc.step2": "Confirm KYC information",
  "kyc.step3": "Complete",
  "kyc.information": "KYC Information",
  "kyc.certify.location": "Location",
  "kyc.institutions": "Institutions",
  "kyc.individuals": "Individuals",
  "kyc.not.certified.text": "Not certified",
  "kyc.not.certified.tips": "Please select your profile to proceed with the KYC certification",
  "kyc.certified.text": "Certified",
  "kyc.rejected.text": "Rejected",
  "kyc.confirm": "Please confirm KYC information",
  "kyc.submit.info": "We have received your KYC information",
  "kyc.info.back": "Back to Motify",
  "kyc.status.under.review": "Under Review",
  "kyc.btn.modify": "Modify and Submit",
  "kyc.status.under.status": "Status",
  "kyc.informations": "Your KYC informations",
  "kyc.empty.tips": "This field is required",
  "kyc.gender.male": "Male",
  "kyc.gender.female": "Female",

  /* 交易 */
  "trade.chart.time": "Time",
  "trade.chart.tradingview": "TradingView",
  "trade.chart.origin": "Origin",
  "trade.chart.24h.up&down": "24h up&down",
  "trade.chart.24h.hign&low": "24h high - low",
  "trade.chart.24h.volume": "24h volume",
  "trade.chart.kline": "K-Line",
  "trade.chart.depth": "Depth",
  "trade.orderbook.title": "Order Book",
  "trade.openorder": "Open Order",
  "trade.history": "Order History",
  "trade.placeOrder.title": "Place Order",
  "trade.placeOrder.tips.market": "Maker Fee: 0.1%",
  "trade.placeOrder.tips.taker": "Taker Fee: 0.1%",
  "trade.placeOrder.buy": "BUY",
  "trade.placeOrder.sell": "SELL",
  "trade.placeOrder.limit": "Limit",
  "trade.placeOrder.market": "Market",
  "trade.placeOrder.balance": "Balance",
  "trade.placeOrder.price": "Price",
  "trade.placeOrder.amount": "Amount",
  "trade.placeOrder.total": "Total",
  "trade.placeOrder.buybtn": "Buy",
  "trade.placeOrder.sellbtn": "Sell",
  "trade.placeOrder.modal.text.first": "You are trading on behalf of the client",
  "trade.placeOrder.modal.text.second": "Do you confirm placing the order?",
  "trade.order.list.view.all": "View All ",
  "trade.order.detail": "Order details",
  "trade.order.cancel.modal.title": "Cancellation Confirmation",
  "trade.order.cancel.modal.content.self": "Do you confirm to cancel the order?",
  "trade.order.cancel.modal.content.not.self":
    "Do you confirm to cancel the transaction ordered by {type} ({email})?",

  /* 首页 */
  "home.24h.volume": "24h volume",
  "home.deposit": "Deposit Crypto",
  "home.deposit.fiat": "Purchase USDT",
  "home.kyc.title": "KYC Verification Required",
  "home.kyc.content": "Please complete KYC to deposit funds",
  "home.kyc.action": "Continue KYC",
  "home.no.depsoit": "You have not deposited any assets yet",
  "home.no.depsoit.sub": "Explore select high-yield products, such as:",
  "home.no.depsoit.modal.sub":
    "You have not deposited any assets yet, please go to your account to deposit first.",
  "overview.assetDistribution.title": "Portfolio Distribution", // 资产分配
  "overview.assetDistribution.total": "Total Assets",
  "overview.myAsset.day": "Day",
  "overview.myAsset.week": "Week",
  "overview.myAsset.month": "Month",
  "overview.myAsset.year": "Year",
  "overview.assetSituational.title": "Portfolio Performance", // 资产状况
  "overview.myAsset.title": "My Asset",
  "overview.myAsset.spot": "Spot",
  "overview.myAsset.action.successful": "Successful",
  "action.deposit": "Deposit",
  "action.redeem": "Redeem",
  "action.subscribe": "Subscribe",
  "action.view": "View",
  "action.cancel": "Cancel",
  "agency.tips.text": "You are operating Aspen Digital account for client ",
  "agency.tips.text.sub": "You are operating Aspen Digital account for ",
  "overview.myAsset.action.withdraw": "Withdraw",
  "overview.savings.title": "Yield",
  "overview.savings.automatic.title": "Automatic Investment Plan",
  "overview.savings.automatic.content":
    "Set up your auto-invest plan to buy crypto in DCA strategy.",
  "overview.savings.view.all": "View All Products ",
  "overview.savings.item.type": "Savings",
  "overview.savings.item.apy": "Estimated APY",
  "overview.savings.item.btn.subscribe": "Subscribe",
  "overview.announcement.new": "New",
  "overview.strategy.view.all": "View All Strategy ",
  "overview.savings.value.date": "Interest bearing date",
  "overview.savings.maximum": "Maximum",
  "overview.savings.apy": "APY",
  "overview.savings.lots": "Lots",
  "overview.savings.redeem": "Redeem",
  "overview.savings.redeem.tips": "Redeem quantity is returned to Spot account in 24 hours",
  "overview.savings.subscribe": "Subscribe",
  // TODO: 下面三条无使用，页面中被注释了
  "overview.savings.lot.size": "Lot size",
  "overview.savings.lot.term.tips": "I have read and agree to",
  "overview.savings.lot.term.service": "Saving Service Aggrement",

  /* 理财 */
  "savings.flexible": "Flexible",
  "savings.fixed": "Fixed",
  "savings.autoInvestment": "Auto-Investment",
  "savings.append.balance": "Balance",
  "savings.redeem.available": "Available",
  "savings.append.amount": "Amount",
  "savings.history.subscription": "Subscription",
  "savings.history.redemption": "Redemption",
  "savings.history.success": "Success",
  "savings.history.failed": "Failed",
  "savings.history.pending": "Pending",
  "savings.auto.compound.tips":
    "If this function is on, your available balance in spot will be automatically subscribed to flexible deposit.",
  "savings.auto.compound.on":
    "When Auto-Compound is ON, all available balance in your spot account will auto-subscribe flexible deposit.",
  "savings.auto.compound.off":
    "Are you sure to turn OFF Auto-Compound? If it is OFF, interest gained will not auto-subscribe flexible deposit.",
  "savings.auto.purchase.tips":
    "When turned on, the daily interest from stable coins will be used to automatically purchase cryptocurrency.",
  "savings.automatic.name": "{coin} Automatic Investment Plan",
  "savings.automatic.my.title": "My Automatic Investment Plan",
  "savings.automatic.my.fail.tips":
    "The automatic investment plan is terminated by the system because the automatic transfer from your wallet to the investment plan failed in 3 times due to insufficient wallet balance.",
  "savings.automatic.add.breadcrumb.first": "Automatic Investment Plan",
  "savings.automatic.add.breadcrumb.second": "Add a New Plan",
  "savings.automatic.add.title": "Add an Automatice Investment Plan",
  "savings.automatic.add.choose.plan": "Choose an investment plan",
  "savings.automatic.add.title.amount.cycle": "Amount and Cycle",
  "savings.automatic.add.available": "Available Amount:",
  "savings.automatic.add.amount.cycle": "Investment Amount Per Cycle",
  "savings.automatic.add.cycle": "Investment Cycle",
  "savings.automatic.add.success": "Subscription Successful",
  "savings.automatic.add.fail": "Subscription Failed",
  "savings.automatic.add.error.tips.first": "Your account balance is insufficient.",
  "savings.automatic.add.error.tips.second": "The minimum deposit amount is",
  "savings.automatic.add.error.tips.third": "The maximum deposit amount is",
  "savings.automatic.modal.title": "Automatic Investment Plan",
  "savings.automatic.modal.denomintaion": "Denomintaion", // 原Cryptocurrency
  "savings.automatic.modal.price": "Price",
  "savings.automatic.modal.amount": "Amount",
  "savings.automatic.modal.ratio": "Ratio",
  "savings.automatic.modal.amount.cycle": "Investment Amount Per Cycle",
  "savings.automatic.modal.cycle": "Investment Cycle",
  "savings.automatic.modal.investment.amount": "Investment Amount",
  "savings.automatic.modal.fee": "Fee",
  "savings.automatic.modal.revenueRate": "Estimated Return Rate",
  "savings.automatic.modal.date": "Most Recent Date of Fee Payment",
  "savings.automatic.modal.transfer": "Transfer into Flexible Yield",
  "savings.automatic.modal.transfer.tips":
    "The traded amount in the cryptocurrency supported in yield will be automatically transfer into yield account.",
  "savings.automatic.modal.cancel.title": "Cancel Automatic Investment Plan",
  "savings.automatic.modal.cancel.content":
    "Are you sure to cancel the automatic investment plan? Once you cancel it, automatic investment for your selected cryptocurrency will be stopped.",
  "savings.automatic.history.btn": "Investment History",
  "savings.automatic.history.Comfirm.time": "Comfirm Time",
  "savings.automatic.history.TxID": "TxID",
  "fixed.deposit.subscribe.fail": "Subscription Failed",
  "savings.fixed.list.days": "Days",
  "savings.fixed.list.btn.subscribe": "Subscribe",
  "savings.fixed.list.btn.sellOut": "Sell Out",
  "savings.fixed.list.btn.cutOff": "Cut Off",
  "savings.fixed.list.btn.comingSoon": "Coming Soon",
  "savings.fixed.lockUp": "Lock-Up",
  "savings.fixed.location.lockUp": "Lock-Up",
  "savings.fixed.lockUp.subscribe": "Lock-Up Subscribe",
  "savings.fixed.subscription.unit": "Subscription Unit",
  "savings.fixed.input.placeholder": "Enter Subscription Unit",
  "savings.fixed.available.unit": "Available Unit",
  "savings.fixed.available.unit.tips": "It is based on max available balance in spot account.",
  "savings.fixed.lots": "Lots",
  "savings.fixed.total.amount": "Total Amount",
  "savings.fixed.max.subscription.unit": "Max Subscription Unit",
  "savings.fixed.estimated.interest": "Estimated Interest",
  "savings.fixed.estimated.interest.tips":
    "It shows the interest corresponding to the subscription amount.",
  "savings.fixed.max": "MAX",
  "savings.fixed.transfer.flexible": "Transfer into Flexible Deposit",
  "savings.fixed.transfer.flexible.tips":
    "When the lock-up period ends, the funds and interest will be transferred to flexible.",
  "savings.fixed.auto.subscribe.next.cycle": "Auto-Subscribe to the Next Cycle",
  "savings.fixed.auto.subscribe.next.cycle.tips":
    "When the fixed deposit lock-up period ends, the subscription amount here and the interest will be automatically subscribed to the next cycle of the same product (if next cycle is not available, the amount and interest will be transferred to spot).",
  "savings.fixed.details": "Details",
  "savings.fixed.subscription": "Subscription",
  "savings.fixed.cut.off": "Cut-Off Time",
  "savings.fixed.start": "Start: ",
  "savings.fixed.end": "End: ",
  "savings.fixed.interests": "Interests",
  "savings.fixed.Transfer.time": "Transfer to Spot or Flexible Within the Day",
  "savings.fixed.lock.period": "Locked-Up Period",
  "savings.fixed.days": "Days",
  "savings.fixed.total.available.amount": "Total Available Amount",
  "savings.fixed.per.unit": "Amount Per Unit",
  "savings.fixed.subscription.amount": "Subscription Amount",
  "savings.fixed.depisit.apy": "Fixed Deposit APY",
  "savings.fixed.flexible.deposit.apy": "Flexible Deposit APY",
  "savings.fixed.tiered.apy": "Tiered APY",
  "savings.fixed.error.tips.first": "Minimum subscription unit is 1. Please enter again.",
  "savings.fixed.error.tips.second":
    "Insufficient balance. Please enter the subscription amount again.",
  "savings.fixed.error.tips.third": "The available subscription unit is {remainingLots}",
  "savings.fixed.error.tips.fourth": "Maximum subscription unit is {personalShareLimit}",
  "savings.fixed.error.tips.fifth": "Subscription unit exceeds {personalShareMinLimit}",
  "fixed.deposit.product.subscribe.not.begin": "Fixed deposit subscription has not yet started.",
  "savings.modal.flexible.subscribe.title": "Subscribe to {currency} Yield",
  "savings.modal.title.turn.on.auto.compound": "Turn On Auto-Compound",
  "savings.modal.title.turn.off.auto.compound": "Turn Off Auto-Compound",
  "savings.modal.title.off.auto.purchase.tip": "Please turn off “Auto-Purchase“",
  "savings.auto.compound.off.auto.purchase.tip.content":
    "Auto-purchase is currently ON, please turn it off before turning Auto-compund ON.",
  "savings.modal.title.turn.on.auto.purchase":
    "Auto-purchase cryptocurrency by interest from {currency} flexible deposit",
  "savings.modal.title.turn.off.auto.purchase": "Turn off Auto-purchase by {currency} Interest",
  "savings.modal.flexible.balance": "Flexible Yield Balance: ",
  "savings.modal.daily.interest": "Daily Interest: ",
  "savings.modal.cryptocurrency.to.purchase": "Cryptocurrency to Purchase: ",
  "savings.modal.auto.purchase.time": "Auto-purchase Time: ",
  "savings.modal.interest.used.to.purchase": "Interest Used to Purchase: ",
  "savings.modal.auto.purchase.on.content.tip":
    "The daily {currency} interest from flexible deposit will be used to auto-purchase cryptocurrency if it fulfills the minimum purchase amount. You can turn off this function at anytime.",
  "savings.modal.auto.purchase.off.content.tip":
    "Auto-purchase of cryptocurrency by {currency} interest will be stopped when this function is turned off. Do you confirm to turn it off ? ",
  "savings.modal.select.cryptocurrency.to.purchase": "Please select a cryptocurrency to purchase! ",
  "savings.modal.auto.purchase.interest.not.enough.tip":
    "The interest from {currency} flexible deposit does not fulfill the minimum purchase amount requirement of 1. Please increase your {currency} flexible deposit amount before turning on this function.",
  "savings.modal.auto.purchase.success": "Auto-purchase Successfully",
  "savings.modal.auto.purchase.success.tip":
    "You have turned on Auto-purchase. The Auto-compound function will be turned off.",
  "savings.modal.auto.purchase.toast": "You have turned {type} Auto-purchase.",
  "savings.modal.auto.purchase.time.value": "Everyday 08:30-09:00",

  /* 资讯 */
  "intelligence.dailyBrifing": "Daily Briefing",
  "intelligence.loadMore": "Load More",
  "intelligence.announcement": "Latest News",
  "intelligence.nothingMore": "No updates available",

  /* 钱包 */
  wallet: "Wallet",
  "wallet.total": "Total Assets",
  "wallet.accountRisk": "Strategy YTD Portfolio Performance",
  "wallet.spot.account": "Spot Account",
  "wallet.type.stategy": "Strategy Account",
  "wallet.type.savings": "Yield Account",
  "wallet.viewDetails": "View Details",
  "wallet.history": "history",
  "wallet.yield.flexible": "Flexible",
  "wallet.yield.fixed": "Fixed",
  "wallet.strategies.ytd.tips":
    "Year to date (YTD) refers to the period of time beginning the first day of the current calendar year or fiscal year up to the current date.",
  "wallet.table.spot.tips.totalAmount":
    "Includes available assets in wallet and frozen assets due to withdrawal, subscription, redemption, trading, etc",
  "wallet.table.spot.tips.available":
    "Available asset that can be withdraw, subscribed to, exchanged or used directly",
  "wallet.table.spot.tips.frozen":
    "Asset that are in flexible/locked savings or funds that are current under review. Also incloudes unreceived amounts",
  "wallet.table.yield.tips.totalAmount": "Total cost/amount/ of your product",
  "wallet.table.yield.tips.cumulativeInterest": "Total Incurred Interest",
  "wallet.table.noStategies.tips": "No available position",
  "wallet.table.withdraw.tips": "{coinCode} withdrawal unsupported",
  "wallet.table.withdraw.aum.title": "Asset Management Fee Notification",
  "wallet.table.withdraw.aum.tips": "There are unsettled records of asset management fee.",
  "wallet.table.withdraw.aum.ask.tips": "Confirm payment and withdraw？",
  "wallet.table.withdraw.aum.fail.tips": "Please replenish USDC before withdrawal.",
  "wallet.table.deposit.title": "Deposit Method",
  "wallet.table.withdraw.title": "Withdraw Method",
  "wallet.deposit.btc": " Deposit",
  "wallet.deposit.btc.title": "BTC",
  "wallet.deposit.btc.select": "Select Coin",
  "wallet.deposit.btc.select.network": "Select Network",
  "wallet.minimum.deposit": "Minimum Deposit: ",
  "wallet.deposit.btc.address": " Deposit Address",
  "wallet.deposit.btc.tag": " Tag",
  "wallet.deposit.btc.qrcode.tips": "Scan to Get {currentCoin} Address",
  "wallet.deposit.btc.tips1": "Scan the code or copy the address to deposit.",
  "wallet.deposit.btc.tips2": "Supports assets on the {currentCoin} blockchain.",
  "wallet.deposit.btc.notice": "Notice",
  "wallet.deposit.btc.notice.first":
    "· Kindly only send  {currentCoin} to this address. Sending any other coin or token to this address may result in the loss of your deposit.",
  "wallet.deposit.btc.notice.second":
    "· Coins will be deposited into your account after {depositNetwork} network confirmations.Your assets will not be available for withdrawal until the {availableNetwork}th confirmation.",
  "wallet.deposit.history": "Deposit History",
  "wallet.deposit.history.viewall": "View All",
  "wallet.deposit.modal.title": "Reminder",
  "wallet.deposit.modal.content":
    "{currency} currently does not support deposit, please choose another crypto to deposit.",
  "wallet.deposit.USDC.method.tip": "Please select a method to deposit USDC",
  "wallet.withdraw.USDC.method.tip": "Please select a method to withdraw USDC",
  "wallet.withdraw.history": "Withdraw History",
  "wallet.withdraw.success.tips": "Withdraw submitted successful",
  "wallet.withdraw.failed.tips": "Withdraw submitted failed",
  "wallet.withdraw.get.bank": "Get bank list fail",
  "coin.address.invalid": "Invalid address",
  "2009": "Exceed daily withdrawal limit", // 电子货币超出单笔上限code
  "2005": "Minimum one time withdrawal limit", // 电子货币小于单笔上限code
  "2007": "Exceed one time withdrawal limit", // 电子货币超出单笔上限code
  "5210": "Exceed daily withdrawal limit", // 法币超出单日上限code
  "wallet.withdraw.limit": "Your withdrawal limit is:",
  "wallet.withdraw.amount": "Withdrawal Amount",
  "wallet.withdraw.max": "MAX",
  "wallet.withdraw.amount.placeholder": "Available 1.23456 BTC",
  "wallet.withdraw.notice": "Notice:",
  "wallet.withdraw.notice.content": [
    "· Only withdraw to the <span>'Bank Account'</span> is supported, please add the account to the <span>'Bank List'</span> before withdrawing.",
    "· Withdrawal will be completed within 1-2 business days.",
    "· If you have any questions, please contact your relationship manager or send email to support@aspendigital.co."
  ],
  "wallet.withdraw.title": "{currentCoin} Withdrawal",
  "wallet.withdraw.btc.address": "Withdrawal Address",
  "wallet.withdraw.btc.address.placeholder": "Please input a withdrawal Address",
  "wallet.withdraw.amount.error.tips.first": "The minimum withdrawal amount is",
  "wallet.withdraw.amount.error.tips.second": "Your withdrawal limit is",
  "wallet.withdraw.amount.error.tips.third": "Your available withdrawal amout is ",
  "wallet.withdraw.amount.error.tips.fourth": "Your per time available withdrawal amount is",
  "wallet.withdraw.amount.error.tips.fifth": "Your weekly available withdrawal amount is",
  "wallet.withdraw.amount.error.tips.sixth": "Your monthly available withdrawal amount is",
  "wallet.withdraw.fiat.address": "Bank Account",
  "wallet.withdraw.fiat.bind": "Bind a New Bank Account",
  "wallet.withdraw.fiat.placeholder": "Please choose a Bank Card ",
  "wallet.withdraw.fee": "Fee: {fee}",
  "wallet.withdraw.usdc.notice.tips": "Currently, you can withdraw USD through Circle USDC.",
  "wallet.withdraw.usdc.notice.content": [
    "· You can only withdraw to the bank account previously registered with your account.",
    "· Fiat will be transferred to your bank account in 1-3 business days.",
    "· Bank charges and transaction fees will be deducted.",
    "· If you have any questions, please contact your relationship manager or send email to support@aspendigital.co."
  ],
  "wallet.circle.title": "Deposit Fiat - USDC",
  "wallet.circle.add.title": "Add Bank Account",
  "wallet.circle.notice": "Notice:",
  "wallet.circle.bank.information": "Bank Information",
  "wallet.circle.bank.name": "Bank Name",
  "wallet.circle.swift": "Swift Code",
  "wallet.circle.country": "Country / Region",
  "wallet.circle.city": "City",
  "wallet.circle.bank.address": "Bank Address",
  "wallet.circle.bank.account": "Bank Account",
  "wallet.circle.account.number": "Account Number",
  "wallet.circle.account.information": "Account Information",
  "wallet.circle.name": "Full Name",
  "wallet.circle.postal": "Postal Code",
  "wallet.circle.address": "Address",
  "wallet.circle.bank.statements": "Bank Statements",
  "wallet.circle.upload.sgin": "Upload Bank Statements",
  "wallet.circle.upload.tips": [
    "· Ensure bank statements are clearly visible.",
    "· Ensure the bank statements you uploaded match with the bank information you previously provided."
  ],
  "wallet.circle.add.notice.content": [
    "· Please upload bank statements dated within the last 3 months.",
    "· The billing information must be consistent with your account information or the bank account verification may be rejected."
  ],
  "wallet.circle.add.modal.title": "Bank Account Addition",
  "wallet.circle.add.modal.content":
    "Please add your bank account infomation before deposit or withdraw via Circle.",
  "wallet.circle.max.deposit.amount": "Max Deposit Amount",
  "wallet.circle.pin": "PIN",
  "wallet.circle.deposit.notice.content":
    "Please enter the PIN in message to payee, othewise the deposit will be failed.",
  "wallet.circle.wiring.information": "Wiring Information",
  "wallet.circle.beneficiary": "Beneficiary",
  "wallet.circle.bank.beneficiary.name": "Bank Beneficiary Name",
  "wallet.circle.wire.routing": "Routing Number",
  "wallet.circle.instructions": "Instructions",
  "wallet.circle.instructions.content": [
    "・ The deposit will be credited to your account in 1-3 business days.",
    "・ The arrival amount will deduct 2 USDC fee from circle.",
    "・ Please do not exceed the maximum deposit amount. You will be responsible for any incurred costs if the remittance fails."
  ],
  "wallet.circle.withdraw.fee": "Circle withdrawal fee:",
  "wallet.circle.modal.review.title": "Bank Account Information Under Review",
  "wallet.circle.modal.review.content": "Your information has been submitted and is under review.",
  "wallet.circle.modal.reject.title": "Bank Account Connection Failed",
  "wallet.circle.modal.reject.title.reason": "Reason: ",
  "wallet.circle.modal.reject.content":
    "Your bank account connetion has failed. Please check your information and submit again.",
  "wallet.AUM.fee.rate": "AUM Fee Rate",
  "wallet.AUM.fee.rate.tips":
    "At the start of each month, asset management fee for the previous month is calculated and will be paid from the USDC account at the 10th of this month",
  "wallet.AUM.fee.rate.link": "View AUM Fee History",
  "wallet.AUM.fee.title": "Assets Under Management Fee",
  "wallet.AUM.fee.total": "Total AUM Fee",
  "wallet.AUM.fee.settled": "Settled AUM Fee",
  "wallet.AUM.fee.unsettled": "Unsettled AUM Fee",
  "wallet.AUM.fee.modal.fee": "Unsettled Fee: ",
  "wallet.AUM.fee.modal.balance": "Available Balance: ",
  "wallet.aum.fee.deduct.failed": "Deduction of Fee failed",
  "wallet.fixed.modal.locked.up.period": "Locked-Up Period",
  "wallet.fixed.modal.total.available.amount": "Total Available Amount",
  "wallet.fixed.modal.fixed.deposit.apy": "Fixed Deposit APY",
  "wallet.fixed.modal.flexible.deposit.apy": "Flexible Deposit APY",
  "wallet.fixed.modal.amount.per.unit": "Amount Per Unit",
  "wallet.fixed.modal.subscription.unit": "Subscription Unit",
  "wallet.fixed.modal.estimated.interest": "Estimated Interest",
  "wallet.fixed.modal.estimated.interest.tips":
    "It shows the interest corresponding to the subscription amount.",
  "wallet.fixed.modal.transfer.into.flexible": "Transfer into Flexible Deposit",
  "wallet.fixed.modal.transfer.into.flexible.tips":
    "When the lock-up period ends, the funds and interest will be transferred to flexible.",
  "wallet.fixed.modal.auto.subscribe.next.cycle": "Auto-Subscribe to the Next Cycle",
  "wallet.fixed.modal.auto.subscribe.next.cycle.tips":
    "When the fixed deposit lock-up period ends, the subscription amount here and the interest will be automatically subscribed to the next cycle of the same product (if next cycle is not available, the amount and interest will be transferred to spot).",
  "wallet.fixed.modal.cancel.title": "Fixed Deposit Cancellation",
  "wallet.fixed.modal.content.confirm": "Do you confirm to cancel this fixed deposit?",
  "wallet.fixed.modal.content.tip":
    "After that, the subscription amount will be transferred into spot.",
  "wallet.fixed.modal.cut.off.time": "Cut-Off Time",
  "wallet.fixed.modal.start": "Start: ",
  "wallet.fixed.modal.end": "End: ",
  "wallet.fixed.modal.transfer.to.spot": "Transfer to Spot or",
  "wallet.fixed.modal.flexible.within.day": "Flexible Within the Day",
  "wallet.fixed.modal.tiered.apy": "Tiered APY",
  "wallet.fixed.modal.subscription": "Subscription",
  "wallet.fixed.modal.locked.up": "Locked-Up",
  "wallet.fixed.modal.interests": "Interests",
  "wallet.fixed.modal.subscription.amount": "Subscription Amount",
  "wallet.fixed.modal.apy.tip.first":
    "1. Before the locked-up period, the flexible deposit APY applies to the subscription amount;",
  "wallet.fixed.modal.apy.tip.second":
    "2. During the locked-up Period,When the subscription amount is at Tier 2, Tier 1 APY is applied for the subscription amount of Tier 1, while Tier 2 APY is applied for the amount that exceeds Tier 1 amount; ",
  "wallet.fixed.modal.apy.tip.third":
    "3. The final interest is calculated according to the subscription amount and Tier APY.",
  "wallet.fixed.modal.cancel.subscription.fail": "Cancellation failed",
  "wallet.fixed.modal.cancel.subscription.success": "Cancellation Successfully",
  "wallet.fixed.subscribing": "Subscribing",
  "wallet.fixed.table.apy.tips": "Annual percentage yields",
  "wallet.flexible.table.auto.purchase.tips":
    "The daily interest from stable coins will be used to automatically purchase cryptocurrency.",
  "wallet.fixed.modal.transfer.to.flexible.tip": "Transfer to flexible deposit",
  "wallet.fixed.modal.transfer.to.flexible.cancel.tip": "Transfer to flexible deposit cancelled",
  "wallet.fixed.modal.auto.subscribe.tip": "Auto-subscription to the next cycle",
  "wallet.fixed.modal.auto.subscribe.cancel.tip": "Auto-subscription to the next cycle cancelled",
  "wallet.fixed.modal.auto.subscribe.checked.cancel.tips":
    "Please cancel “Auto-Subscribe to the Next Cycle” before your operation",
  "wallet.fixed.modal.transfer.to.flexible.checked.cancel.tips":
    "Please cancel “Transfer into Flexile Deposit” before your operation",
  "bank.account": "Bank Account",
  // TODO: 无使用 可删除
  "bank.account.sub": "Fiat Deposit & Withdrawal",

  /* 历史记录 - My Investment */
  "history.title": "My Investment",
  "history.strategy.cancel.modal.title": "Cancellation Confirmation",
  "history.strategy.cancel.modal.self.content": "Do you confirm to cancel the application?",
  "history.strategy.cancel.modal.not.self.content":
    "Do you confirm to cancel the application by {type} ({email})?",
  "history.strategy.subscribe.detail": "Subscription Detail",
  "history.strategy.redeem.detail": "Redemption Detail",
  "history.spot.account.title": "Spot Account History",

  /* 个人中心 */
  profile: "Profile",
  "profile.referral": "Referral Link",
  "profile.invitation": "Invitation Link",
  "profile.my.invitation.title": "My Invitation",
  "profile.uid": "UID",
  // TODO: 业务已移除，代码未更改，待处理
  "profile.contactus": "Contact US",
  "profile.bank.delete": "Delete Bank Account",
  "profile.bank.delete.tip1": "Are you sure you want to delete the linked bank account?",
  "profile.bank.delete.tip1.desc":
    "If you delete this bank card, you will not able to use this bank card to withdraw and deposit. Are you sure to delete this bank card？",
  "profile.bank.delete.tip2": "You will not be able to deposit and withdraw",
  "profile.bank.delete.tip2.desc":
    "This is the last bank account you bound. You can not delete your last bank card. If you want to delete this bank card, you need to add another one first.",
  "profile.add.bank": "Add Bank Card Information",
  "profile.add.bank.bankName": "Payee Name",
  "profile.add.bank.bankName.placeholder": "Enter your bank name",
  "profile.add.bank.card.id.placeholder": "Enter your bank card number",
  "profile.add.bank.bank.card.id": "Bank Account Number",
  "profile.add.bank.bank.remark": "Reference",
  "profile.add.bank.bank.notice": [
    "Your name on the bank account should match your name provided during KYC."
  ],
  // 以上待删除
  "profile.basic.info": "Basic Information",
  "profile.basic.info.google.auth": "Google Authenticator",
  "profile.basic.info.google.tips":
    "Google Authenticator can be used to verify withdrawals and we recommend you to turn on Authenticator to ensure the safety of your assets.",
  "profile.basic.info.google.auth.step1": "Step 1",
  "profile.basic.info.google.auth.step1.content":
    "Use your phone to download the Google Authenticator app.",
  "profile.basic.info.google.auth.step2": "Step 2",
  "profile.basic.info.google.auth.step2.content":
    "Please store this key in a safe place. The key can help you restore Google verification when your phone is lost.",
  "profile.basic.info.google.auth.step2.content.tips":
    "This is your key code, you also can scan the QR code to verify your account.",
  "profile.basic.info.google.auth.step2.button": "Click to View Key",
  "profile.basic.info.google.auth.step2.title": "Safety Verification",
  "profile.basic.info.google.auth.step2.tip1": "A verification email will be sent to you",
  "profile.basic.info.google.auth.step2.tip2":
    "Please fill in the verification code in the email into the input box.",
  "profile.basic.info.google.auth.step3": "Step 3",
  "profile.basic.info.google.auth.step3.content":
    "Enter the key into Google Authenticator, get a 6-digit number, and input below.",
  "google.auth.tips":
    "To keep your property safe, please enter your Google verification code to verify your identity.",
  "google.auth.tips.placeholder": "Enter the Google verifcation code",
  "google.verify.not.passed":
    "Account security warning, please contact us at support@aspendigital.co",
  "profile.change": "Change",
  "profile.change.google.start": "Turn On",
  "profile.kyc": "KYC Information",
  "profile.kyc.name": "Name",
  "profile.kyc.country": "Country / Region",
  "profile.kyc.type": "Account Type",
  "profile.kyc.successMessage": "You have submitted your KYC information successfully",
  "profile.google.verification1": "A verification email will be sent to you",
  "profile.google.verification2":
    "Please fill in the verification code in the email into the input box.",
  "profile.client.email.verification1": "A verification email will be sent to your client account",
  "profile.client.email.verification1.sub": "A verification email will be sent to the main account",
  "profile.client.email.verification2": "Please fill in the verification code here.",
  "profile.google.verification2.verfy.placeholder": "Verification Code",
  "profile.googlecode.bind.placeholder": "Input 6-digit number",
  "profile.googlecode.bind.email.code.error.locked":
    "Please re-acquire the verification code and verify",
  "profile.googlecode.using": "Active",
  "profile.googlecode.remove":
    "Enter the verification code to deactivate Google Authenticator. After deactivation, email will be used for verification.",
  "profile.googlecode.remove.tips": "For any issues, please contact support@aspendigital.co.",
  "profile.basic.change.password": "Change Password",
  "change.email.address": "Change Email Address",
  "change.email.address.tips":
    "48 hours after the email is modified, all withdrawal operations of your account will be prohibited.",
  "change.email.address.tips2":
    "The verification is passed, please enter a new email address and verify to replace the original address",
  "change.email.address.message": "Email has been registered",
  "profile.sub.title": "Multi-Login",
  "profile.sub.tag": "Frozen",
  "profile.sub.btn.add": "Add",
  "profile.sub.btn.terminate": "Terminate",
  "profile.sub.empty.content": "Invite your assistant to login to manage your account",
  "profile.sub.add.title": "Invite Multi-login Email Address",
  "profile.sub.add.content.one":
    "Multi-login function enables another email address to access your account, trade, subscribe or redeem yield, and subscribe or redeem strategy on behalf of you.",
  "profile.sub.add.content.two":
    "Enter multi-login email address below for invitation. A link for multi-login registration will be sent to this email and is valid within 10 days. The invitation link will be invalid after 10 days.",
  "profile.sub.add.placeholder": "Enter multi-login email address",
  "profile.sub.add.check.lable": "I have read and agreed to the ",
  "profile.sub.agreement.name": "Aspen Digital Sub Account Authorization Agreement",
  "profile.sub.email": "E-mail",
  "profile.sub.authorization": "Authorization",
  "profile.sub.agreement": "Agreement",
  "profile.sub.stop.title": "Terminate Authorization",
  "profile.sub.stop.content.1st":
    "When the authorization is terminated, the multi-login email address",
  "profile.sub.stop.content.2nd":
    "will not be able to login and operate in Aspen Digital platform.",
  "profile.sub.stop.content.3th": "Are you sure to terminate the authorization?",
  "profile.sub.error.tips.first": "Please enter the email address",
  "profile.sub.error.tips.second": "Error email",
  "profile.partner.title": "Account Manager",
  "profile.partner.tag.frozen": "Frozen",
  "profile.partner.tag.review": "Reviewing",
  "profile.partner.btn.add": "Authorize",
  "profile.partner.btn.terminate": "Terminate",
  "profile.partner.agreement.name": "Aspen Digital Account Management Authorization Agreement",
  "profile.partner.channel.partner": "Account Manager",
  "profile.partner.email": "E-mail",
  "profile.partner.authorization.period": "Authorized Period",
  "profile.partner.authorization": "Authorization",
  "profile.partner.agreement": "Agreement",
  "profile.partner.stop.title": "Terminate Authorization",
  "profile.partner.stop.content.1st": "When the authorization is terminated, account manager ",
  "profile.partner.stop.content.2nd":
    "will not be able to operate your account in Aspen Digital platform.",
  "profile.partner.stop.content.3th": "Are you sure to terminate the authorization?",
  "profile.partner.authorization.period.arr": ["3 Months", "Half-year", "One Year"],
  "profile.partner.authorization.detail": [
    {
      key: "Trade",
      detail:
        "Place and cancel orders on behalf of you. A notification email will be sent to you when the order is placed."
    },
    {
      key: "Yield",
      detail:
        "Subscribe or redeem yield products, and start or cancel automatic investment plan on behalf of you. Verification code for this operation will be sent to you in order to complete the process."
    },
    {
      key: "Strategies",
      detail:
        "Subscribe or redeem strategies on behalf of you. Verification code for this operation will be sent to you in order to complete the process."
    }
  ],
  "profile.partner.authorization.title": "Account Management Authorization",
  "profile.partner.authorization.information": "Application Information",
  "profile.partner.authorization.name": "Account Manager",
  "profile.partner.authorization.email": "E-mail",
  "profile.partner.authorization.permission": "Permission Applied",
  "profile.partner.authorization.authorization": "Authorization",
  "profile.partner.authorization.description": "Description",
  "profile.partner.authorization.period.title": "Authorized Period",
  "profile.partner.authorization.agreement":
    "Aspen Digital Account Management Authorization Agreement",
  "profile.partner.authorization.agreement.read":
    "I have read and agreed to Aspen Digital Account Management Authorization Agreement",
  "profile.partner.authorization.agreement.read.tips":
    "Please read the entire agreement and roll down to the bottom",
  "profile.partner.modal.authorization.reject": "Reject Authorization",
  "profile.partner.authorization.reject":
    "Are you sure to reject the account management application from",
  "profile.service.title": "Aspen Digital Service Account",
  "profile.service.btn": {
    on: "Turn On",
    off: "Turn Off"
  },
  "profile.service.content":
    "When it is on, you can contact Aspen Digital for placing order, subscription or redemption.",
  "profile.service.content.off": "Contact Aspen Digital to help operate your account and invest.",
  "profile.service.modal.title": {
    on: "Turn on Aspen Digital Service Account",
    off: "Turn off Aspen Digital Service Account"
  },
  "profile.service.first.content": {
    on: "Turn on Aspen Digital Service Account to allow Aspen Digital to help you place order, subscribe or redeem yield and strategies.",
    off: "Turn off Aspen Digital Service Account to disallow Aspen Digital to help you place order, subscribe or redeem yield and strategies."
  },
  "profile.service.second.content": {
    on: "When it is on, Aspen Digital Service Account will have the permission for the below operations: ",
    off: "When it is off, Aspen Digital Service Account will not have the permission for the below operations:"
  },
  "profile.service.confirm.tip": {
    on: "Do you confirm to turn on Aspen Digital Service Account? ",
    off: "Do you confirm to turn off Aspen Digital Service Account? "
  },
  "profile.service.modal.operation": "Operation",
  "profile.service.modal.permission": "Permission",
  "profile.service.modal.trade": "Trade",
  "profile.service.modal.trade.permission": "Place and cancel orders on behalf of you.",
  "profile.service.modal.yield": "Yield",
  "profile.service.modal.yield.permission":
    "Subscribe or redeem yield products, and start or cancel automatic investment plan on behalf of you.",
  "profile.service.modal.strategies": "Strategies",
  "profile.service.modal.strategies.permission": "Subscribe or redeem stratigies on behalf of you.",

  /* 策略 */
  "strategies.title": "Strategies",
  "strategies.item.viewDetail": "View Detail",
  "strategies.detail.strategies": "Strategies",
  "strategies.detail.details": "Details",
  "strategies.modal.subscribe.title": "Strategy Subscription",
  "strategies.modal.subscribe.balance": "Balance:",
  "strategies.modal.subscribe.amount.title": "Subscription Amount",
  "strategies.modal.subscribe.amount.placeholder": "Please fill in subscription amount",
  "strategies.modal.subscribe.amount.error": "Maximum subscription amount is ",
  "strategies.modal.subscribe.content":
    "Multiple subscriptions are not allowed within a month. The other subscription to this product will be cancelled and only the latest one will be valid.",
  "strategies.modal.redeem.title": "Strategy Redemption",
  "strategies.modal.redeem.amount.tips.title": "Redemption amount:",
  "strategies.modal.redeem.amount.tips.content":
    "Value updated in next month * Redemption percentage",
  "strategies.modal.redeem.amount.value.title": "Latest Value: ",
  "strategies.modal.redeem.input.title": "Redemption Percentage",
  "strategies.modal.redeem.input.placeholder": "Please fill in redemption percentage",
  "strategies.modal.redeem.content":
    "Multiple redemptions are not allowed within a month. The other redemption to this product will be cancelled and only the latest one will be valid.",
  "strategies.modal.subscribe.error.tips": "Subscription Application Failed",
  "strategies.modal.redeem.error.tips": "Redemption Application Failed",
  "strategies.modal.result.success.subscribe.title": "Subscription Application Success",
  "strategies.modal.result.success.subscribe.content":
    "We have received your subscription application, and will transfer the subscription amount at the end of this month from spot. Please ensure that the balance in {currency} spot is sufficient. We will notify you again before the transfer through email ",
  "strategies.modal.result.success.subscribe.amount": "Subscription Amount",
  "strategies.modal.result.success.subscribe.time": "Fund Transfer Time:",
  "strategies.modal.result.success.redeem.title": "Redemption Application Success",
  "strategies.modal.result.success.redeem.content":
    "We have received your redemption application, and will process at the start of next month. Please check for update notification through ",
  "strategies.modal.result.success.redeem.amount": "Redemption Percentage",
  "strategies.modal.result.success.redeem.time": "Update Time:",
  "strategies.modal.result.failed.title": "Application Failed",
  "strategies.modal.result.failed.positive":
    "The order for this product is being processed.Please submit again after the process is finished.",
  "strategies.modal.result.failed.inverse":
    "You cannot apply for both subscription and redemption for the same product in each month. Please cancel the previous application before submitting other one.",

  /* 前端判断提示, 没有分的很清楚 */
  "error.password.format": "Please enter 6-40 characters in numbers, upper and lower letters.",
  "login.multiple.errors":
    'Too many incorrect entries, try again after 2 hours. Or use "Forgot Password" to reset.',
  "password.not.match": "The two passwords that you entered do not match!", //todo, 宏淼提供
  "subscribe.rule":
    "Amount entered should be larger than minimum subscription amount and increment rule",
  "subscribe.amount.more.than.max": "Exceed maximum subscription amount",
  "subscribe.insufficient.balance": "Insufficient balance, please re-enter the amount",
  "subscribe.amount.less.than.min":
    "Subscription amount should be larger than minimum subscription amount {minAmount}",
  "subscribe.amount.not.increment":
    "Subscription amount must be on the multiple of {incrementAmount}",
  "password.changed": "Password changed",
  "email.changed": "Email changed",
  "google.auth.bind.success": "Google Authenticator binded",
  "bank.add": "Bank card added",
  "bank.delete": "Bank card deleted",
  "redeem.exceed.maximum": "Exceed Maximum redeem remaining",
  "redemm.not.zero": "The redemption quantity cannot be 0",
  "trade.value.greater.than.order.max": "Cannot exceed {amount} {currency} price per order limit",
  "trade.amount.empty": "The amount cannot be empty",
  "trade.entrust.cancel.success": "Cancel successfully",
  "trade.entrust.success": "Entrust success", // 委托成功
  "trade.placed.success": "Placed successfully", // limit成功
  "trade.purchased.success": "Purchased successfully", // market成功
  "spot.order.invalid.status": "Order Cancellation Failed",
  "spot.order.order.book.cancel.fail": "Order Cancellation Failed",
  "spot.order.update.fail": "Update failed",
  "spot.order.trade.duplicate": "Order failed",
  "spot.order.trade.state.illegal": "Order status is wrong",
  "spot.customer.stop.trade": "Trading status error",
  "spot.order.book.invoke.fail": "Order failed",
  "spot.order.success": "Spot order success",
  "spot.order.duplicate": "Spot order duplicate",
  "spot.order.create.fail": "Failed to create spot order",
  "spot.order.symbol.invalid": "This coin is invalid to trade in spot market",
  "spot.order.error": "Spot order failed",
  "spot.order.user.account.insufficient": "User insufficient balance",
  "spot.entrust.account.insufficient": "User insufficient balance",
  "spot.entrust.user.account.insufficient": "User insufficient balance",
  "spot.entrust.cancel.fail": "Spot transaction cancellation failed",
  "spot.order.system.account.insufficient": "System insufficient balance",
  "spot.order.side.invalid": "Invalid parameter for spot order",
  "spot.order.type.invalid": "Invalid type for spot order",
  "spot.order.check.success": "Success verifying spot order",
  "spot.order.min.check": "Spot order is smaller than minimum limit amount",
  "spot.order.max.check": "Spot order is greater than maximum limit amount",
  "spot.order.invalid.parameter": "Invalid parameter",
  minTotalMessage: "Min transaction limits cannot ≤ {orderQuoteMin}",
  oneMaxTotalMessage: "Max transaction limits cannot ≥ {orderQuoteMax}",
  maxAvblMessage: "Max available {total}",
  oneMaxBuyMessage: "Max buy amount cannot ≥ {orderAmount}",
  maxBuyMessage: "Max buy amount {orderAmount}",
  maxSellMessage: "Max sell amount {orderAmount}",
  minBuyMessage: "Min buy amount cannot ≤ {orderAmount}",
  minSellMessage: "Min sell amount cannot ≤ {orderAmount}",
  oneMaxSellMessage: "Max sell amount cannot ≥ {orderAmount}",

  /* 接口返回提示 */
  email_code_verify_count_locked:
    "Too many attempt in verification code, please try again after 24 hours",
  email_code_verify_count_often: "Please re-acquire the verification code and verify",
  "customer.update.password.locked": "Too many incorrect entries, try again after 24 hours",
  email_code_not_send: "Please send the verification code first",
  reset_password_count_error_often: "Too many incorrect entries, try again after 24 hours",
  "customer.exist.none": "Your account has not been registered",
  "customer.email.code.incorrect": "Verification code error",
  "google.code.error": "Your verification code is incorrect.",
  "global.exception": "System error",
  "rpc.exception": "System error",
  "profile.googlecode.bind.email.code.error": "Incorrect verification code",
  "financing.account.amount.incorrect": "Amount Incorrect, please try again later",
  "customer.login.password.error.email": "Incorrect account or password",
  "user.name.not.found": "Incorrect account or password",
  "user.password.error": "Incorrect account or password",
  "customer.not.found": "The account does not exist.",
  "user.is.not.allowed.to.operate": "Unauthorized",
  "user.status.is.not.normal": "The customer account has been frozen.",
  "customer.google.on.error": "Google verification fail to open",
  "customer.verify.email.inactive": "Need to activate mailbox",
  "customer.verify.code.sent.often": "Email verification code sent too often, please try later",
  "user.access.interface.frequency": "Operation too often, please try later",
  "customer.login.required": "User not login",
  "customer.email.required": "Email can not be empty",
  "customer.email.format.incorrect": "Email format is incorrect",
  "customer.verify.code.sent": "Verification code sent",
  "customer.password.required": "Password can not be empty",
  "customer.password.different": "The password entered twice must be the same",
  "customer.password.old.required": "Original password can not be empty",
  "customer.password.new.required": "New password can not be empty",
  "customer.password.same": "New login password can not be the same as the original one",
  "customer.phone.code.required": "Phone verification code can not be empty",
  "customer.phone.code.incorrect": "Phone Verification code error",
  "customer.phone.send.fail": "Failed to send verification code",
  "customer.phone.format.incorrect": "Wrong format of phone number",
  "customer.phone.required": "Phone number can not be empty",
  "customer.phone.code.limit": "SMS sent beyond the maximum limit of the day",
  "customer.phone.code.often": "SMS verification code sent too often, please try again later",
  "customer.phone.duplicate": "The phone number has been bound",
  "customer.phone.new.duplicate": "The new phone number can not be duplicated with the old one",
  "customer.phone.error": "Mobilephone certification failed",
  "customer.verify.code.expire": "Verification code error",
  "customer.verify.phone.unbind": "Need binding phone",
  "customer.forbidden":
    "The account has been frozen due to security problems. For more information, please contact support@aspendigital.co.",
  "customer.reg.email.duplicate": "Email has been registered",
  "customer.reg.fail": "Registration failed",
  "customer.google.off.error": "Google verification failed to close",
  "customer.google.auth.fail": "Google validation code error",
  "customer.google.bind.fail": "Google verification binding failed",
  "customer.google.unbind.fail": "Google verification unbinding failed",
  "customer.kyc.id.used": "The ID certificate has been used, please try others",
  "customer.kyc.duplicate": "Customer already certified",
  "customer.kyc.expire": "The verification expired. Please try again later",
  "customer.kyc.info.insufficient": "Insufficient KYC information",
  "customer.kyc.reject.image.not.readable":
    "The system could not verify your passport photo, please read the instruction and upload again. If you have any questions, please go to [Profile]-[Other Settings]-[Contact us] to contact us.",
  "customer.kyc.reject.unsupported.id.country":
    "Certificate info and submitted country info does not match",
  "customer.kyc.reject.handheld.fraud":
    "Hand-held ID photo info does not meet the platform's requirements",
  "customer.kyc.not.submit": "KYC has not been submitted",
  "customer.kyc.id.expire": "Passport expired/invalid",
  "customer.kyc.live.result.check.fail": "liveness detection failed",
  "customer.kyc.verify.fail": "KYC certification failed",
  "customer.kyc.reject.unsupported.id.type": "Passport type not supported",
  "customer.phone.on.fail": "Open your phone to verify failed",
  "customer.phone.off.fail": "Failed to verify handset shutdown",
  "customer.password.reset.fail": "Password reset failure",
  "customer.login.new": "Logout",
  "customer.virtual.account.nonexist": "Virtual account does not exist",
  "customer.verify.code.incorrect": "Email verification code is incorrect",
  "customer.verify.type.invalid": "Invalid verify type",
  "customer.google.verify.code.incorrect": "Google verification code can not be empty",
  "customer.password.format.incorrect": "Bad password format",
  "customer.email.duplicate": "Email address cannot be repeated",
  "customer.login.incorrect": "User info expired",
  "customer.login.password.error.mobile":
    "Username or Password is not correct,{time} times remaining",
  "customer.login.password.error": "Email or Password error, {0} input opportunities left",
  "customer.login.3rd.token.error": "Third-party login failed(1105)",
  "customer.login.3rd.email.exist": "Email has already registered in Kikitrade",
  "incorrect.invitation.code": "Incorrect invitation code",
  "otc.customer.payment.way.save.fail": "Failed to save bank card",
  "otc.customer.payment.way.save.exception": "Error to save bank card",
  "otc.customer.payment.way.none.exist": "Bank card info doesn't exist",
  "otc.customer.payment.way.disable": "Bank card info has been deleted",
  "otc.customer.payment.way.op.exception": "Failed to get bank card info",
  "system.operation.frequent": "Operation too frequent",
  "system.security.param.required": "Security check parameter is empty",
  "system.security.check.fail": "Security check failed",
  "system.parameter.invalid": "Request Parameter Invalid",
  "system.parameter.required": "Parameter [{0}] is empty",
  "system.parameter.format.incorrect": "Parameter format [{0}] is incorrect",
  "system.parameter.type.mismatch": "Parameter type mismatch",
  "system.parameter.digital.required": "Parameter [{0}] should be digital",
  "system.data.not.found": "System data is not found",
  "system.cache.refresh.fail": "System cache refresh fail",
  "system.error": "Connection fail, retry later",
  "system.service.not.available": "Service invalid",
  "customer.locked":
    "The password is entered incorrectly 5 times, and your account cannot be logged in again within two hours",
  "customer.reset.password.error": "Old password error", // todo, ?
  "user.information.is.incorrect": "Old password error",
  "system.success": "Success",
  "system.successful": "Successful",
  "margin.interest.duplicate": "Duplicate leveraged interest processing",
  "margin.interest.state.illegal": "Close Leverage Failed",
  "margin.config.update.fail": "Update failed",
  "margin.config.disable.forbidden": "Leverage closing failed",
  "margin.balance.insufficient": "Insufficient balance",
  "margin.lock.fail": "Order failed",
  "margin.unlock.fail": "Risk check unlock failed",
  "margin.orders.exceed": "The number of orders placed with leverage exceeds the limit",
  "margin.transfer.fail": "Insufficient balance",
  "margin.transfer.lock.fail": "Failed to open leverage, please try again later",
  "margin.open.country.forbidden": "Your current location does not allow margin trading",
  "margin.open.saving.forbidden":
    "Please turn off automatic subscription for wealth management first, and then turn on leverage",
  "margin.open.invest.forbidden":
    "Please close financial fixed investment first, and then open leverage",
  "margin.open.order.forbidden":
    "Please cancel the pending order first, and then open the leverage",
  "margin.open.query.order.fail": "Lever opening failed, please try again later",
  "margin.close.loan.forbidden": "Close leverage failed-user has borrowed money",
  "margin.close.order.forbidden":
    "Please cancel the pending order first, and then open the leverage",
  "margin.close.interest.cannot.afford":
    "When the leverage is closed, the interest payable is insufficient",
  "margin.interest.fail": "Failed to close the leverage, please try again later",
  "margin.already.closed": "Leverage is closed",
  "margin.close.fail": "Lever close abnormal",
  "coin.withdraw.amount.onetime.min.less": "Below min amount",
  "coin.withdraw.amount.onetime.max.exceed": "Exceeds max amount",
  "fixed.deposit.product.quantity.not.enough":
    "Subscription Failed. The product is not available now.", // 接口返回
  "financing.contract.interest.trading.operated":
    "Operation finished. Please close the pop-up and check the result.",

  /* 代理商 */
  "clients.title": "Client Management",
  "clients.agency.information": "Agency Information",
  "clients.agency.type": "Agency Type",
  "clients.aum": "Assets Under Management (AUM)",
  "clients.invitation.information": "Invitation Information",
  "clients.referral.code": "Referral Code",
  "clients.customer.list": "Customer List",
  "clients.customer.id": "Customer ID",
  "clients.customer.tips": "Search by Customer ID",
  "clients.manager.search.tips": "User ID can be checked in management dashboard",
  "clients.commission": "Commission",
  "clients.total": "Total",
  "clients.unsettled": "Unsettled",
  "clients.settled": "Settled",
  "clients.view.billing.history": "View Billing History",
  "clients.billing.history.title": "Customer Management - Billing History",
  "clients.billing.history.column.date": "Settlement Date",
  "clients.billing.history.column.amount": "Settlement Amount",
  "clients.application.title": "Client Account Management Authorization Application",
  "clients.application.information": "Application Information",
  "clients.application.name": "Client’s Name",
  "clients.application.email": "E-mail",
  "clients.application.authorization": "Authorization",
  "clients.application.description": "Description",
  "clients.application.authorization.detail": [
    {
      key: "Trade",
      detail: "Place and cancel orders on behalf of the client"
    },
    {
      key: "Yield",
      detail:
        "Subscribe or redeem yield products, and start or cancel automatic investment plan on behalf of the client (require verification code from client)"
    },
    {
      key: "Strategies",
      detail:
        "Subscribe or redeem strategies on behalf of the client (require verification code from client)"
    }
  ],
  "clients.application.period": "Duration",
  "clients.application.agreement": "Aspen Digital Account Management Authorization Agreement",
  "clients.application.agreement.read":
    "I have read and agreed to Aspen Digital Account Management Authorization Agreement",
  "clients.application.agreement.read.tips":
    "Please read the entire agreement and roll down to the bottom",
  "global.access.denied": "Access denied",

  /* input缺省相关 */
  "placeholder.enter": "Please Enter",
  "placeholder.select": "Please Select",
  "placeholder.billing.address": "Billing Address",
  "placeholder.hk.postal.code": "HK Postal Code: 999077",
  "placeholder.kyc.company.name": "Company Name",
  "placeholder.kyc.enter.mc": "Enter MC",
  "placeholder.kyc.enter.tin": "Enter TIN",
  "placeholder.kyc.enter.address": "Please enter a valid office address",
  "placeholder.kyc.enter.name": "Name",
  "placeholder.kyc.enter.title": "Title",
  "placeholder.kyc.enter.email": "Email",
  "placeholder.kyc.enter.same.passport.name": "Same to Passport/ID Name",
  "placeholder.kyc.enter.birthday": "Birthday",
  "placeholder.kyc.enter.age": "Age",
  "placeholder.kyc.enter.passport.id": "Passport/ID Number",
  "placeholder.kyc.enter.phone": "Phone Number",
  "placeholder.kyc.enter.residential.address": "Residential Address",

  /* 表格列名相关 */
  "column.currency": "Cryptocurrency", // 原为Currency
  "column.denomination": "Denomination", // 原为Cryptocurrency
  "column.totalAmount": "Total Amount",
  "column.product": "Product",
  "column.type": "Type",
  "column.latestValue": "Latest Value",
  "column.cumulativeInterest": "Cumulative interest",
  "column.apy": "APY",
  "column.apr": "APR",
  "column.action": "Actions",
  "column.details": "Details",
  "column.pending": "Pending",
  "column.available": "Available",
  "column.date": "Date",
  "column.name": "Name",
  "column.updateDate": "Update Date",
  "column.pair": "Pair",
  "column.average.price": "Average Price",
  "column.side": "Side",
  "column.price": "Price",
  "column.order.price": "Order Price",
  "column.amount": "Amount",
  "column.filled.total": "Filled Total",
  "column.order.total": "Order Total",
  "column.filled.amount": "Filled Amount",
  "column.filled": "Filled",
  "column.order.amount": "Order Amount",
  "column.fee": "Fee",
  "column.number": "Number",
  "column.total": "Total",
  "column.authorization": "Authorization",
  "column.estimated.interest": "Estimated Interest",
  "column.period": "Period",
  "column.cutOff": "Cut-Off Time",
  "column.operation": "Actions",
  "column.operator": "Operator",
  "column.strategy": "Strategy",
  "column.latest.value": "Latest Value",
  "column.ytd": "YTD",
  // TODO: 已无使用
  "column.payee.name": "Payee Name",
  "column.project.name": "Project Name",
  "column.bank.name": "Bank Name",
  "column.bank.card": "Bank Card ID",
  "column.bank.remark": "Ref. (Optional)",
  "column.from.address": "From Address",
  "column.to.address": "To Address",
  "column.from.account": "From Account",
  "column.to.account": "To Account",
  "column.time": "Time",
  "column.month": "Month",
  "column.status": "Status",
  "column.history": "History",
  "column.category": "Category",
  "column.yield.auto.purchase": "Auto-Purchase",
  "column.yield.auto.compound": "Auto-Compound",
  "column.yield.auto.purchase.mini.investment.amount": "Min Investment Amount ",
  "column.yield.auto.purchase.investment.amount": "Investment Amount",
  "column.yield.auto.purchase.estimated_return_rate": "Estimated Return Rate",
  "column.yield.invest.plan.details": "Plan Details",
  "column.yield.invest.investment.details": "Invest History",
  "column.email": "Email",
  "column.email.address": "Email address",
  "column.email.type": "Type",
  "column.user.status": "User Status",
  "column.total.assets": "Total Assets",
  "column.percentage": "Percentage",
  "column.registration": "Registration",

  /* 阿里云滑动验证 */
  "alicloud.sliding.verification.uplang.en": {
    // 加载状态提示。
    LOADING: "Loading... ",
    // 等待滑动状态提示。
    SLIDE: "Please slide to verify",
    // 验证通过状态提示。
    SUCCESS: "Verified",
    // 验证码加载出现异常时触发该回调参数。
    ERROR: "Please check your network and try again later ",
    // 验证失败触发拦截状态提示。
    FAIL: "Verification failed. Please try again "
  },
  "Network error": "Please check your network and try again later.",
  "afs.nvc.fail":
    "Verification failed. If you have any question, please contact support@aspendigital.co.",
  "afs.nvc.fail.refresh": "Verification failed. Please refesh the page.",
  "alicloud.loading.timeout.tip": "Loading timed out. Please refresh the page and try again"
};
