import React, { useState, useEffect, Suspense } from "react";
import { IkycInfo } from "@/model/index";
import { Breadcrumb, Button, Image, Spin } from "antd";
import styles from "./Strategy.module.less";
import { Link as RouterLink } from "react-router-dom";
import { getStrategyDetail, availableSpot } from "@/services/request";
import KYCInfoLayout from "@/components/withKycInfo/WithKYCInfo";
import { getQueryValue } from "@/utils/index";
import { Strategy_Type } from "@/config/constants";

const ModalSubscribeRedeem = React.lazy(
  () => import(/* webpackChunkName: "ModalSubscribeResult" */ "./components/ModalSubscribeRedeem")
);
const ModalResult = React.lazy(
  () => import(/* webpackChunkName: "ModalSubscribeResult" */ "./components/ModalResult")
);

interface IProps {
  kycInfo: IkycInfo;
  showGuideModal: () => void;
}
function Detail(props: IProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<any>({});
  const [subscribeRedeemModalVisible, setSubscribeRedeemModalVisible] = useState<boolean>(false);
  const [resultModalVisible, setResultModalVisible] = useState<boolean>(false);
  const [actionType, setActionType] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [fetchCode, setFetchCode] = useState<string>("0");
  const [fetchAmount, setFetchAmount] = useState<string>("0");

  const intl: any = window.__intl__.messages;
  const strategies_text: string = intl["strategies.detail.strategies"];
  const details_text: string = intl["strategies.detail.details"];

  useEffect(() => {
    setLoading(true);
    // 请求数据
    let params = {
      strategyId: getQueryValue("id") ?? ""
    };
    getStrategyDetail(params)
      .then((res) => {
        if (res.code == 0) {
          setInfo(res.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // 是否开始或者已经过期
  const isExpired = () => {
    const endTime = info?.endTime;
    const startTime = info?.startTime;
    const now = new Date().getTime();
    if (now > endTime) {
      return "close";
    }
    if (now < startTime) {
      return "comingSoon";
    }
    return false;
  };

  // 点击申购赎回
  const handleClick = (type: number) => {
    // 判断kyc验证是否通过
    if (props.kycInfo.auditStatus != 1) {
      props.showGuideModal();
    } else {
      const params = {
        currency: info.currency ?? ""
      };
      // type : 0 申购；1 赎回
      if (type === 0) {
        availableSpot(params).then((res) => {
          if (res?.code == 0) {
            setBalance(res.data?.balance);
            setActionType(type);
            setSubscribeRedeemModalVisible(true);
          }
        });
      } else {
        setActionType(type);
        setSubscribeRedeemModalVisible(true);
      }
    }
  };

  // 申购/赎回 请求完成回掉
  const handleResultCallback = (code: string, amount: string) => {
    setFetchCode(code);
    setFetchAmount(amount);
    setSubscribeRedeemModalVisible(false);
    setResultModalVisible(true);
  };

  return (
    <Spin spinning={loading}>
      <div className={styles.detail}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <RouterLink to="/strategies">{strategies_text}</RouterLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{details_text}</Breadcrumb.Item>
        </Breadcrumb>
        <section id="title">
          <p className={styles.title}>{info?.name}</p>
          <div className={styles.categroy}>{Strategy_Type[info?.type]}</div>
        </section>
        <div className={styles.detailContent}>
          <div
            className={styles.detailBgContent}
            dangerouslySetInnerHTML={{ __html: info?.detailInformation }}
          />
          {info.name && (
            <div className={styles.btnWrap}>
              {isExpired() ? (
                <div className={styles.subBtnWrap}>
                  <Button type="primary" disabled className={styles.actionBtn}>
                    {isExpired() === "close" ? intl["button.closed"] : intl["button.comingSoon"]}
                  </Button>
                </div>
              ) : (
                <div className={styles.subBtnWrap}>
                  <Button
                    type="primary"
                    className={styles.actionBtn}
                    onClick={() => handleClick(0)}>
                    {intl["button.subscribe"]}
                  </Button>
                  {info.amount && (
                    <Button
                      type="primary"
                      className={styles.actionBtn}
                      onClick={() => handleClick(1)}>
                      {intl["button.redeem"]}
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <Suspense fallback={<></>}>
          <ModalSubscribeRedeem
            visible={subscribeRedeemModalVisible}
            type={actionType}
            info={info}
            balance={balance}
            handleResultCallback={(code: string, amount: string) => {
              handleResultCallback(code, amount);
            }}
            cancelModal={() => {
              setSubscribeRedeemModalVisible(false);
            }}
          />
          <ModalResult
            visible={resultModalVisible}
            type={actionType}
            info={info}
            amount={fetchAmount}
            code={fetchCode}
            cancelModal={() => {
              setResultModalVisible(false);
            }}
          />
        </Suspense>
      </div>
    </Spin>
  );
}

export default KYCInfoLayout(Detail);
