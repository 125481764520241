/*
 * NOTE:
 * 1. 新增/改变路由，需要在增加/检查权限配置
 */

import { USER_ROLE } from "@/config/constants";
const {
  ROLE_CUSTOMER,
  ROLE_PARTNER,
  ROLE_MANAGER,
  ROLE_PARTNER_CUSTOMER,
  ROLE_MANAGER_CUSTOMER,
  ROLE_SUB
} = USER_ROLE;

/* ---------- 用户相关 ----------*/
import Login from "@/views/login/Login";
import AgentLogin from "@/views/login/AgentLogin";
import ManagerLogin from "@/views/login/ManagerLogin";
import Register from "@/views/login/Register";
import RegisterSub from "@/views/login/RegisterSub";
import ResetPassword from "@/views/login/ResetPassword";

import Policy from "@/views/login/components/PrivacyPolicy";

import Agreement from "@/views/login/components/ServiceAgreement";
import AuthorizationAgreement from "@/views/clients/AuthorizationAgreement";

/* ---------- 个人中心相关 ----------*/
import Profile from "@/views/profile/Profile";

import KycCertify from "@/views/profile/kyc/KycCertify";
import PartnerAuthorization from "@/views/profile/PartnerAuthorization";

import MyInvitation from "@/views/profile/invitation/MyInvitation";

/* ---------- 主页 ----------*/
import Home from "@/views/home/Home";
//import Home from /* webpackChunkName: "Home" */ "@/views/home/Home";

/* ---------- 交易 ----------*/
//import Trade from /* webpackChunkName: "Trade" */ "@/views/trade/Trade";
import Trade from "@/views/trade/Trade";

/* ---------- 新闻相关 ----------*/
import Intelligence from "@/views/intelligence/Intelligence";
import IntelligenceDetail from "@/views/intelligence/Detail";

/* ---------- 理财 ----------*/
import Yield from "@/views/savings/Savings";
import InvestmentPlan from "@/views/savings/InvestmentPlan";
import MyInvestmentPlan from "@/views/savings/MyInvestmentPlan";
import AddInvestmentPlan from "@/views/savings/AddInvestmentPlan";
import InvestmentHistory from "@/views/savings/InvestmentHistory";
import SubscribeFixedYield from "@/views/savings/SubscribeFixedYield";

/* ---------- 策略 ----------*/
import Strategy from "@/views/strategy/Strategy";
import StrategyDetail from "@/views/strategy/Detail";

/* ---------- 钱包 ----------*/
import Wallet from "@/views/wallet/Wallet";
import Deposit from "@/views/wallet/Deposit";
import Withdraw from "@/views/wallet/Withdraw";
import WalletHistory from "@/views/wallet/WalletHistory";
import AddBankAccount from "@/views/wallet/AddBankAccount";
import DepositUSDC from "@/views/wallet/DepositUSDC";
import WithdrawUSDC from "@/views/wallet/WithdrawUSDC";
import AUMFee from "@/views/wallet/AUMFee";

/* ---------- 历史记录 ----------*/
import TradeHistory from "@/views/history/TradeHistory";
import AccountHistory from "@/views/history/AccountHistory";
import ClientsBillingHisory from "@/views/history/CustomerManagementBillingHisory";

/* ---------- 代理商/管理员相关 ----------*/
import PartnerClients from "@/views/clients/PartnerManagement";
import ManagerClients from "@/views/clients/CustomerManagement";
import PartnerAuthApplication from "@/views/clients/PartnerAuthApplication";

interface IRoleItem {
  component: any;
  exact: boolean;
  needAuthenticated?: boolean; // 是否需要登陆权限, 默认false
  role?: string[]; // 可以访问的角色列表, 不设置或者为空，即不限角色， 只有在needAuthenticate为true的情况下才需要配置role
}
interface IRole {
  [propName: string]: IRoleItem;
}

/*
 * desc： 路由/角色/配置
 * 不配置路由权限或者为空，即不限角色role
 * 只有在needAuthenticate为true的情况下才需要配置role
 *
 * 根据域名判断，登陆路由模块是否存在 （域名中存在partner时为代理商CMS登陆/存在service时为SAS登陆 仅显示登陆login路由 本地调试代理商的话需要partner改为local）
 */
const loginRouteConfig: IRole =
  location.origin.includes("partner") || location.origin.includes("service")
    ? {
        "/login": {
          component: location.origin.includes("partner") ? AgentLogin : ManagerLogin,
          exact: true,
          needAuthenticated: false,
          role: []
        }
      }
    : {
        "/login": {
          component: Login,
          exact: true,
          needAuthenticated: false,
          role: []
        },
        "/login/register": {
          component: Register,
          exact: true,
          needAuthenticated: false,
          role: []
        },
        "/login/registerMulti": {
          component: RegisterSub,
          exact: true,
          needAuthenticated: false,
          role: []
        },
        "/login/reset": {
          component: ResetPassword,
          exact: true,
          needAuthenticated: false,
          role: []
        },
        "/policy": {
          component: Policy,
          exact: true,
          needAuthenticated: false,
          role: []
        },
        "/agreement": {
          component: Agreement,
          exact: true,
          needAuthenticated: false,
          role: []
        }
      };
const afterLoginRouteConfig: IRole = {
  "/": {
    component: Home,
    exact: true,
    needAuthenticated: true,
    role: [ROLE_CUSTOMER]
  },
  "/home": {
    component: Home,
    exact: true,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true
  },
  "/trade": {
    component: Trade,
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB, ROLE_PARTNER_CUSTOMER, ROLE_MANAGER_CUSTOMER],
    needAuthenticated: true
  },
  "/yield": {
    component: Yield,
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB, ROLE_PARTNER_CUSTOMER, ROLE_MANAGER_CUSTOMER],
    needAuthenticated: true
  },
  "/yield/investmentPlan": {
    component: InvestmentPlan,
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB, ROLE_PARTNER_CUSTOMER, ROLE_MANAGER_CUSTOMER],
    needAuthenticated: true
  },
  "/yield/myInvestmentPlan": {
    component: MyInvestmentPlan,
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB, ROLE_PARTNER_CUSTOMER, ROLE_MANAGER_CUSTOMER],
    needAuthenticated: true
  },
  "/yield/addInvestmentPlan": {
    component: AddInvestmentPlan,
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB, ROLE_PARTNER_CUSTOMER, ROLE_MANAGER_CUSTOMER],
    needAuthenticated: true
  },
  "/yield/investmentHistory": {
    component: InvestmentHistory,
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB, ROLE_PARTNER_CUSTOMER, ROLE_MANAGER_CUSTOMER],
    needAuthenticated: true
  },
  "/yield/subscribeFixedYield": {
    component: SubscribeFixedYield,
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB, ROLE_PARTNER_CUSTOMER, ROLE_MANAGER_CUSTOMER],
    needAuthenticated: true
  },
  "/strategies": {
    component: Strategy,
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB, ROLE_PARTNER_CUSTOMER, ROLE_MANAGER_CUSTOMER],
    needAuthenticated: true
  },
  "/strategies/detail": {
    component: StrategyDetail,
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB, ROLE_PARTNER_CUSTOMER, ROLE_MANAGER_CUSTOMER],
    needAuthenticated: true
  },
  "/intelligence": {
    component: Intelligence,
    exact: true,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true
  },
  "/intelligence/detail": {
    component: IntelligenceDetail,
    exact: true,
    role: [ROLE_CUSTOMER],
    needAuthenticated: false
  },
  "/profile": {
    component: Profile,
    exact: true,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true
  },
  "/profile/myInvitation": {
    exact: true,
    component: MyInvitation,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true
  },
  "/profile/kyc/certify": {
    exact: true,
    component: KycCertify,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true
  },
  "/profile/agent/authorize": {
    exact: true,
    component: PartnerAuthorization,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true
  },
  "/wallet": {
    component: Wallet,
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB, ROLE_PARTNER_CUSTOMER, ROLE_MANAGER_CUSTOMER],
    needAuthenticated: true
  },
  "/wallet/deposit": {
    component: Deposit,
    exact: true,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true
  },
  "/wallet/withdraw": {
    component: Withdraw,
    exact: true,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true
  },
  "/wallet/addAccount": {
    component: AddBankAccount,
    exact: true,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true
  },
  "/wallet/withdraw/usdc": {
    component: WithdrawUSDC,
    exact: true,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true
  },
  "/wallet/deposit/usdc": {
    component: DepositUSDC,
    exact: true,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true
  },
  "/wallet/history": {
    component: WalletHistory,
    exact: true,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true
  },
  "/wallet/AUMFee": {
    component: AUMFee,
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB, ROLE_PARTNER_CUSTOMER, ROLE_MANAGER_CUSTOMER],
    needAuthenticated: true
  },
  "/history": {
    component: TradeHistory,
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB, ROLE_PARTNER_CUSTOMER, ROLE_MANAGER_CUSTOMER],
    needAuthenticated: true
  },
  "/history/accountDetail": {
    component: AccountHistory,
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB, ROLE_PARTNER_CUSTOMER, ROLE_MANAGER_CUSTOMER],
    needAuthenticated: true
  },
  "/clients": {
    component: location.origin.includes("partner") ? PartnerClients : ManagerClients,
    exact: true,
    role: [ROLE_PARTNER, ROLE_MANAGER],
    needAuthenticated: true
  },
  "/clients/billingHisory": {
    component: ClientsBillingHisory,
    exact: true,
    role: [ROLE_PARTNER],
    needAuthenticated: true
  },
  "/clients/application": {
    component: PartnerAuthApplication,
    exact: true,
    role: [ROLE_PARTNER],
    needAuthenticated: true
  },
  "/authorizationAgreement": {
    component: AuthorizationAgreement,
    exact: true,
    needAuthenticated: true,
    role: []
  }
};
export const routeConfig = Object.assign(loginRouteConfig, afterLoginRouteConfig);
