import React, { useState, useEffect } from "react";
import { Modal, Spin, Descriptions, message } from "antd";
import { Intl } from "@/model/index";
import { updateAllowHelpOperate, getAllowHelpOperateInfo } from "@/services/request";
import styles from "../Profile.module.less";

interface IProps {}

const ServiceAccount: React.FC<IProps> = (props: IProps) => {
  const intl: Intl = window.__intl__.messages;

  const [loading, setLoading] = useState<boolean>(false);
  const [allowHelpOperate, setAllowHelpOperate] = useState<boolean>(false);
  const [allowHelpOperateModal, setAllowHelpOperateModal] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    initData();
  }, []);

  const initData = () => {
    getAllowHelpOperateInfo()
      .then((res) => {
        if (res?.code == 0) {
          setAllowHelpOperate(res.data);
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 是否允许帮客操作,true-open,false-close
  const handleConfirm = () => {
    const params = {
      allowHelpOperate: !allowHelpOperate
    };

    updateAllowHelpOperate(params).then((res) => {
      if (res?.code == 0) {
        setAllowHelpOperateModal(false);
        initData();
        message.destroy();
        message.success(intl["system.success"]);
      } else {
        message.destroy();
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });
  };

  const onOrOff = allowHelpOperate ? "off" : "on";

  return (
    <Spin spinning={loading}>
      <section className={styles.module}>
        <div className="flex">
          <p className={styles.title}>{intl["profile.service.title"]}</p>
          <div
            className={styles.titleActionLink}
            onClick={() => {
              setAllowHelpOperateModal(true);
            }}>
            {intl["profile.service.btn"][onOrOff]}
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.serviceAccountContainer}>
          <p className={styles.accountText}>
            {allowHelpOperate
              ? intl["profile.service.content.off"]
              : intl["profile.service.content"]}
          </p>
        </div>
        <Modal
          wrapClassName={styles.modal + " " + styles.turnOnServiceModal}
          title={intl["profile.service.modal.title"][onOrOff]}
          visible={allowHelpOperateModal}
          onOk={handleConfirm}
          onCancel={() => {
            setAllowHelpOperateModal(false);
          }}
          centered={true}
          destroyOnClose={true}
          okText={intl["button.confirm"]}
          cancelText={intl["button.cancel"]}
          width={480}>
          <div className={styles.serviceModalContent}>
            <p className={styles.text}>{intl["profile.service.first.content"][onOrOff]}</p>
            <p className={styles.text} style={{ margin: "21px 0" }}>
              {intl["profile.service.second.content"][onOrOff]}
            </p>
            <Descriptions
              bordered
              column={1}
              labelStyle={{
                width: 104,
                background: "#2f3342",
                paddingLeft: 22,
                color: "#fff"
              }}
              contentStyle={{ background: "#1f212f", color: "#fff" }}>
              <Descriptions.Item
                label={intl["profile.service.modal.operation"]}
                contentStyle={{ textAlign: "center", fontSize: 14 }}>
                {intl["profile.service.modal.permission"]}
              </Descriptions.Item>
              <Descriptions.Item label={intl["profile.service.modal.trade"]}>
                {intl["profile.service.modal.trade.permission"]}
              </Descriptions.Item>
              <Descriptions.Item label={intl["profile.service.modal.yield"]}>
                {intl["profile.service.modal.yield.permission"]}
              </Descriptions.Item>
              <Descriptions.Item label={intl["profile.service.modal.strategies"]}>
                {intl["profile.service.modal.strategies.permission"]}
              </Descriptions.Item>
            </Descriptions>
            <p className={styles.text} style={{ marginTop: 21 }}>
              {intl["profile.service.confirm.tip"][onOrOff]}
            </p>
          </div>
        </Modal>
      </section>
    </Spin>
  );
};

export default ServiceAccount;
