export default {
  available: "可用额",
  logout: "登出",
  resetPsw: "重置密码",
  failed: "失败",
  canceled: "取消",
  settled: "已结算",
  unsettled: "未结算",
  copied: "已复制",
  go: "Go",
  registered: "Registered", // 状态
  verified: "Verified", // 状态
  chinese: "中文",
  english: "英文",
  "empty.list": "暂无数据",

  /* 菜单 */
  "menu.home": "首页",
  "menu.yield": "理财",
  "menu.trade": "交易",
  "menu.strategies": "策略",
  "menu.intelligence": "资讯",
  "menu.history": "我的投资",
  "menu.wallet": "钱包",

  /* button按钮 */
  "button.deposit": "充币",
  "button.deposit.crypto.currency": "数字货币入金",
  "button.deposit.fiat.currency": "法币入金 - USDC",
  "button.deposit.fiat.circle": "Circle入金",
  "button.withdraw.crypto.currency": "数字货币提币",
  "button.withdraw.fiat.circle": "USD提币 - Circle",
  "button.transfer.from.bank": "法币入金 - Circle",
  "button.cancel": "取消",
  "button.processing": "取消中",
  "button.confirm": "确认",
  "button.ok": "确认",
  "button.resubmit": "重新提交",
  "button.continue": "继续",
  "button.view": "查看",
  "button.add": "添加",
  "button.search": "搜索",
  "button.manage": "操作",
  "button.refresh": "刷新",
  "button.submit": "提交",
  "button.next": "下一步",
  "button.invest": "定制",
  "button.next.step": "下一步",
  "button.start.now": "开始",
  "button.noCode": "没有邀请码并继续",
  "button.confirm.submit": "确认并提交",
  "button.confirm.subscribe": "确认申购",
  "button.delete": "删除",
  "button.sure.delete": "确认删除",
  "button.close": "关闭",
  "button.closed": "已关闭",
  "button.loadMore": "加载更多…",
  "button.back": "返回",
  "button.subscribe": "申购",
  "button.redeem": "赎回",
  "button.submit.application": "提交申请",
  "button.apply": "申请",
  "button.turn.on": "开启",
  "button.turn.off": "关闭",
  "button.copy": "复制",
  "button.authorize": "授权",
  "button.reject": "拒绝",
  "button.my.invitation": "我的邀请列表",
  "button.my.auto.invest.plan": "我的定投计划",
  "button.comingSoon": "即将开售",

  /* 登录注册 */
  hiddenAmount: "****",
  register: "注册账号",
  "register.now": "去注册",
  register01: "请输入邀请码",
  inviteErrCode: "你的邀请码不正确，请检查并重试。",
  register03: "邀请码",
  "register.back": "返回",
  "register.name": "名称",
  "register.email": "邮箱",
  "register.password": "设置密码",
  "register.passwordConfirm": "确认密码",
  "register.verify": "验证码",
  "register.sendverify": "发送",
  "register.resend": "重新发送",
  "register.enterCode": "请输入你的邀请码",
  "register.email.incorrect": "邮箱错误",
  "register.t&c.01": "我已阅读并同意",
  "register.t&c.02": "Aspen Digital用户协议",
  "register.t&c.03": "Aspen Digital隐私政策",
  "register.t&c.04": "和",
  "register.sub.title": "多重登录注册",
  "register.sub.main": "主账号",
  "register.sub.email": "多重登录邮箱",
  "reset.login": "重置登录密码",
  "reset.login.confirm": "确认密码",
  "reset.login.text": "密码须由 6-40 组合并要大写，小写英文和数字。",
  "new.email": "输入新的邮箱地址",
  "new.password": "新密码",
  "old.password": "当前密码",
  "different.password": "请输入新密码（须跟旧密码不同）",
  "confirm.password": "确认新密码",
  "forget.password": "忘记密码",
  "forget.password.text": "请输入你的名称",
  "forget.account": "名称",
  "forget.email.label": "邮箱",
  login: "登录",
  "logout.out": "已登出，请重新登录",
  "login.text": "欢迎来到Aspen Digital",
  "login.text.CMS": "CMS登录",
  "login.text.SAS": "SAS登录",
  "login.email": "邮箱",
  "login.email.verify.code": "邮箱验证码",
  "login.google.verify.code": "谷歌验证码",
  "login.password": "密码",
  "login.forget": "忘记密码",
  "login.noAccount": "没有账号?",
  "login.network.error": "网络异常，请稍后重试",
  "login.title": "你的一站式数字资产管理平台",
  "login.title.CMS": "客户管理系统",
  "login.title.SAS": "帮客操作系统",
  "link.to.login": "去登录",

  /* KYC */
  "kyc.rejected": "拒绝原因",
  "kyc.basic.info": "基本信息",
  "kyc.individual.fullname": "全名",
  "kyc.individual.age": "年龄",
  "kyc.individual.gender": "性别",
  "kyc.individual.birth": "出生日期",
  "kyc.individual.nationality": "国籍",
  "kyc.individual.passportId": "护照/身份证",
  "kyc.individual.cardId": "护照/身份证",
  "kyc.individual.contact": "联系信息",
  "kyc.individual.address": "居住地址",
  "kyc.individual.funds": [
    {
      label: "年收入 (USD)",
      name: "annualIncome",
      options: [
        "0 - 1,000,000",
        "1,000,001 - 5,000,000",
        "5,000,001 - 10,000,000",
        "More than 10,000,000"
      ]
    },
    {
      label: "主要收入来源",
      name: "sourceOfIncome",
      options: [
        "Self-Employed / Business Income",
        "Employment Income",
        "Investment",
        "Inheritance",
        "Gift",
        "Bank Loan"
      ]
    },
    {
      label: "投资收益来源",
      name: "sourceOfFund",
      options: ["Client’s Investment", "Business Income", "Family Wealth", "Inheritance"],
      tips: "Amount of funds available at any time"
    },
    {
      label: "流动资产 (USD)",
      name: "liquidAssets",
      options: [
        "0 - 1,000,000",
        "1,000,001 - 5,000,000",
        "5,000,001 - 10,000,000",
        "More than 10,000,000"
      ],
      tips: "Amount of funds available at any time"
    },
    {
      label: "总资产 (USD)",
      name: "totalAssets",
      options: [
        "0 - 1,000,000",
        "1,000,001 - 5,000,000",
        "5,000,001 - 10,000,000",
        "More than 10,000,000"
      ],
      tips: "Total asset quota, including stocks, bonds, funds, investment gold and other valuable investment products. It does not include the pledged assets, cars, real estate, jewelry and other consumer investment products."
    }
  ],
  "kyc.certify.institutions.name": "公司名称",
  "kyc.certify.institutions.authorizedRepresentative": "已授权代表",
  "kyc.certify.institutions.authorizedName": "公司代表名称",
  "kyc.certify.institutions.authorizedTitle": "公司代表职衔",
  "kyc.certify.institutions.authorizedPhone": "电话号码",
  "kyc.certify.institutions.authorizedEmail": "公司代表邮箱",
  "kyc.certify.institutions.business.nature": "企业性质 (MC)",
  "kyc.certify.institutions.business.nature.tips":
    "请提供贵司的企业商业性质（包括行业，提供的服务，一般客户群，运营国家，收款渠道等）",
  "kyc.certify.institutions.taxIdentificationNumber": "TIN (BR Number)",
  "kyc.certify.institutions.taxIdentificationNumberTips": "税务编号 (如不适用, 请填写Nil)",
  "kyc.certify": "KYC",
  "kyc.certify.institutions.CountryIncorporation": "公司注册地（国家）",
  "kyc.certify.institutions.address": "公司注册地址", //Principle address of office
  "kyc.certify.institutions.entity.type": "企业类型",
  "kyc.certify.institutions.entityTypes": ["Private Limited", "Public Limited", "Unincorporated"],
  "kyc.certify.institutions.fund": "财富来源",
  "kyc.certify.institutions.fundTypes": [
    {
      label: "投资收益来源",
      name: "sourceOfFund",
      options: ["Investment", "Business Income"],
      tips: ""
    },
    {
      label: "流动资产 (USD)",
      name: "liquidAssets",
      options: [
        "0 - 1,000,000",
        "1,000,001 - 5,000,000",
        "5,000,001 - 10,000,000",
        "More than 10,000,000"
      ],
      tips: "Amount of funds available at any time"
    },
    {
      label: "总资产 (USD)",
      name: "totalAssets",
      options: [
        "0 - 1,000,000",
        "1,000,001 - 5,000,000",
        "5,000,001 - 10,000,000",
        "More than 10,000,000"
      ],
      tips: "Total asset quota, including stocks, bonds, funds, investment gold and other valuable investment products. It does not include the pledged assets, cars, real estate, jewelry and other consumer investment products."
    }
  ],
  "kyc.certify.tips": "请确保填写KYC的信息是正确无误。",
  "kyc.certify.comfirm.tips": "请检查一下填写资料是否正确并点击提交。",
  "kyc.step1": "填写KYC信息",
  "kyc.step2": "确认KYC信息",
  "kyc.step3": "完成",
  "kyc.information": "KYC信息",
  "kyc.certify.location": "位置",
  "kyc.institutions": "企业",
  "kyc.individuals": "个人",
  "kyc.not.certified.text": "未验证",
  "kyc.not.certified.tips": "请到个人中心进行KYC验证",
  "kyc.certified.text": "已验证",
  "kyc.rejected.text": "已拒绝",
  "kyc.confirm": "请确认KYC信息",
  "kyc.submit.info": "已收到你的KYC资料",
  "kyc.info.back": "返回去修改",
  "kyc.status.under.review": "审批中",
  "kyc.btn.modify": "修改并提交",
  "kyc.status.under.status": "状态",
  "kyc.informations": "你的KYC信息",
  "kyc.empty.tips": "字段必填",
  "kyc.gender.male": "男",
  "kyc.gender.female": "女",

  /* 交易 */
  "trade.chart.time": "时间",
  "trade.chart.tradingview": "TradingView",
  "trade.chart.origin": "Origin",
  "trade.chart.24h.up&down": "24h 涨跌幅",
  "trade.chart.24h.hign&low": "24h 最高 - 最低",
  "trade.chart.24h.volume": "24h 交易量",
  "trade.chart.kline": "K线图",
  "trade.chart.depth": "深度图",
  "trade.orderbook.title": "订单簿",
  "trade.openorder": "当前委托订单",
  "trade.history": "订单记录",
  "trade.placeOrder.title": "下单",
  "trade.placeOrder.tips.market": "市价手续费: 0.1%",
  "trade.placeOrder.tips.taker": "限价手续费: 0.1%",
  "trade.placeOrder.buy": "买入",
  "trade.placeOrder.sell": "卖出",
  "trade.placeOrder.limit": "限价",
  "trade.placeOrder.market": "市价",
  "trade.placeOrder.balance": "可用余额",
  "trade.placeOrder.price": "价格",
  "trade.placeOrder.amount": "数量",
  "trade.placeOrder.total": "总数",
  "trade.placeOrder.buybtn": "买入",
  "trade.placeOrder.sellbtn": "卖出",
  "trade.placeOrder.modal.text.first": "你正在代表你的客户交易",
  "trade.placeOrder.modal.text.second": "确认该下单？",
  "trade.order.list.view.all": "查看所有",
  "trade.order.detail": "订单详情",
  "trade.order.cancel.modal.title": "确认取消",
  "trade.order.cancel.modal.content.self": "确认取消该订单？",
  "trade.order.cancel.modal.content.not.self": "确认取消该订单 - {type} ({email})?",

  /* 首页 */
  "home.24h.volume": "24h 交易量",
  "home.deposit": "数字货币入金",
  "home.deposit.fiat": "购买USDT",
  "home.kyc.title": "需通过验证KYC",
  "home.kyc.content": "请在入金前完成KYC",
  "home.kyc.action": "继续KYC",
  "home.no.depsoit": "你还没有入金",
  "home.no.depsoit.sub": "探索高收益的理财产品：",
  "home.no.depsoit.modal.sub": "目前还没有入金，请先为你的账户充值。",
  "overview.assetDistribution.title": "资产分布", // 资产分配
  "overview.assetDistribution.total": "总资产 (USD)",
  "overview.myAsset.day": "天",
  "overview.myAsset.week": "周",
  "overview.myAsset.month": "月",
  "overview.myAsset.year": "年",
  "overview.assetSituational.title": "资产表现", // 资产状况
  "overview.myAsset.title": "我的资产",
  "overview.myAsset.spot": "现货",
  "overview.myAsset.action.successful": "成功",
  "action.deposit": "充币",
  "action.redeem": "赎回",
  "action.subscribe": "申购",
  "action.view": "查看",
  "action.cancel": "取消",
  "agency.tips.text": "你正操作客户账号 ",
  "agency.tips.text.sub": "你正操作客户账号 ",
  "overview.myAsset.action.withdraw": "提币",
  "overview.savings.title": "理财",
  "overview.savings.automatic.title": "定投计划",
  "overview.savings.automatic.content": "开启定投计划，实现平均价买入策略。",
  "overview.savings.view.all": "查看所有产品",
  "overview.savings.item.type": "Savings",
  "overview.savings.item.apy": "预估APY",
  "overview.savings.item.btn.subscribe": "申购",
  "overview.announcement.new": "New",
  "overview.strategy.view.all": "查看所有策略 ",
  "overview.savings.value.date": "发息时间",
  "overview.savings.maximum": "最大申购额",
  "overview.savings.apy": "APY",
  "overview.savings.lots": "份",
  "overview.savings.redeem": "赎回",
  "overview.savings.redeem.tips": "赎回金额会在24小时内到达你的现货账户",
  "overview.savings.subscribe": "申购",

  /* 理财 */
  "savings.flexible": "活期",
  "savings.fixed": "定期",
  "savings.autoInvestment": "定投",
  "savings.append.balance": "可用余额",
  "savings.redeem.available": "可赎回额",
  "savings.append.amount": "数量",
  "savings.history.subscription": "申购",
  "savings.history.redemption": "赎回",
  // TODO: 状态
  "savings.history.success": "Success",
  "savings.history.failed": "Failed",
  "savings.history.pending": "Pending",
  "savings.auto.compound.tips": "开启后，在现货账户的余额会自动转入申购活期理财。",
  "savings.auto.compound.on": "当开启自动续投后，你的现货账户的可用余额都会自动申购活期理财。",
  "savings.auto.compound.off": "确认关闭自动续投？关闭后，利息将不会申购活期理财。",
  "savings.auto.purchase.tips":
    "一种无风险的投资策略：发放的利息会按照所制定的定投计划买入对应币种",
  "savings.automatic.name": "{coin} 定投计划",
  "savings.automatic.my.title": "我的定投计划",
  "savings.automatic.my.fail.tips": "定投计划被终止因连续3次余额不足而无法进行定投。",
  "savings.automatic.add.breadcrumb.first": "定投计划",
  "savings.automatic.add.breadcrumb.second": "添加新计划",
  "savings.automatic.add.title": "添加定投计划",
  "savings.automatic.add.choose.plan": "选择一项定投计划",
  "savings.automatic.add.title.amount.cycle": "数量和周期",
  "savings.automatic.add.available": "可用数量:",
  "savings.automatic.add.amount.cycle": "每次定投数量",
  "savings.automatic.add.cycle": "定投周期",
  "savings.automatic.add.success": "申购成功",
  "savings.automatic.add.fail": "申购失败",
  "savings.automatic.add.error.tips.first": "你的账户余额不足",
  "savings.automatic.add.error.tips.second": "最小定投额是：",
  "savings.automatic.add.error.tips.third": "最大定投额是：",
  "savings.automatic.modal.title": "定投计划",
  "savings.automatic.modal.denomintaion": "币种", // 原Cryptocurrency
  "savings.automatic.modal.price": "价格",
  "savings.automatic.modal.amount": "数量",
  "savings.automatic.modal.ratio": "比例",
  "savings.automatic.modal.amount.cycle": "每次定投数量",
  "savings.automatic.modal.cycle": "定投周期",
  "savings.automatic.modal.investment.amount": "定投数量",
  "savings.automatic.modal.fee": "手续费",
  "savings.automatic.modal.revenueRate": "预估定投回报率",
  "savings.automatic.modal.date": "下一个定投日（减扣现货余额）",
  "savings.automatic.modal.transfer": "自动转入活期理财",
  "savings.automatic.modal.transfer.tips": "开启后，定投买到的币会自动转入活期理财而获取更多利息。",
  "savings.automatic.modal.cancel.title": "取消定投计划",
  "savings.automatic.modal.cancel.content": "确认取消定投计划？一旦取消后该币种的定投计划会停止。",
  "savings.automatic.history.btn": "定投历史记录",
  "savings.automatic.history.Comfirm.time": "确认时间",
  "savings.automatic.history.TxID": "TxID",
  "fixed.deposit.subscribe.fail": "申购失败",
  "savings.fixed.list.days": "天",
  // TODO: 申购、已售罄、锁仓中、即将开售 状态 暂时不翻译 牵扯到逻辑判断有问题
  "savings.fixed.list.btn.subscribe": "Subscribe",
  "savings.fixed.list.btn.sellOut": "Sell Out",
  "savings.fixed.list.btn.cutOff": "Cut Off",
  "savings.fixed.list.btn.comingSoon": "Coming Soon",
  "savings.fixed.lockUp": "锁仓",
  "savings.fixed.location.lockUp": "定期理财",
  "savings.fixed.lockUp.subscribe": "定期理财申购",
  "savings.fixed.subscription.unit": "申购份额",
  "savings.fixed.input.placeholder": "请输入申购份额",
  "savings.fixed.available.unit": "可用份额",
  "savings.fixed.available.unit.tips": "这是以可用余额去计算可买最多的份额数",
  "savings.fixed.lots": "份",
  "savings.fixed.total.amount": "申购总额",
  "savings.fixed.max.subscription.unit": "最大申购份额",
  "savings.fixed.estimated.interest": "预估利息",
  "savings.fixed.estimated.interest.tips": "以申购数量所预估的利息",
  "savings.fixed.max": "最大",
  "savings.fixed.transfer.flexible": "自动转入活期理财",
  "savings.fixed.transfer.flexible.tips": "当锁仓时间结束时，本金与利息会自动申购活期理财。",
  "savings.fixed.auto.subscribe.next.cycle": "自动续投下期定期理财",
  "savings.fixed.auto.subscribe.next.cycle.tips":
    "当定期理财的锁仓期完结后，你的本金和利息会自动续投到下一个定期理财产品。（如果没有下期，本金和利息会转入现货）。",
  "savings.fixed.details": "详情",
  "savings.fixed.subscription": "申购",
  "savings.fixed.cut.off": "申购结束时间",
  "savings.fixed.start": "开始: ",
  "savings.fixed.end": "结束: ",
  "savings.fixed.interests": "发息",
  "savings.fixed.Transfer.time": "在锁仓结束当天，本金与利息发还给用户",
  "savings.fixed.lock.period": "锁仓周期",
  "savings.fixed.days": "天",
  "savings.fixed.total.available.amount": "已售出总量",
  "savings.fixed.per.unit": "每份金额",
  "savings.fixed.subscription.amount": "申购数量",
  "savings.fixed.depisit.apy": "定期APY",
  "savings.fixed.flexible.deposit.apy": "活期APY",
  "savings.fixed.tiered.apy": "APY梯度",
  "savings.fixed.error.tips.first": "最小申购份额: 1, 请重新输入",
  "savings.fixed.error.tips.second": "余额不足，请再输入申购数量",
  "savings.fixed.error.tips.third": "可申购份额: {remainingLots}",
  "savings.fixed.error.tips.fourth": "最大申购份额: {personalShareLimit}",
  "savings.fixed.error.tips.fifth": "申购份额大于 {personalShareMinLimit}",
  "fixed.deposit.product.subscribe.not.begin": "定期理财申购还没开始",
  "savings.modal.flexible.subscribe.title": "申购{currency}理财",
  "savings.modal.title.turn.on.auto.compound": "开启自动续投",
  "savings.modal.title.turn.off.auto.compound": "关闭自动续投",
  "savings.modal.title.off.auto.purchase.tip": "请关闭利息定投买币",
  "savings.auto.compound.off.auto.purchase.tip.content": "请先关闭利息定投买币功能，再开启自动续投",
  "savings.modal.title.turn.on.auto.purchase": "自动利息定投买币",
  "savings.modal.title.turn.off.auto.purchase": "关闭自动利息定投买币",
  "savings.modal.flexible.balance": "活期理财余额：",
  "savings.modal.daily.interest": "每日利息：",
  "savings.modal.cryptocurrency.to.purchase": "购买币种：",
  "savings.modal.auto.purchase.time": "定投时间：",
  "savings.modal.interest.used.to.purchase": "利息买币金额：",
  "savings.modal.auto.purchase.on.content.tip":
    "将以每日利息购买已选币种（以达最小申购金额）。 你可以随时暂停此功能",
  "savings.modal.auto.purchase.off.content.tip": "请确认关闭自动利息定投买币功能？",
  "savings.modal.select.cryptocurrency.to.purchase": "请选择购买币种",
  "savings.modal.auto.purchase.interest.not.enough.tip":
    "{currency}币种活期存款的利息不满足 1 的最低购买金额要求。请增加您的{currency}活期存款金额，再开启此功能。",
  "savings.modal.auto.purchase.success": "已成功开启自动利息定投买币",
  "savings.modal.auto.purchase.success.tip": "你已成功开启自动利息定投买币，自动续投功能会被关闭。",
  "savings.modal.auto.purchase.toast": "你已成功{type}自动利息定投买币",
  "savings.modal.auto.purchase.time.value": "每天08:30-09:00",

  /* 资讯 */
  "intelligence.dailyBrifing": "每日简报",
  "intelligence.loadMore": "加载更多",
  "intelligence.announcement": "最新公告",
  "intelligence.nothingMore": "暂无更新",

  /* 钱包 */
  wallet: "钱包",
  "wallet.total": "总资产",
  "wallet.accountRisk": "策略YTD表现",
  "wallet.spot.account": "现货账户",
  "wallet.type.stategy": "策略账户",
  "wallet.type.savings": "理财账户",
  "wallet.viewDetails": "查看详情",
  "wallet.history": "历史",
  "wallet.yield.flexible": "活期",
  "wallet.yield.fixed": "定期",
  "wallet.strategies.ytd.tips": "YTD 的时间段为当前日历年或财政年度的第一天开始到当前日期。",
  "wallet.table.spot.tips.totalAmount": "包含可用资产和因提币、申购、赎回、交易而暂时冻结的资产",
  "wallet.table.spot.tips.available": "可提现、申购、下单的可用资产",
  "wallet.table.spot.tips.frozen": "申购/赎回，下单，提现的资产会在暂时冻结状态",
  "wallet.table.yield.tips.totalAmount": "总申购成功数量",
  "wallet.table.yield.tips.cumulativeInterest": "总发放利息",
  "wallet.table.noStategies.tips": "暂无持仓",
  "wallet.table.withdraw.tips": "{coinCode}暂不支持提币",
  "wallet.table.withdraw.aum.title": "资管费通知",
  "wallet.table.withdraw.aum.tips": "有未付的资管费。",
  "wallet.table.withdraw.aum.ask.tips": "确认付款和提币？",
  "wallet.table.withdraw.aum.fail.tips": "请在提币前充值USDC。",
  "wallet.table.deposit.title": "充币方式",
  "wallet.table.withdraw.title": "提币方式",
  "wallet.deposit.btc": "充币",
  "wallet.deposit.btc.title": "BTC",
  "wallet.deposit.btc.select": "选择币种",
  "wallet.deposit.btc.select.network": "主链类型",
  "wallet.minimum.deposit": "最小充币额: ",
  "wallet.deposit.btc.address": "充币地址",
  "wallet.deposit.btc.tag": "标签",
  "wallet.deposit.btc.qrcode.tips": "扫码获取{currentCoin}地址",
  "wallet.deposit.btc.tips1": "扫码或复制充币地址入金",
  "wallet.deposit.btc.tips2": "只支持{currentCoin}可选主链类型",
  "wallet.deposit.btc.notice": "注意",
  "wallet.deposit.btc.notice.first":
    "· 此地址只接受 {currentCoin} 充币，任何其他加密货币资产充入此地址都不可找回和不予处理。",
  "wallet.deposit.btc.notice.second":
    "· 充币需要{depositNetwork}个区块确认，你的资产在第{availableNetwork}个区块确认前都不能提现。",
  "wallet.deposit.history": "充币历史记录",
  "wallet.deposit.history.viewall": "查看全部",
  "wallet.deposit.modal.title": "提示",
  "wallet.deposit.modal.content": "{currency} 暂不支持充币，请选择其他数字货币",
  "wallet.deposit.USDC.method.tip": "请选择下列充币方式",
  "wallet.withdraw.USDC.method.tip": "请选择下列提币方式",
  "wallet.withdraw.history": "提币记录",
  "wallet.withdraw.success.tips": "提币提交成功",
  "wallet.withdraw.failed.tips": "提币提交失败",
  "wallet.withdraw.get.bank": "银行卡数据获取失败",
  "coin.address.invalid": "无效地址", // 接口返回
  "2009": "电子货币超出单笔上限", // 电子货币超出单笔上限code
  "2005": "电子货币小于单笔上限t", // 电子货币小于单笔上限code
  "2007": "电子货币超出单笔上限", // 电子货币超出单笔上限code
  "5210": "法币超出单日上限", // 法币超出单日上限code
  "wallet.withdraw.limit": "最大提币额:",
  "wallet.withdraw.amount": "提币数量",
  "wallet.withdraw.max": "MAX",
  "wallet.withdraw.amount.placeholder": "可用额 1.23456 BTC",
  "wallet.withdraw.notice": "注意:",
  "wallet.withdraw.notice.content": [
    "· Only withdraw to the <span>'Bank Account'</span> is supported, please add the account to the <span>'Bank List'</span> before withdrawing.",
    "· 提币会在1-2工作日内完成。",
    "· 如果你有任何问题，可以找客户经理或发邮件到 support@aspendigital.co。"
  ],
  "wallet.withdraw.title": "{currentCoin}提币",
  "wallet.withdraw.btc.address": "提币到账地址",
  "wallet.withdraw.btc.address.placeholder": "请输入提币到账地址",
  "wallet.withdraw.amount.error.tips.first": "最小提币额",
  "wallet.withdraw.amount.error.tips.second": "最大提币额",
  "wallet.withdraw.amount.error.tips.third": "你的可提币额 ",
  "wallet.withdraw.amount.error.tips.fourth": "单笔最大提币额",
  "wallet.withdraw.amount.error.tips.fifth": "每周最大提币额",
  "wallet.withdraw.amount.error.tips.sixth": "每月为大提币额",
  "wallet.withdraw.fiat.address": "银行账号",
  "wallet.withdraw.fiat.bind": "绑定新的银行账号",
  "wallet.withdraw.fiat.placeholder": "请选择银行卡",
  "wallet.withdraw.fee": "手续费: {fee}",
  "wallet.withdraw.usdc.notice.tips": "你可以使用Circle进行提现USDC",
  "wallet.withdraw.usdc.notice.content": [
    "· 你只能提现USD到你绑定的银行账号。",
    "· 整个提现大概需要1-3个工作天。",
    "· 实际到账金额需扣除手续费和银行汇款手续费。",
    "· 如果你有任何问题，可以找客户经理或发邮件到 support@aspendigital.co。"
  ],
  "wallet.circle.title": "法币入金 - USDC",
  "wallet.circle.add.title": "添加银行账号",
  "wallet.circle.notice": "注意:",
  "wallet.circle.bank.information": "银行信息",
  "wallet.circle.bank.name": "银行名称",
  "wallet.circle.swift": "Swift",
  "wallet.circle.country": "国家/区",
  "wallet.circle.city": "城市",
  "wallet.circle.bank.address": "银行地址",
  "wallet.circle.bank.account": "银行账户",
  "wallet.circle.account.number": "银行账号号码",
  "wallet.circle.account.information": "银行账号信息",
  "wallet.circle.name": "全名",
  "wallet.circle.postal": "邮政编码",
  "wallet.circle.address": "地址",
  "wallet.circle.bank.statements": "银行账单",
  "wallet.circle.upload.sgin": "上传银行账单",
  "wallet.circle.upload.tips": [
    "· 请确保银行账单信息清晰可见.",
    "· 请确保你上传的银行账单与你填写的银行信息一致。"
  ],
  "wallet.circle.add.notice.content": [
    "· 请上传最近3个月的银行账单。",
    "· 你的名称、地址与银行账号号码必须与上传的银行账单一致，否则申请会被拒绝。"
  ],
  "wallet.circle.add.modal.title": "添加银行账号",
  "wallet.circle.add.modal.content": "请在Circle入金或法币入金前添加银行账号",
  "wallet.circle.max.deposit.amount": "最大入金额",
  "wallet.circle.pin": "PIN",
  "wallet.circle.deposit.notice.content":
    "请在备注/信息给收款方填写你的PIN, 否则该转账会失败退回并Circle回收取需50USD的退换费。",
  "wallet.circle.wiring.information": "汇款资料",
  "wallet.circle.beneficiary": "收款方",
  "wallet.circle.bank.beneficiary.name": "收款银行",
  "wallet.circle.wire.routing": "Routing Number",
  "wallet.circle.instructions": "注意",
  "wallet.circle.instructions.content": [
    "・ 一般入金需时1-3个工作天。",
    "・ 每次成功到账, Circle会收取2 USD的手续费。",
    "・ 请不要超过最大入金额, 否则会被退回转账并且Circle会收取50 USD的手续费。"
  ],
  "wallet.circle.withdraw.fee": "Circle提币手续费:",
  "wallet.circle.modal.review.title": "银行账号资料审核中",
  "wallet.circle.modal.review.content": "你的资料已成功提交并正在审核中",
  "wallet.circle.modal.reject.title": "银行账号添加失败",
  "wallet.circle.modal.reject.title.reason": "原因: ",
  "wallet.circle.modal.reject.content": "你的银行账号添加失败，请检查你的资料并再次提交。",
  "wallet.AUM.fee.rate": "资管费",
  "wallet.AUM.fee.rate.tips": "每月初会计算上月的资管费, 并会以USDC在当月10号进行扣款。",
  "wallet.AUM.fee.rate.link": "查看资管费历史记录",
  "wallet.AUM.fee.title": "资产管理费",
  "wallet.AUM.fee.total": "总资管费",
  "wallet.AUM.fee.settled": "已付资管费",
  "wallet.AUM.fee.unsettled": "未付资管费",
  "wallet.AUM.fee.modal.fee": "未付费: ",
  "wallet.AUM.fee.modal.balance": "可用余额: ",
  "wallet.aum.fee.deduct.failed": "资产管理费扣除失败",
  "wallet.fixed.modal.locked.up.period": "锁仓周期",
  "wallet.fixed.modal.total.available.amount": "总数量",
  "wallet.fixed.modal.fixed.deposit.apy": "定期APY",
  "wallet.fixed.modal.flexible.deposit.apy": "活期APY",
  "wallet.fixed.modal.amount.per.unit": "每份金额",
  "wallet.fixed.modal.subscription.unit": "申购份额",
  "wallet.fixed.modal.estimated.interest": "预估利息",
  "wallet.fixed.modal.estimated.interest.tips": "以申购数量所预估的利息",
  "wallet.fixed.modal.transfer.into.flexible": "自动转入活期理财",
  "wallet.fixed.modal.transfer.into.flexible.tips": "当锁仓期完结，本金和利息会自动转入活期理财。",
  "wallet.fixed.modal.auto.subscribe.next.cycle": "自动续投下期定期理财",
  "wallet.fixed.modal.auto.subscribe.next.cycle.tips":
    "当定期理财的锁仓期完结后，你的本金和利息会自动续投到下一个定期理财产品。（如果没有下期，本金和利息会转入现货）。",
  "wallet.fixed.modal.cancel.title": "取消定期理财",
  "wallet.fixed.modal.content.confirm": "确认取消定期理财？",
  "wallet.fixed.modal.content.tip": "确认后，你的申购额会回到你的现货账户里。",
  "wallet.fixed.modal.cut.off.time": "申购结束时间",
  "wallet.fixed.modal.start": "开始: ",
  "wallet.fixed.modal.end": "结束: ",
  "wallet.fixed.modal.transfer.to.spot": "在锁仓结束当天，",
  "wallet.fixed.modal.flexible.within.day": "本金与利息发还给用户",
  "wallet.fixed.modal.tiered.apy": "APY梯度",
  "wallet.fixed.modal.subscription": "申购",
  "wallet.fixed.modal.locked.up": "锁仓周期",
  "wallet.fixed.modal.interests": "发息",
  "wallet.fixed.modal.subscription.amount": "申购数量",
  "wallet.fixed.modal.apy.tip.first": "1. 在申购后到锁仓之前的时间，可享受活期理财",
  "wallet.fixed.modal.apy.tip.second":
    "2. 在锁仓期间, 当你的申购额度超过梯度1的申购额, 超出的额度可享有梯度2的APY, 如此类推",
  "wallet.fixed.modal.apy.tip.third": "3. 最终利息是基于梯度的APY和申购数量。",
  "wallet.fixed.modal.cancel.subscription.fail": "取消失败",
  "wallet.fixed.modal.cancel.subscription.success": "取消成功",
  "wallet.fixed.subscribing": "Subscribing",
  "wallet.fixed.table.apy.tips": "APY",
  "wallet.flexible.table.auto.purchase.tips": "每日发放的利息会去定投购买数字货币。",
  "wallet.fixed.modal.transfer.to.flexible.tip": "转入活期理财",
  "wallet.fixed.modal.transfer.to.flexible.cancel.tip": "取消转入活期理财",
  "wallet.fixed.modal.auto.subscribe.tip": "自动续投下期定期",
  "wallet.fixed.modal.auto.subscribe.cancel.tip": "取消自动续投",
  "wallet.fixed.modal.auto.subscribe.checked.cancel.tips": "操作前，请取消“自动续投下期活期理财”",
  "wallet.fixed.modal.transfer.to.flexible.checked.cancel.tips": "操作前，请取消“自动转入活期理财”",
  "bank.account": "银行账户",

  /* 历史记录 - My Investment */
  "history.title": "我的投资",
  "history.strategy.cancel.modal.title": "确认取消",
  "history.strategy.cancel.modal.self.content": "确认取消该订单？",
  "history.strategy.cancel.modal.not.self.content": "确认取消该订单 - {type} ({email})?",
  "history.strategy.subscribe.detail": "申购详情",
  "history.strategy.redeem.detail": "赎回详情",
  "history.spot.account.title": "现货账户记录",

  /* 个人中心 */
  profile: "个人中心",
  "profile.referral": "邀请链接",
  "profile.invitation": "邀请链接",
  "profile.my.invitation.title": "我的邀请列表",
  "profile.uid": "UID",
  // TODO: 业务已移除，代码未更改，待处理
  "profile.contactus": "Contact US",
  "profile.bank.delete": "Delete Bank Account",
  "profile.bank.delete.tip1": "Are you sure you want to delete the linked bank account?",
  "profile.bank.delete.tip1.desc":
    "If you delete this bank card, you will not able to use this bank card to withdraw and deposit. Are you sure to delete this bank card？",
  "profile.bank.delete.tip2": "You will not be able to deposit and withdraw",
  "profile.bank.delete.tip2.desc":
    "This is the last bank account you bound. You can not delete your last bank card. If you want to delete this bank card, you need to add another one first.",
  "profile.add.bank": "Add Bank Card Information",
  "profile.add.bank.bankName": "Payee Name",
  "profile.add.bank.bankName.placeholder": "Enter your bank name",
  "profile.add.bank.card.id.placeholder": "Enter your bank card number",
  "profile.add.bank.bank.card.id": "Bank Account Number",
  "profile.add.bank.bank.remark": "Reference",
  "profile.add.bank.bank.notice": [
    "Your name on the bank account should match your name provided during KYC."
  ],
  // 以上待删除
  "profile.basic.info": "基本信息",
  "profile.basic.info.google.auth": "谷歌验证",
  "profile.basic.info.google.tips": "为提高账号安全性，请绑定谷歌验证。",
  "profile.basic.info.google.auth.step1": "第一步：",
  "profile.basic.info.google.auth.step1.content": "下载谷歌验证App。",
  "profile.basic.info.google.auth.step2": "第二步：",
  "profile.basic.info.google.auth.step2.content": "请点击以下查看密钥。",
  "profile.basic.info.google.auth.step2.content.tips":
    "这个是创建谷歌验证的密钥，或是可用扫二维码完成谷歌验证。",
  "profile.basic.info.google.auth.step2.button": "点击查看密钥",
  "profile.basic.info.google.auth.step2.title": "安全验证",
  "profile.basic.info.google.auth.step2.tip1": "验证码会发送到你的邮箱",
  "profile.basic.info.google.auth.step2.tip2": "请输入验证码",
  "profile.basic.info.google.auth.step3": "第三步：",
  "profile.basic.info.google.auth.step3.content": "请把密钥加进谷歌验证, 然后输入6位谷歌验证码。",
  "google.auth.tips": "为提高安全，请输入谷歌验证码去验证你的身份。",
  "google.auth.tips.placeholder": "请输入谷歌验证码",
  "google.verify.not.passed": "帐户安全警告, 请联系客户经理或support@aspendigital.co",
  "profile.change": "更改",
  "profile.change.google.start": "开启",
  "profile.kyc": "KYC信息",
  "profile.kyc.name": "名称",
  "profile.kyc.country": "国家 / 区",
  "profile.kyc.type": "账户类型",
  "profile.kyc.successMessage": "你已成功提交KYC资料",
  "profile.google.verification1": "验证码会发送到你的邮箱",
  "profile.google.verification2": "请输入验证码。",
  "profile.client.email.verification1": "验证码会发送到您的客户邮箱",
  "profile.client.email.verification1.sub": "验证码会发送到您的主账号邮箱",
  "profile.client.email.verification2": "请输入验证码。",
  "profile.google.verification2.verfy.placeholder": "验证码",
  "profile.googlecode.bind.placeholder": "输入6位数字",
  "profile.googlecode.bind.email.code.error.locked": "请重新获取验证码并进行验证",
  "profile.googlecode.using": "Active",
  "profile.googlecode.remove": "输入验证码去解绑谷歌验证。当你解绑后，会以邮箱做验证。",
  "profile.googlecode.remove.tips":
    "如果你有任何问题, 可以找客户经理或发邮件到support@aspendigital.co。",
  "profile.basic.change.password": "更改密码",
  "change.email.address": "更改邮箱地址",
  "change.email.address.tips": "修改邮箱后48小时不能提币。",
  "change.email.address.tips2": "验证已通过，请输入新的邮箱并通过验证。",
  "change.email.address.message": "邮箱已注册",
  "profile.sub.title": "多重账号登录",
  "profile.sub.tag": "Frozen",
  "profile.sub.btn.add": "添加",
  "profile.sub.btn.terminate": "终止",
  "profile.sub.empty.content": "邀请你的助手去管理你的账号",
  "profile.sub.add.title": "邀请多重登录",
  "profile.sub.add.content.one":
    "多重登录授权并允许另一个邮箱去查阅账户余额并操作有关该账户的交易，申购赎回理财和策略产品等等。",
  "profile.sub.add.content.two": "请输入邀请的多重登录邮箱。注册链接会发送到该邮箱，有效期为10天。",
  "profile.sub.add.placeholder": "输入多重登录邮箱",
  "profile.sub.email": "邮箱",
  "profile.sub.authorization": "授权",
  "profile.sub.agreement": "协议",
  "profile.sub.stop.title": "终止授权",
  "profile.sub.stop.content.1st": "当你终止收授权，你的管理者：",
  "profile.sub.stop.content.2nd": "不能再操作你的账户。",
  "profile.sub.stop.content.3th": "请问确认终止授权吗？",
  "profile.sub.error.tips.first": "请输入邮箱",
  "profile.sub.error.tips.second": "邮箱错误",
  "profile.partner.title": "代理商账号",
  "profile.partner.tag.frozen": "Frozen",
  "profile.partner.tag.review": "Reviewing",
  "profile.partner.btn.add": "授权",
  "profile.partner.btn.terminate": "终止",
  "profile.partner.agreement.name": "Aspen Digital 帐户授权他人管理协议",
  "profile.partner.channel.partner": "代理商",
  "profile.partner.email": "邮箱",
  "profile.partner.authorization.period": "授权时间",
  "profile.partner.authorization": "授权",
  "profile.partner.agreement": "协议",
  "profile.partner.stop.title": "终止授权",
  "profile.partner.stop.content.1st": "当你终止收授权，你的管理者：",
  "profile.partner.stop.content.2nd": "不能再操作你的账户。",
  "profile.partner.stop.content.3th": "请问确认终止授权吗？",
  "profile.partner.authorization.period.arr": ["三个月", "半年", "一年"],
  "profile.partner.authorization.detail": [
    {
      key: "交易",
      detail: "代表该账户下单和撤单，并会有一个通知邮件发送给你。"
    },
    {
      key: "理财",
      detail:
        "申购或赎回理财产品，开启或关闭定投。验证码会发送到你的邮箱并需要给Aspen Digital才能完成。"
    },
    {
      key: "策略",
      detail: "申购或赎回策略产品。验证码会发送到你的邮箱并需要给Aspen Digital才能完成。"
    }
  ],
  "profile.partner.authorization.title": "代理商管理授权",
  "profile.partner.authorization.information": "申请资料",
  "profile.partner.authorization.name": "代理商",
  "profile.partner.authorization.email": "邮箱",
  "profile.partner.authorization.permission": "授权申请",
  "profile.partner.authorization.authorization": "授权",
  "profile.partner.authorization.description": "描述",
  "profile.partner.authorization.period.title": "授权时间",
  "profile.partner.authorization.agreement": "Aspen Digital 帐户授权他人管理协议",
  "profile.partner.authorization.agreement.read":
    "我已阅读并同意Aspen Digital 帐户授权他人管理协议",
  "profile.partner.authorization.agreement.read.tips": "请阅读整个协议并向下滚动至底部",
  "profile.partner.modal.authorization.reject": "拒绝授权",
  "profile.partner.authorization.reject": "确认拒绝授权申请？",
  "profile.service.title": "Aspen帮客操作服务",
  "profile.service.btn": {
    on: "开启",
    off: "关闭"
  },
  "profile.service.content":
    "{allowHelpOperate}授权Aspen Digital代表该账户去进行下单，申购和赎回。",
  "profile.service.modal.title": {
    on: "开启Aspen帮客操作服务",
    off: "关闭Aspen帮客操作服务"
  },
  "profile.service.first.content": {
    on: "开启授权Aspen Digital代表该账户操作交易，申购赎回理财和策略。",
    off: "关闭授权Aspen Digital代表该账户操作交易，申购赎回理财和策略。"
  },
  "profile.service.second.content": {
    on: "当开启时, Aspen Digital被授权可做以下操作: ",
    off: "当关闭时, Aspen Digital被授权可做以下操作: "
  },
  "profile.service.confirm.tip": {
    on: "确认开启Aspen帮客服务? ",
    off: "确认关闭Aspen帮客服务? "
  },
  "profile.service.modal.operation": "操作",
  "profile.service.modal.permission": "权限",
  "profile.service.modal.trade": "交易",
  "profile.service.modal.trade.permission": "代表该账户下单和撤单",
  "profile.service.modal.yield": "理财",
  "profile.service.modal.yield.permission": "申购或赎回理财产品，开启或关闭定投。",
  "profile.service.modal.strategies": "策略",
  "profile.service.modal.strategies.permission": "申购或赎回策略产品。",

  /* 策略 */
  "strategies.title": "策略",
  "strategies.item.viewDetail": "查看详情",
  "strategies.detail.strategies": "策略",
  "strategies.detail.details": "详情",
  "strategies.modal.subscribe.title": "策略申购",
  "strategies.modal.subscribe.balance": "可用余额:",
  "strategies.modal.subscribe.amount.title": "申购数量",
  "strategies.modal.subscribe.amount.placeholder": "请输入申购数量",
  "strategies.modal.subscribe.amount.error": "最大申购数量 ",
  "strategies.modal.subscribe.content":
    "在同一个月内不能申购多于一次。如果申购多于一次，会以最后那次申购为准。",
  "strategies.modal.redeem.title": "策略赎回",
  "strategies.modal.redeem.amount.tips.title": "赎回数量:",
  "strategies.modal.redeem.amount.tips.content": "下月更新持仓 * 赎回百分比",
  "strategies.modal.redeem.amount.value.title": "目前最新持仓：",
  "strategies.modal.redeem.input.title": "赎回百分比",
  "strategies.modal.redeem.input.placeholder": "请输入赎回百分比",
  "strategies.modal.redeem.content": "每月每个策略只能赎回一次。如提交多于一次，会以最后那次为准",
  "strategies.modal.subscribe.error.tips": "申购意向提交失败",
  "strategies.modal.redeem.error.tips": "赎回意向提交失败",
  "strategies.modal.result.success.subscribe.title": "申购意向提交成功",
  "strategies.modal.result.success.subscribe.content":
    "我们已收到你的申购意向, 将于每月底进行扣款, 请您确保Spot账户{currency}充足。我们会再次向您确认，请注意查收邮件",
  "strategies.modal.result.success.subscribe.amount": "申购数额",
  "strategies.modal.result.success.subscribe.time": "扣款时间:",
  "strategies.modal.result.success.redeem.title": "赎回意向提交成功",
  "strategies.modal.result.success.redeem.content":
    "我们已收到你的赎回意向，将于下月初进行持仓更新，请注意查收邮件",
  "strategies.modal.result.success.redeem.amount": "赎回比例",
  "strategies.modal.result.success.redeem.time": "更新时间: ",
  "strategies.modal.result.failed.title": "申赎意向提交失败",
  "strategies.modal.result.failed.positive": "此订单已在处理中, 请在处理完毕后再提交。",
  "strategies.modal.result.failed.inverse":
    "同一月不可对同一产品即申购又赎回，您本月对该产品进行过赎回意向的提交，待本月更新持仓后才可再申购。或者您可以取消之前对该产品提交的意向，取消后才可重新申购",

  /* 前端判断提示, 没有分的很清楚 */
  "error.password.format": "请输入6-40英文大写, 小写和数字的组合。",
  "login.multiple.errors": '输入错误太多次, 请在2小时后重试过使用"忘记密码"重新设置密码。',
  "password.not.match": "密码不正确!", //todo, 宏淼提供
  "subscribe.rule": "输入金额需大于最小申购额和递增额",
  "subscribe.amount.more.than.max": "超过最大申购额",
  "subscribe.insufficient.balance": "余额不足，请重新输入",
  "subscribe.amount.less.than.min": "申购金额需大于最小申购额 {minAmount}",
  "subscribe.amount.not.increment": "申购数量需以{incrementAmount}倍数递增",
  "password.changed": "密码已更新",
  "email.changed": "邮箱已更新",
  "google.auth.bind.success": "谷歌验证已绑定",
  "bank.add": "银行卡已绑定",
  "redeem.exceed.maximum": "超过可赎回最大额",
  "redemm.not.zero": "赎回金额不能为0",
  "trade.value.greater.than.order.max": "单笔交易不能大于 {amount} {currency}  ",
  "trade.amount.empty": "必须填写数量",
  "trade.entrust.cancel.success": "成功取消",
  "trade.entrust.success": "成功委托", // 委托成功
  "trade.placed.success": "成功下单", // limit成功
  "trade.purchased.success": "订单已成交", // market成功
  "spot.order.invalid.status": "取消单子失败",
  "spot.order.order.book.cancel.fail": "取消单子失败",
  "spot.order.update.fail": "更新失败",
  "spot.order.trade.duplicate": "订单失败",
  "spot.order.trade.state.illegal": "订单状态有误",
  "spot.customer.stop.trade": "交易状态有",
  "spot.order.book.invoke.fail": "订单失败",
  "spot.order.success": "现货订单成功",
  "spot.order.duplicate": "现货订单重复",
  "spot.order.create.fail": "创建现货订单失败",
  "spot.order.symbol.invalid": "该币种无法在现货交易",
  "spot.order.error": "现货订单失败",
  "spot.order.user.account.insufficient": "用户可用余额不足",
  "spot.entrust.account.insufficient": "用户可用余额不足",
  "spot.entrust.user.account.insufficient": "用户可用余额不足",
  "spot.entrust.cancel.fail": "现货交易取消失败",
  "spot.order.system.account.insufficient": "系统余额不足",
  "spot.order.side.invalid": "现货订单错误参数",
  "spot.order.type.invalid": "现货订单错误类型",
  "spot.order.check.success": "现货订单成功验证",
  "spot.order.min.check": "现货订单小于最小交易额",
  "spot.order.max.check": "现货订单大于最大交易额",
  "spot.order.invalid.parameter": "错误参数",
  minTotalMessage: "最小交易额不能小于 {orderQuoteMin}",
  oneMaxTotalMessage: "最大交易额不能大于{orderQuoteMax}",
  maxAvblMessage: "最大可用 {total}",
  oneMaxBuyMessage: "最大买入额不能大于 {orderAmount}",
  maxBuyMessage: "最大买入额 {orderAmount}",
  maxSellMessage: "最大卖出额 {orderAmount}",
  minBuyMessage: "最小买入额不能小于 {orderAmount}",
  minSellMessage: "最小卖出额不能小于 ≤ {orderAmount}",
  oneMaxSellMessage: "最大卖出额不能大于 ≥ {orderAmount}",

  /* 接口返回提示 */
  email_code_verify_count_locked: "验证码尝试次数过多, 请24小时后重试",
  email_code_verify_count_often: "请重新获取验证码并验证",
  "customer.update.password.locked": "多次输入错误，请在 24 小时后重试",
  email_code_not_send: "请先发送验证码",
  reset_password_count_error_often: "多次输入错误，请在 24 小时后重试",
  "customer.exist.none": "账号还未注册",
  "customer.email.code.incorrect": "验证码错误",
  "google.code.error": "验证码错误",
  "global.exception": "系统错误",
  "rpc.exception": "系统错误",
  "profile.googlecode.bind.email.code.error": "验证码不正确",
  "financing.account.amount.incorrect": "金额不正确，请稍后再试",
  "customer.login.password.error.email": "帐号或密码错误",
  "user.name.not.found": "帐号或密码错误",
  "user.password.error": "帐号或密码错误",
  "customer.not.found": "账户不存在",
  "user.is.not.allowed.to.operate": "未授权",
  "user.status.is.not.normal": "账号已被冻结。",
  "customer.google.on.error": "谷歌验证打开失败",
  "customer.verify.email.inactive": "需要激活邮箱",
  "customer.verify.code.sent.often": "邮箱验证码发送太频繁，请稍后再试",
  "user.access.interface.frequency": "操作太频繁，请稍后再试",
  "customer.login.required": "用户未登录",
  "customer.email.required": "邮箱不能为空",
  "customer.email.format.incorrect": "电子邮件格式不正确",
  "customer.verify.code.sent": "验证码已发送",
  "customer.password.required": "密码不能为空",
  "customer.password.different": "两次输入的密码必须相同",
  "customer.password.old.required": "原密码不能为空",
  "customer.password.new.required": "新密码不能为空",
  "customer.password.same": "新的登录密码不能和原来的一样",
  "customer.phone.code.required": "手机验证码不能为空",
  "customer.phone.code.incorrect": "手机验证码错误",
  "customer.phone.send.fail": "发送验证码失败",
  "customer.phone.format.incorrect": "电话号码格式错误",
  "customer.phone.required": "电话号码不能为空",
  "customer.phone.code.limit": "短信发送超出当天最大限制",
  "customer.phone.code.often": "短信验证码发送太频繁，请稍后再试",
  "customer.phone.duplicate": "电话号码已绑定",
  "customer.phone.new.duplicate": "新电话号码不能与旧电话号码重复",
  "customer.phone.error": "手机认证失败",
  "customer.verify.code.expire": "验证码错误",
  "customer.verify.phone.unbind": "需要绑定手机",
  "customer.forbidden": "该账户因安全问题已被冻结。如需了解更多信息, 请联系support@aspendigital.co",
  "customer.reg.email.duplicate": "邮箱已注册",
  "customer.reg.fail": "注册失败",
  "customer.google.off.error": "谷歌验证关闭失败",
  "customer.google.auth.fail": "谷歌验证码错误",
  "customer.google.bind.fail": "谷歌验证绑定失败",
  "customer.google.unbind.fail": "谷歌验证解除绑定失败",
  "customer.kyc.id.used": "身份证已被使用，请尝试其他",
  "customer.kyc.duplicate": "客户已经认证",
  "customer.kyc.expire": "验证已过期，请稍后再试",
  "customer.kyc.info.insufficient": "KYC 信息不足",
  "customer.kyc.reject.image.not.readable":
    "系统无法验证您的护照照片，请阅读说明后重新上传。如有任何问题，请前往【个人资料】-【其他设置】-【联系我们】联系我们。",
  "customer.kyc.reject.unsupported.id.country": "KYC信息和提交的国家信息不匹配",
  "customer.kyc.reject.handheld.fraud": "手持证件照片信息不符合平台要求",
  "customer.kyc.not.submit": "KYC 尚未提交",
  "customer.kyc.id.expire": "护照过期/无效",
  "customer.kyc.live.result.check.fail": "活体检测失败",
  "customer.kyc.verify.fail": "KYC 认证失败",
  "customer.kyc.reject.unsupported.id.type": "不支持护照类型",
  "customer.phone.on.fail": "打开手机验证失败",
  "customer.phone.off.fail": "验证手机关机失败",
  "customer.password.reset.fail": "密码重置失败",
  "customer.login.new": "已登出",
  "customer.virtual.account.nonexist": "虚拟账户不存在",
  "customer.verify.code.incorrect": "邮箱验证码不正确",
  "customer.verify.type.invalid": "验证类型无效",
  "customer.google.verify.code.incorrect": "谷歌验证码不能为空",
  "customer.password.format.incorrect": "密码格式错误",
  "customer.email.duplicate": "邮箱地址不能重复",
  "customer.login.incorrect": "用户信息已过期",
  "customer.login.password.error.mobile": "用户名或密码不正确，还剩 {time} 次",
  "customer.login.password.error": "电子邮件或密码错误，还剩 {0} 个输入机会",
  "customer.login.3rd.token.error": "第三方登录失败(1105)",
  "customer.login.3rd.email.exist": "邮箱已经在 Kikitrade 注册了",
  "incorrect.invitation.code": "邀请码不正确",
  "otc.customer.payment.way.save.fail": "保存银行卡失败",
  "otc.customer.payment.way.save.exception": "保存银行卡错误",
  "otc.customer.payment.way.none.exist": "银行卡信息不存在",
  "otc.customer.payment.way.disable": "银行卡信息已被删除",
  "otc.customer.payment.way.op.exception": "获取银行卡信息失败",
  "system.operation.frequent": "操作太频繁",
  "system.security.param.required": "安全检查参数为空",
  "system.security.check.fail": "安全检查失败",
  "system.parameter.invalid": "请求参数无效",
  "system.parameter.required": "参数 [{0}] 为空",
  "system.parameter.format.incorrect": "参数格式 [{0}] 不正确",
  "system.parameter.type.mismatch": "参数类型不匹配",
  "system.parameter.digital.required": "参数 [{0}] 该是数字",
  "system.data.not.found": "未找到系统数据",
  "system.cache.refresh.fail": "系统缓存刷新失败",
  "system.error": "连接失败，稍后重试",
  "customer.locked": "密码输入错误5次, 两小时内无法再次登录",
  "customer.reset.password.error": "旧密码错误",
  "user.information.is.incorrect": "旧密码错误",
  "system.success": "成功",
  "system.successful": "成功",
  "margin.interest.duplicate": "重复杠杆利息处理中",
  "margin.interest.state.illegal": "关闭杠杆失败",
  "margin.config.update.fail": "更新失败",
  "margin.config.disable.forbidden": "杠杆关闭失败",
  "margin.balance.insufficient": "余额不足",
  "margin.lock.fail": "订单失败",
  "margin.unlock.fail": "风险检查解锁失败",
  "margin.orders.exceed": "杠杆下单数量超限",
  "margin.transfer.fail": "余额不足",
  "margin.transfer.lock.fail": "开杠杆失败，请稍后重试",
  "margin.open.country.forbidden": "您当前所在位置不允许保证金交易",
  "margin.open.saving.forbidden": "请先关闭理财自动申购，再开启杠杆",
  "margin.open.invest.forbidden": "请先关闭定投，再开启杠杆",
  "margin.open.order.forbidden": "请先取消挂单，再开启杠杆",
  "margin.open.query.order.fail": "拉杆打开失败，请稍后再试",
  "margin.close.loan.forbidden": "平仓杠杆失败-用户借了钱",
  "margin.close.order.forbidden": "请先取消挂单，再开启杠杆",
  "margin.close.interest.cannot.afford": "杠杆平仓时，应付利息不足",
  "margin.interest.fail": "关闭杠杆失败，请稍后重试",
  "margin.already.closed": "杠杆关闭",
  "margin.close.fail": "杠杆关闭异常",
  "coin.withdraw.amount.onetime.min.less": "低于最低金额",
  "coin.withdraw.amount.onetime.max.exceed": "超过最大金额",
  "fixed.deposit.product.quantity.not.enough": "申购失败，该产品暂时不可用。",
  "financing.contract.interest.trading.operated": "操作完成，请关闭弹窗查及查看结果。",

  /* 代理商 */
  "clients.title": "代理客户管理",
  "clients.agency.information": "代理商资料",
  "clients.agency.type": "代理模式",
  "clients.aum": "资产管里 (AUM)",
  "clients.invitation.information": "邀请链接",
  "clients.referral.code": "邀请码",
  "clients.customer.list": "客户总表",
  "clients.customer.id": "客户ID",
  "clients.customer.tips": "通过客户ID搜索",
  "clients.manager.search.tips": "User ID can be checked in management dashboard",
  "clients.commission": "佣金",
  "clients.total": "总数",
  "clients.unsettled": "未付",
  "clients.settled": "已付",
  "clients.view.billing.history": "查看账单记录",
  "clients.billing.history.title": "客户管理 - 账单记录",
  "clients.billing.history.column.date": "日期",
  "clients.billing.history.column.amount": "数量",
  "clients.application.title": "授权他人管理账户申请",
  "clients.application.information": "申请资料",
  "clients.application.name": "客户名称",
  "clients.application.email": "邮箱",
  "clients.application.authorization": "授权",
  "clients.application.description": "描述",
  "clients.application.authorization.detail": [
    {
      key: "交易",
      detail: "代表客服下单和撤单。"
    },
    {
      key: "理财",
      detail: "申购或赎回理财，开启和取消定投计划。"
    },
    {
      key: "策略",
      detail: "申购或赎回策略"
    }
  ],
  "clients.application.period": "时长",
  "clients.application.agreement": "Aspen Digital 帐户授权他人管理协议",
  "clients.application.agreement.read": "我已阅读并同意有关协议",
  "clients.application.agreement.read.tips": "请阅读整个协议并向下滚动至底部",
  "global.access.denied": "拒绝访问",

  /* input缺省相关 */
  "placeholder.enter": "请输入",
  "placeholder.select": "请选择",
  "placeholder.billing.address": "账单地址",
  "placeholder.hk.postal.code": "香港邮政编码: 999077",
  "placeholder.kyc.company.name": "公司名称",
  "placeholder.kyc.enter.mc": "输入MC",
  "placeholder.kyc.enter.tin": "输入TIN",
  "placeholder.kyc.enter.address": "请输入公司注册地址",
  "placeholder.kyc.enter.name": "名称",
  "placeholder.kyc.enter.title": "公司代表职衔",
  "placeholder.kyc.enter.email": "邮箱",
  "placeholder.kyc.enter.same.passport.name": "需和护照/身份证名称一致",
  "placeholder.kyc.enter.birthday": "生日",
  "placeholder.kyc.enter.age": "年龄",
  "placeholder.kyc.enter.passport.id": "护照/身份证号码",
  "placeholder.kyc.enter.phone": "电话号码",
  "placeholder.kyc.enter.residential.address": "居住地址",

  /* 表格列名相关 */
  "column.currency": "币种", // 原为Currency
  "column.denomination": "币种", // 原为Cryptocurrency
  "column.totalAmount": "总数量",
  "column.product": "产品",
  "column.type": "类型",
  "column.latestValue": "最新价值",
  "column.cumulativeInterest": "累计利息",
  "column.apy": "APY",
  "column.apr": "APR",
  "column.action": "操作",
  "column.details": "详情",
  "column.pending": "冻结资产",
  "column.available": "可用资产",
  "column.date": "日期",
  "column.name": "名称",
  "column.updateDate": "更新时间",
  "column.pair": "交易对",
  "column.average.price": "平均价格",
  "column.side": "买卖",
  "column.price": "价格",
  "column.order.price": "订单价格",
  "column.amount": "数量",
  "column.filled.total": "已成交金额",
  "column.order.total": "订单金额",
  "column.filled.amount": "已成交数量",
  "column.filled": "已成交",
  "column.order.amount": "订单数量",
  "column.fee": "手续费",
  "column.number": "订单号码",
  "column.total": "总金额",
  "column.authorization": "授权状态",
  "column.estimated.interest": "预估利息",
  "column.period": "周期",
  "column.cutOff": "锁仓时间",
  "column.operation": "操作",
  "column.operator": "操作方",
  "column.strategy": "策略名称",
  "column.latest.value": "最新持仓",
  "column.ytd": "YTD",
  // TODO: 已无使用
  "column.payee.name": "Payee Name",
  "column.project.name": "产品名称",
  "column.bank.name": "银行卡名称",
  "column.bank.card": "银行卡号",
  "column.bank.remark": "Ref. (Optional)",
  "column.from.address": "充币来源地址",
  "column.to.address": "提币到账地址",
  "column.from.account": "充值银行账号",
  "column.to.account": "提现到账银行账号",
  "column.time": "时间",
  "column.month": "月份",
  "column.status": "状态",
  "column.history": "历史记录",
  "column.category": "类型",
  "column.yield.auto.purchase": "利息定投买币",
  "column.yield.auto.compound": "自动续投",
  "column.yield.auto.purchase.mini.investment.amount": "最小定投数量",
  "column.yield.auto.purchase.investment.amount": "定投数量",
  "column.yield.auto.purchase.estimated_return_rate": "预估回报率",
  "column.yield.invest.plan.details": "计划详情",
  "column.yield.invest.investment.details": "定投历史记录",
  "column.email": "邮箱",
  "column.email.address": "邮箱地址",
  "column.email.type": "类型",
  "column.user.status": "用户状态",
  "column.total.assets": "总资产",
  "column.percentage": "百分比",
  "column.registration": "注册时间",

  /* 阿里云滑动验证 */
  "alicloud.sliding.verification.uplang.en": {
    // 加载状态提示。
    LOADING: "加载中... ",
    // 等待滑动状态提示。
    SLIDE: "请向右滑动验证",
    // 验证通过状态提示。
    SUCCESS: "验证通过",
    // 验证码加载出现异常时触发该回调参数。
    ERROR: "请检查您的网络，稍后再试",
    // 验证失败触发拦截状态提示。
    FAIL: "验证失败，请重试"
  },
  "Network error": "请检查您的网络，稍后再试！",
  "afs.nvc.fail": "验证失败。如果你有任何问题，可以找客户经理或发邮件到 support@aspendigital.co。",
  "afs.nvc.fail.refresh": "验证失败，请刷新页面并重试",
  "alicloud.loading.timeout.tip": "加载超时，请刷新页面并重试"
};
