import React, { Suspense } from "react";
import { FormattedMessage } from "react-intl";
import { Link, withRouter } from "react-router-dom";
import { Menu, Layout, Dropdown } from "antd";
import styles from "./Header.module.less";
import { checkAuth } from "@/utils/index";
import { USER_ROLE_POWER } from "@/config/constants";
import { connect } from "react-redux";
import { switchLanguage } from "@/slices/IntlSlice";

import img_user from "@/assets/images/user.svg";
import img_logout from "@/assets/images/logout.svg";
import img_language from "@/assets/images/language.png";

const ModalChangePassword = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ModalChangePassword" */ "@/views/profile/components/ModalChangePassword"
    )
);

const logo = require("@/assets/images/aspen_logo_white@2x.png").default;
const img_avatar = require("@/assets/images/img_avatar@2x.png").default;

const menus = [
  {
    id: "menu.home",
    to: "/home"
  },
  {
    id: "menu.trade",
    to: "/trade"
  },
  {
    id: "menu.yield",
    to: "/yield"
  },
  {
    id: "menu.strategies",
    to: "/strategies"
  },
  {
    id: "menu.intelligence",
    to: "/intelligence"
  },
  {
    id: "menu.history",
    to: "/history"
  },
  {
    id: "menu.wallet",
    to: "/wallet"
  }
];

interface IState {
  current: string;
  changePasswordModal: boolean;
}

interface IProps {
  locale: string;
  [index: string]: any;
}

interface MenuInfo {
  item: React.ReactInstance;
  key: string;
  keyPath: string[];
}
class Header extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      current: window.location.pathname == "/" ? "/home" : window.location.pathname,
      changePasswordModal: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClickProfile = this.handleClickProfile.bind(this);
  }

  handleClick: (e: MenuInfo) => void = (e: MenuInfo) => {
    this.setState({
      current: e.key
    });
  };

  handleClickProfile(): void {
    window.__history__.push(`/profile`);
  }

  handleLogout() {
    window.location.href = "/login";
  }

  componentDidMount: () => void = () => {
    // 隐藏/显示选项卡
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState == "visible") {
        // this.props.registerWS();
      } else {
        // 隐藏选项卡5分钟后取消websocket, 先注释掉
        // setTimeout(() => {
        //   this.props.unregisterWS();
        // }, 5 * 60 * 1000);
      }
    });

    // 当路由是登陆相关、策略相关、新闻相关、个人中心相关时，不注册websocket ，除此之外注册websocket
    window.__history__.listen((route) => {
      if (location.href.includes("home") || location.href.includes("trade")) {
        // this.props.registerWS();
      }
      // 1分钟后取消websocket， 是否注销websocket, 需要在斟酌下, todo,
      // setTimeout(() => {
      //   if (!(location.href.includes("home") || location.href.includes("trade"))) {
      //     this.props.unregisterWS();
      //   }
      // }, 60 * 1000);
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.current != window.location.pathname) {
      window.scrollTo(0, 0);
      this.setState({
        current: window.location.pathname
      });
    }
  }

  getPopupContainer = (trigger) => trigger.parentNode || document.body;

  resetPassword = () => {
    this.setState({ changePasswordModal: true });
  };

  handleSwitchLanguage = () => {
    const lang = this.props?.locale === "en" ? "zh" : "en";
    this.props?.switchLanguage(lang);
  };

  render() {
    const { current } = this.state;
    const { Header } = Layout;

    const dropdownMenu = (
      <Menu>
        {localStorage.getItem("user_auth") != "partner" &&
          (localStorage.getItem("user_auth") == "subcustomer" ||
          localStorage.getItem("user_auth") == "manager" ? (
            <Menu.Item onClick={this.resetPassword} key="_restPassword">
              <img src={img_user} alt="" style={{ width: 16, height: 16, marginRight: 16 }} />
              <FormattedMessage id="resetPsw" />
            </Menu.Item>
          ) : (
            <Menu.Item onClick={this.handleClickProfile} key="_user">
              <img src={img_user} alt="" style={{ width: 16, height: 16, marginRight: 16 }} />
              <FormattedMessage id="profile" />
            </Menu.Item>
          ))}
        <Menu.Item onClick={this.handleSwitchLanguage} key="_language">
          <img src={img_language} alt="" style={{ width: 16, height: 16, marginRight: 16 }} />
          <span>{this.props?.locale === "en" ? "简体中文" : "English"}</span>
        </Menu.Item>
        <Menu.Item onClick={this.handleLogout} key="_logout">
          <img src={img_logout} alt="" style={{ width: 16, height: 16, marginRight: 16 }} />
          <FormattedMessage id="logout" />
        </Menu.Item>
      </Menu>
    );

    const defaultPath = USER_ROLE_POWER[localStorage?.getItem("user_auth")]?.loginToPath || "/home";
    return (
      <Header className={styles.row}>
        <div className={styles.headerLeft}>
          {/* 如果有role中有不能访问首页的情况，待确认点击logo权限,代理商简版不涉及 */}
          <Link to={defaultPath}>
            <img className={styles.logo} src={logo} alt="" style={{ width: "88px" }} />
          </Link>
        </div>
        <div className={styles.headerRight}>
          <Menu onClick={this.handleClick} selectedKeys={[current]} mode="horizontal">
            {menus.map(
              (item) =>
                checkAuth(item?.to) && (
                  <Menu.Item key={item.to}>
                    <Link to={item.to}>
                      {/* <FormattedMessage id={item.id} /> */}
                      {window.__intl__.messages[item.id]}
                    </Link>
                  </Menu.Item>
                )
            )}
            {localStorage.getItem("user_auth") != "partnercustomer" &&
              localStorage.getItem("user_auth") != "managercustomer" && (
                <Menu.Item key="profile">
                  <div
                    className={
                      localStorage.getItem("user_auth") == "subcustomer"
                        ? styles.subDrop
                        : styles.defaultDrop
                    }>
                    <Dropdown
                      overlay={dropdownMenu}
                      overlayClassName="logout-dropdown"
                      trigger={["click"]}
                      getPopupContainer={this.getPopupContainer}>
                      <img src={img_avatar} alt="" style={{ width: "24px", height: "24px" }} />
                    </Dropdown>
                  </div>
                </Menu.Item>
              )}
          </Menu>
        </div>
        <Suspense fallback={<></>}>
          <ModalChangePassword
            visible={this.state.changePasswordModal}
            handleCancel={() => {
              this.setState({ changePasswordModal: false });
            }}
          />
        </Suspense>
      </Header>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.language.locale
});

const mapDispatchToProps = () => ({
  switchLanguage: (payload) => switchLanguage(payload)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Header));
