import ic_success from "@/assets/images/ic_successfully@2x.png";
import ic_fail from "@/assets/images/ic_fail@2x.png";

// 密码最大长度40
export const PASSWORD_MAX_LENGTH = 40;
// 验证码最长长度6
export const VerifyCode_MAX_LENGTH = 6;

export const MD5_FIXED_STRING = "Awesome Aspen Digital";

//dev上传OSSHost信息
export const Dev_OSSData = {
  bucket: "aspen-dev",
  dir: "static/image/",
  expire: "2030-01-01T12:00:00.000Z",
  host: "//www.test-aspendigital.co",
  accessKeyId: "LTAI5tJ7hhKb8JQrLe3xYbya",
  accessKeySecret: "J02xXyUG4aqMQBj6DUWIjU0l7PZ3vr"
};

export const DOMAIN_DEV = "portal.dev.test-aspendigital.co";
export const DOMAIN_BETA = "portal.beta.test-aspendigital.co";
export const DOMAIN_PROD = "portal.aspendigital.co";
export const DOMAIN_RED = "portal-red.beta.test-aspendigital.co";
export const DOMAIN_GREEN = "green-portal.aspendigital.co";
//代理商对应域名
export const DOMAIN_DEV_PARTNER = "partner.dev.test-aspendigital.co";
export const DOMAIN_BETA_PARTNER = "partner.beta.test-aspendigital.co";
export const DOMAIN_PROD_PARTNER = "partner.aspendigital.co";
export const DOMAIN_RED_PARTNER = "partner-red.beta.test-aspendigital.co";
export const DOMAIN_GREEN_PARTNER = "green-partner.aspendigital.co";
//管理员对应域名
export const DOMAIN_DEV_MANAGER = "service.dev.test-aspendigital.co";
export const DOMAIN_BETA_MANAGER = "service.beta.test-aspendigital.co";
export const DOMAIN_PROD_MANAGER = "service.aspendigital.co";
export const DOMAIN_RED_MANAGER = "service-red.beta.test-aspendigital.co";
export const DOMAIN_GREEN_MANAGER = "green-service.aspendigital.co";

export const RECAPTCHA_V3_KEY = "6Lfrv9odAAAAAAPyzPXB4sEQ1ILa_UMBOj7VEv8z";
export const RECAPTCHA_V2_KEY_CHECKBOX = "6LdMUN0dAAAAAC192e0mY3tDjQxErMRQAPpBXBV4";
export const RECAPTCHA_V2_KEY_INVISIBLE = "6Lek_RoeAAAAAHzk3EGE4Nq-_JdZlBfcuYdt7zQt";
export const apikeyBugsnagProd = "39c2a22c6792caf8a4ca4317e3e9d273";
export const apikeyBugsnagBeta = "67a5275aa12dc423bc113ad69da9d93c";
export const apikeyBugsnagDev = "ce04cf79558d4491ae9fe634baf50882";

export const AppkeyAlicloud = "FFFF0N0N000000006429"; // 阿里云验证的appkey

// strategies images 地址， dev
export const STRATEGIES_DEV_OSS_URL =
  "https://aspen-dev.oss-ap-southeast-1.aliyuncs.com/strategies";
// strategies images 地址， beta
export const STRATEGIES_BETA_OSS_URL =
  "https://aspen-beta.oss-ap-southeast-1.aliyuncs.com/strategies";
// strategies images 地址， prod
export const STRATEGIES_PROD_OSS_URL =
  "https://aspen-prod.oss-ap-southeast-1.aliyuncs.com/strategies";
// strategies images 地址， red
export const STRATEGIES_RED_OSS_URL =
  "https://aspen-beta-red.oss-ap-southeast-1.aliyuncs.com/strategies";

let STRATEGIES_OSS_Url = STRATEGIES_DEV_OSS_URL;
if (
  location.origin.includes(DOMAIN_DEV) ||
  location.origin.includes(DOMAIN_DEV_PARTNER) ||
  location.origin.includes(DOMAIN_DEV_MANAGER)
) {
  STRATEGIES_OSS_Url = STRATEGIES_DEV_OSS_URL;
}
if (
  location.origin.includes(DOMAIN_BETA) ||
  location.origin.includes(DOMAIN_BETA_PARTNER) ||
  location.origin.includes(DOMAIN_BETA_MANAGER)
) {
  STRATEGIES_OSS_Url = STRATEGIES_BETA_OSS_URL;
}
if (
  location.origin.includes(DOMAIN_RED) ||
  location.origin.includes(DOMAIN_RED_PARTNER) ||
  location.origin.includes(DOMAIN_RED_MANAGER)
) {
  STRATEGIES_OSS_Url = STRATEGIES_RED_OSS_URL;
}
if (
  location.origin.includes(DOMAIN_PROD) ||
  location.origin.includes(DOMAIN_PROD_PARTNER) ||
  location.origin.includes(DOMAIN_PROD_MANAGER)
) {
  STRATEGIES_OSS_Url = STRATEGIES_PROD_OSS_URL;
}
export { STRATEGIES_OSS_Url };

// Plots 相关的图表
// https://charts.ant.design/zh/docs/manual/getting-started
// https://unpkg.com/@ant-design/charts@1.0.5/dist/charts.min.js  引入这个Area上的tootip展示异常, 且Pie角度失效

// 使用plot， Area上的tooltip无法展示
//export const ANT_DESDIGN_CHART_SRC = "https://unpkg.com/@ant-design/plots@latest/dist/plots.min.js";
export const ANT_DESDIGN_CHART_SRC = "https://unpkg.com/@ant-design/charts";

export const ALI_SDK_SRC = "https://gosspublic.alicdn.com/aliyun-oss-sdk-6.16.0.min.js";

export const FIXED_DECIMAL = 8;

export const BENCHMARK_CURRENCY = "USDC";
export const BENCHMARK_CURRENCY_SYMBOL = "$";
export const BENCHMARK_CURRENCY_ICON = require("@/assets/images/USDC.png").default;
export const HOME_SYMBOLS = ["BTC_USD", "ETH_USD", "SOL_USD", "BNB_USD", "AVAX_USD"];

//账户权限 ，目前登陆后，后台仅会返回一种角色
export const USER_ROLE = {
  ROLE_CUSTOMER: "customer",
  ROLE_PARTNER: "partner",
  ROLE_MANAGER: "manager",
  ROLE_PARTNER_CUSTOMER: "partnercustomer", //代理商进入客户页面操作
  ROLE_MANAGER_CUSTOMER: "managercustomer", //代理商进入客户页面操作
  ROLE_SUB: "subcustomer"
};
/**
 * 角色权限功能
 * depositAndWithdraw：是否开启 充提币、出入金 入口
 * loginToPath：登陆后默认进入的路由
 * actionForOthers：当前角色是否是 正在为客户进行操作（目前仅判断是否全局提示蓝条）
 */
export const USER_ROLE_POWER = {
  customer: {
    depositAndWithdraw: true,
    loginToPath: "/home",
    actionForOthers: false
  },
  partner: {
    depositAndWithdraw: false,
    loginToPath: "/clients",
    actionForOthers: false
  },
  //管理员权限
  manager: {
    depositAndWithdraw: false,
    loginToPath: "/clients",
    actionForOthers: false
  },
  //partnercustomer为代理商代理客户操作时的权限
  partnercustomer: {
    depositAndWithdraw: false,
    loginToPath: "/trade",
    actionForOthers: true
  },
  //管理员代理客户操作时的权限
  managercustomer: {
    depositAndWithdraw: false,
    loginToPath: "/trade",
    actionForOthers: true
  },
  subcustomer: {
    depositAndWithdraw: false,
    loginToPath: "/trade",
    actionForOthers: true
  }
};

// 12月份简称
export const months = [
  "Jan.",
  "Feb.",
  " Mar.",
  "Apr.",
  "May",
  "Jun.",
  "Jul.",
  "Aug.",
  "Sept.",
  "Oct.",
  "Nov.",
  "Dec."
];
export const Yield_Action = {
  Append: "Append",
  Subscribe: "Subscription",
  Redeem: "Redemption"
};

export const BUSINESS_TYPE = {
  1: "withdraw",
  2: "deposit",
  3: "order",
  4: "inner withdraw",
  5: "reward",
  6: "settle",
  7: "otc order",
  8: "inner transfer",
  9: "agent_manual_settle",
  10: "yield",
  11: "treasure confirm",
  12: "interests",
  13: "sys_invest",
  14: "treasure trial cash revenue transfer",
  15: "yield",
  23: "member pay",
  24: "spot trade",
  25: "fiat deposit",
  26: "fiat withdraw",
  27: "redeem card",
  28: "system plus",
  29: "dummy transaction",
  30: "redeem card buy",
  31: "investment subscribe",
  32: "margin transaction",
  33: "margin interest",
  34: "wallet fee",
  35: "dust transaction",
  36: "hedge transaction",
  37: "auto invest",
  38: "fixed deposit subscription",
  39: "fixed deposit cancellation",
  40: "fixed deposit revenue to proxy",
  41: "fixed deposit matured",
  80: "strategy",
  81: "AUM Fee",
  82: "commission",
  83: "strategy subscription",
  84: "strategy redemption",
  85: "strategy income",
  86: "strategy subscription canceled",
  90: "p2p merchant apply",
  91: "p2p merchant post ads",
  92: "p2p order",
  93: "p2p order fee",
  94: "p2p transfer in",
  95: "p2p transfer out"
};

//wallet充提币历史状态
export const Wallet_Status = {
  WITHDRAW_APPLY: "Review",
  WITHDRAW_APPROVED: "Approved",
  WITHDRAW_REJECTED: "Rejected",
  WITHDRAW_COMPLETED: "Completed",
  WITHDRAW_FAILED: "Failed",
  WITHDRAW_NOT_IN_CHAIN: "Not in chain",
  DEPOSIT_INITIAL: "Initial",
  DEPOSIT_APPLY: "Review",
  DEPOSIT_FINISHED: "Completed",
  DEPOSIT_FAILED: "Failed"
};

//circle充提币历史
export const Circle_Status = {
  FIAT_INIT: "Reviewing",
  FIAT_APPLY: "Reviewing",
  FIAT_APPROVED: "Approved",
  FIAT_REJECTED: "Rejected",
  FIAT_FAILED: "Failed",
  FIAT_COMPLETED: "Transferred",
  FIAT_CANCELED: "Canceled"
};

//circle 绑定银行账户拒绝原因
export const Circle_Reject = {
  bankError001: "Incorrect bank country",
  bankError002: "Incorrect bank city",
  bankError003: "Account number does not match with bank statement",
  bankError004: "No Bank code is needed",
  bankError005: "No Branch code is needed",
  bankError006: "No Bank code and Branch code is needed",
  bankError007:
    "Please follow the account number shown on the top right corner of the bank statement",
  accountError001: "Address does not match with bank statement",
  accountError002: "Postal code does not match with the bank statement",
  certificateError001: "Bank statement photo unclear or incomplete",
  certificateError002: "Bank statement type does not meet the verfication requirement",
  certificateError003: "Bank statement is not within 3 months",
  otherError001: "Others, please contact support@aspendigital.co"
};

//定投频率
export const Automatice_Investment_Cycle = {
  DAY: {
    code: 0,
    text: "Daily"
  },
  WEEK: {
    code: 1,
    text: "Weekly"
  },
  HALF_MONTH: {
    code: 2,
    text: "Half Month"
  },
  MONTH: {
    code: 3,
    text: "Monthly"
  }
};
export const Automatice_Investment_Cycle_Code = {
  0: "Daily",
  1: "Weekly",
  2: "Half Month",
  3: "Monthly"
};
//我的定投状态
export const My_Automatice_Investment = {
  0: "Active",
  1: "Active",
  2: "Failure",
  3: "Cancelled"
};
//定投历史状态
export const Automatice_Investment_History = {
  1: "Processing",
  2: "Processing",
  3: "Successful",
  11: "Failure"
};
//操作人类别  0本人操作，1子账号，2代理商，3管理员账号
export const Operator_Type = {
  0: "Self",
  1: "Multi-login",
  2: "Account Manager",
  3: "Aspen Digital",
  4: "Aspen Digital"
};
// 取消交易订单操作人类别
export const Cancel_Operator_Type = {
  0: "The Main Account",
  1: "Multi-login Account",
  2: "Account Manager",
  3: "Aspen Digital"
};

//client授权状态
export const Client_Authorization_Status = {
  0: "Not authorized", //未申请
  1: "Reviewing", //待用户同意
  2: "Reviewing", //待平台审核
  3: "Reviewing", //平台初审通过
  4: "Success", //平台复审通过
  5: "Client Rejected", //用户拒绝
  6: "Platform Rejected", //平台初审拒绝
  7: "Platform Rejected", //平台复审拒绝
  8: "Success", //帐户冻结 但是未过期
  9: "Terminated", //用户终止授权
  10: "Expired" //帐户过期
};
// 错误上报的code集合
export const errorcode = {
  "-2": "global exception",
  "1018": "customer.verify.code.expire",
  "1020": "customer.exist.none",
  "1021": "customer.forbidden",
  "1022": "customer.locked",
  "1023": "customer.reg.email.duplicate",
  "1024": "customer.reg.fail",
  "1030": "customer.kyc.id.used",
  "1031": "customer.kyc.duplicate",
  "1038": "customer.kyc.source.invalid",
  "1039": "customer.kyc.more.than.usage",
  "1042": "customer.password.reset.fail",
  "1045": "customer.verify.code.incorrect",
  "1047": "customer.google.verify.code.incorrect",
  "1048": "customer.password.format.incorrect",
  "1056": "customer.email.duplicate",
  "1057": "customer.email.code.incorrect",
  "1062": "customer.email.unbind.fail",
  "1064": "customer.email.unbind.not.allowed", // 不允许客户电子邮件解除绑定
  "4000": "incorrect.invitation.code", // 邀请码不正确
  "9000": "system.operation.frequent", // 操作太频繁了了
  "9002": "system.security.check.fail", // 安全检查失败
  "9007": "system.data.not.found", // 找不到系统数据
  "9008": "System cache refresh fail", // 系统缓存刷新失败
  "9999": "system.error", // Connection fail, retry later
  // trade
  "7001": "spot.order.duplicate",
  "7002": "spot.order.create.fail",
  "7004": "spot.order.error",
  "7007": "spot.order.side.invalid",
  "7008": "spot.order.type.invalid",
  "7013": "spot.order.invalid.parameter",
  "7015": "spot.entrust.duplicate",
  "7016": "spot.entrust.fail",
  "7019": "spot.entrust.cancel.fail",
  "7020": "spot.order.price.check.fail",
  "7021": "spot.order.not.exist",
  "7022": "spot.order.invalid.status",
  "7023": "spot.order.order.book.cancel.fail",
  "7024": "spot.order.update.fail",
  "7025": "spot.order.trade.duplicate",
  "7026": "spot.order.trade.state.illegal",
  "7027": "spot.customer.status.not.active",
  "7028": "spot.customer.stop.trade",
  "7029": "spot.order.book.invoke.fail",
  "5500": "rpc.exception"
};

// 错误上报的msg集合 (待和后端沟通，调整返回message) https://app.bugsnag.com/aspen-web-prod/web-prod-aspendigital/errors/62871bc1c8b31b0008e9d79a?filters[event.since]=30d&filters[error.status]=new
export const errmsg = ["has not registered"];

// 错误上报可忽略的msg集合
export const ignoreBugsnagError = [
  "ResizeObserver loop limit exceeded", // antd select组件触发，官方暂时也是忽略该错误
  "ResizeObserver loop completed with undelivered notifications",
  "getBoundingClientRect", // TypeError
  "unhandledrejection handler" // 未处理的promise错误， 当promise被reject并且错误信息没有被处理的时候，会抛出一个unhandledrejection
];

// 定期理财列表申购状态
export const Fixed_Yield_Status = {
  PUBLISHED: "Coming Soon",
  LOCKED: "Cut Off"
};

// 定期理财历史记录的状态Status： START(1)-已创建, PROCESSING(2)-处理中, CONFIRMED(3)-已确认, REVOKED(4)-已撤销, FAILED(11)-失败
export const Fixed_Yield_Log_Status = {
  START: "Pending",
  PROCESSING: "Pending",
  CONFIRMED: "Success",
  REVOKED: "Canceled",
  FAILED: "Fail"
};

// 定期理财历史记录的操作类型Type: SUBSCRIBE(1)-申购, REVOKE(2)-撤销, BEARING(3)-计息, REDEEM(4)-赎回
export const Fixed_Yield_Operate_Type = {
  SUBSCRIBE: "Subscription",
  REDEEM: "Redemption"
};

// 定期理财持仓总记录的状态Status(合约状态): SIGNED(0)-已签约, LOCKED(1)-已锁定, REDEEM(2)-已赎回
export const Fixed_Yield_Contracts_Status = {
  SIGNED: "Awaiting To Lock Up",
  LOCKED: "Lock-Up"
};

export const resultIconSrc = {
  success: ic_success,
  fail: ic_fail
};
// 策略类型
export const Strategy_Type = {
  0: "Quantitative",
  1: "Tracker",
  2: "Fixed Income",
  3: "Venture Capital Fund",
  4: "Venture Capital Primaries",
  5: "Venture Capital Secondaries",
  6: "Staking"
};

// 策略历史订单列表状态
export const Strategy_Order_Type = {
  1: "Failed", // 扣款失败
  2: "Pending", // 已扣款
  3: "Failed", // 冻结失败
  4: "Pending", // 已冻结
  7: "Pending", // 赎回审批通过
  0: "Confirming", // 待冻结,待上传赎回数据
  11: "Canceled", // 已取消
  12: "Success" // 已成功
};
