import React, { useState, useImperativeHandle, forwardRef } from "react";
import { message } from "antd";
import Bugsnag from "@bugsnag/js";
import { Intl } from "@/model";
import { AppkeyAlicloud } from "@/config/constants";
import { formatBugsnagMessage } from "@/utils";
import ModalBase from "../ModalBase";
import logo from "@/assets/images/aspen_logo_white.png";

import styles from "./index.module.less";

interface IProps {}

const AlicloudNCCaptcha = (props: IProps, ref) => {
  const intl = window.__intl__.messages;

  const [visible, setVisible] = useState<boolean>(false);

  const init = (scene, successCallback) => {
    const intl: Intl = window.__intl__.messages;

    window?.AWSC?.use("nc", (state, module) => {
      // state标识状态，首次实例化会加载外部js，timeout为获取超时，loaded为已加载
      if (state === "timeout") {
        console.log("alicloud nc loading timeout");
        Bugsnag.notify(
          formatBugsnagMessage(
            "Alicloud sliding verification JS loading timeout",
            "Action-sliding-verification"
          )
        );
      }

      let failCount = 0;
      // 初始化
      window.nc = module.init({
        appkey: AppkeyAlicloud,
        scene,
        renderTo: "nc",
        language: "en",
        upLang: {
          en: intl["alicloud.sliding.verification.uplang.en"]
        },
        hideErrorCode: true,
        //前端滑动验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
        success: (data) => {
          window.console && console.log(data);
          successCallback(data);
        },
        // 滑动验证失败时触发该回调参数。
        fail: (failCode) => {
          // 连续失败三次后，提示用户刷新页面
          failCount++;
          if (failCount >= 3) {
            message.destroy();
            message.error(intl["afs.nvc.fail.refresh"]);
          }

          Bugsnag.notify(
            formatBugsnagMessage(
              `Alicloud sliding verification failed, failCode: ${failCode}`,
              "Action-sliding-verification"
            )
          );
        },
        // 验证码加载出现异常时触发该回调参数。
        error: (errorCode) => {
          window.console && console.log(errorCode);
          Bugsnag.notify(
            formatBugsnagMessage(
              `Alicloud sliding verification loading exception, errorCode: ${errorCode}`,
              "Action-sliding-verification"
            )
          );
        }
      });
    });
  };

  const reset = () => {
    window?.nc?.reset && window.nc.reset();
  };

  useImperativeHandle(ref, () => ({
    init,
    reset,
    setVisible
  }));

  return (
    <ModalBase
      style={{ background: "#23262F" }}
      visible={visible}
      footer={false}
      onCancel={() => setVisible(false)}>
      <div className={styles.alicloudNc}>
        <img className={styles.logo} src={logo} alt="" />
        <p className={styles.title}>{intl["login.title"]}</p>
        <div id="nc" className="alicloud-nc" style={{ margin: "32px 0" }} />
      </div>
    </ModalBase>
  );
};

export default React.memo(forwardRef(AlicloudNCCaptcha));
