import React, { useState, useEffect } from "react";
import styles from "@/views/login/Login.module.less";
import { Form, Input, Button, message } from "antd";
import { agentLogin, logout, getConfigInfo, fecthCustomerSetting } from "@/services/request";
import { Intl } from "@/model/index";
import { useLocation } from "react-router-dom";
import LoginLayoutCMS from "./components/LoginLayoutCMS";
import { PASSWORD_MAX_LENGTH, MD5_FIXED_STRING, VerifyCode_MAX_LENGTH } from "@/config/constants";
import WithFiatAndSpotTrade from "@/components/withFiatAndSpotTrade/WithFiatAndSpotTrade";
import md5 from "md5";
import { NVCCaptcha, formatBugsnagMessage, isEmail } from "@/utils";
import Bugsnag from "@bugsnag/js";
import AlicloudNCCaptcha from "@/components/alicloudNCCaptcha";
import EmailCodeLayout from "@/components/withVerifyEmailCode/WithEmailCodeLayout";

interface IProps {
  countdown: number;
  loading: boolean;
  sendMailBtn: string;
  getEmailCode: (businessType: number, email?: string) => void;
  unregisterWS: () => void;
  handleBlur: (event: { target: { value: any }; currentTarget: any }) => void;
  handleFocus: (event: { target: { value: any }; currentTarget: any }) => void;
}

const AgentLogin: React.FC<IProps> = (props: IProps) => {
  const state = useLocation()?.state;
  const intl: Intl = window.__intl__.messages;
  const [accountName, setAccountName] = useState<string>(state?.email);
  const [password, setPassword] = useState<string>(state?.password);
  const [loading, setLoading] = useState<boolean>(false);

  const emailInput = React.useRef<any>(null);
  const [form] = Form.useForm();

  // 滑动验证参数
  const [ncVal, setNcVal] = useState<Record<string, any>>(null);
  // 2FA验证类型
  const [validChannel, setValidChannel] = useState<string>("");
  const [validChannelLoading, setValidChannelLoading] = useState<boolean>(false);
  const [verifyCode, setVerifyCode] = useState<string>("");

  const NCRef = React.useRef<any>();

  // 处理退出登录 信息清除等
  const init = () => {
    const error = localStorage.getItem("error");
    if (error) {
      message.destroy();
      message.error(intl[error]);
    }
    props.unregisterWS();
    window.ws = null;
    emailInput?.current?.focus();
    form.setFieldsValue({ email: accountName, password });

    logout().finally(() => {
      // 循环清除其它所有locastorage，除过config的showRecaptcha信息
      Object.keys(localStorage).forEach((key) => {
        if (key !== "showRecaptcha") {
          localStorage.removeItem(key);
        }
      });
      getConfigData();
    });
  };

  // 获取配置信息，afsOpen 是否开启阿里云验证开关
  const getConfigData = () => {
    // 设置默认值为true，若接口有问题，不会导致登录接口显示缺乏参数 header
    localStorage.setItem("showRecaptcha", "true");
    localStorage.setItem("is2fa", "true");

    getConfigInfo().then((res) => {
      if (res?.code == 0) {
        localStorage.setItem("showRecaptcha", res.data?.afsOpen);
        localStorage.setItem("is2fa", res.data?.is2fa);
      }
    });
  };

  useEffect(() => {
    init();
    NVCCaptcha.init("nvc_login_h5"); // 初始化阿里云无痕验证码
  }, []);

  useEffect(() => {
    emailInput?.current?.focus();
  }, [accountName]);

  // ncVal: 滑动验证成功后的信息 滑动验证成功后，直接登录
  useEffect(() => {
    if (ncVal) handleSubmit();
  }, [ncVal]);

  // 获取账户的登录验证渠道
  const getValidChannel = () => {
    if (localStorage.getItem("is2fa") === "false") return;

    setValidChannelLoading(true);
    fecthCustomerSetting({ accountName })
      .then((res) => {
        if (res?.code == "0") {
          setValidChannel(res?.data?.valid_channel || "");
          setVerifyCode("");
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setValidChannelLoading(false);
      });
  };

  const handleEmailBlur = (e) => {
    // 失焦时，获取验证渠道
    if (accountName && isEmail(accountName)) {
      getValidChannel();
    }
    props.handleBlur(e);
  };

  const handleSubmit: () => void = () => {
    // 若没触发input失焦，则点击登录时 先获取账户的登录验证渠道
    const is2fa = localStorage.getItem("is2fa") == "true";
    if (is2fa && !verifyCode) {
      if (!validChannel) getValidChannel();
      return;
    }

    const showRecaptcha = localStorage.getItem("showRecaptcha") == "true";
    // 是否开启阿里云验证
    if (showRecaptcha) {
      ncVal?.sessionId
        ? fetchSubmit(ncVal, "nc") // 滑动验证提交
        : NVCCaptcha.getVal((nvcVal) => {
            fetchSubmit(nvcVal, "nvc"); // 无痕验证提交
          });
    } else {
      fetchSubmit();
    }
  };

  // data 人机信息数据 type nc滑动， nvc无痕
  const fetchSubmit = async (data?: any, type?: string) => {
    // 当用户点击登录时，先保存用户名，上报信息时使用
    localStorage.setItem("user", accountName);

    setLoading(true);
    const params = {
      accountName: accountName,
      password: md5(password + MD5_FIXED_STRING)
    };

    const headers = {};
    const showRecaptcha = localStorage.getItem("showRecaptcha") == "true";

    // 滑动验证参数
    if (showRecaptcha) {
      if (type === "nc") {
        headers["SG-Verification-Type"] = 1; // 1表示滑动验证
        headers["SG-Verification-Session-Id"] = data.sessionId;
        headers["SG-Verification-Sig"] = data.sig;
        headers["SG-Verification-Token"] = data.token;
        headers["SG-Verification-Scene"] = "nc-login-h5";
      } else {
        headers["SG-Verification-Type"] = 3; // 3表示无痕验证
        headers["SG-Verification-Data"] = data;
      }
    }

    // 2FA验证参数
    const is2fa = localStorage.getItem("is2fa") == "true";
    if (is2fa) {
      headers["is2fa"] = is2fa;
      headers["validCode"] = verifyCode;
    }

    agentLogin(params, headers)
      .then((res) => {
        if (res?.code == "0") {
          // 滑动验证通过后关闭弹框
          ncVal && NCRef.current.setVisible(false);

          const {
            user_id,
            account_id,
            jwtToken,
            idCertifiedStatus,
            isGoogleCertified,
            user_roles,
            customer_info
          } = res?.data;
          let auditStatus: string = "";
          // "idCertifiedStatus 认证审核状态：0未认证，1待审核，2已通过, 3已拒绝, 只有管理端审核了状态才会变更
          // kyc接口： 0 Auditing 审核中，1 Certified  通过，2 Rejected  拒绝
          if (idCertifiedStatus == 0) {
            auditStatus = "";
          }
          if (idCertifiedStatus == 1) {
            auditStatus = "0";
          }
          if (idCertifiedStatus == 2) {
            auditStatus = "1";
          }
          if (idCertifiedStatus == 3) {
            auditStatus = "2";
          }
          localStorage.setItem(
            "user_auth",
            user_roles ? user_roles.join(",").toLocaleLowerCase() : ""
          );
          localStorage.setItem(`${location.origin}jwtToken`, jwtToken ?? "");
          localStorage.setItem("isAuthenticated", "true");
          localStorage.setItem("user", accountName);
          localStorage.setItem("customerId", account_id ?? user_id);
          localStorage.setItem("customerInfo", customer_info);
          localStorage.setItem("kycCertifiedStatus", auditStatus);
          localStorage.setItem("isGoogleCertified", Number(isGoogleCertified).toString());
          window.location.href = "/clients";
          setTimeout(() => {
            setLoading(false);
          }, 3000);
        } else if (res.code == "400" || res.code == "800") {
          setLoading(false);
          // 无痕验证失败，启动滑动验证
          if (!ncVal) {
            NCRef.current.setVisible(true);
            NCRef.current.init("nc_login_h5", (data) => {
              setNcVal(data);
            });
          }
        } else if (res.code == "900") {
          setLoading(false);
          // 二次滑动验证失败
          NCRef.current.reset();
          message.destroy();
          message.error(intl["afs.nvc.fail"] ?? res?.msg);
          Bugsnag.notify(formatBugsnagMessage(JSON.stringify(res)));
        } else {
          /*错误判断包含：
           * 1. 账号未注册，服务器端判断返回
           * 2. 账号或者密码错误，服务器端判断返回
           * 3. 密码错误超过5次，锁定2小时
           */
          setLoading(false);
          NCRef.current.setVisible(false);
          message.destroy();
          message.error(intl[res?.msg] ?? res?.msg);
        }
      })
      .catch(() => {
        setLoading(false);
        ncVal && NCRef.current.reset();
      });
  };

  const handleChangeVerifyCode = (e) => {
    const val = e.target.value;
    const onlyNumber = val.replace(/[^0-9]/gi, "");
    setVerifyCode(onlyNumber);
  };

  const title: string = intl["login.text.CMS"];
  const messageEmail: string = intl["register.email.incorrect"];

  const buttonDisabled = validChannel
    ? !(accountName && password && verifyCode && verifyCode.length >= 6)
    : !(accountName && password);

  return (
    <div className={styles.form}>
      <div className={styles.container}>
        <p className={styles.title}> {title} </p>
        <Form
          name="basic"
          layout="vertical"
          autoComplete="off"
          form={form}
          initialValues={{ accountName: accountName, password: password }}
          onFinish={handleSubmit}>
          {/* 为了解决chrome浏览器自动填充input不触发onchange问题 */}
          <Input type="password" autoComplete="new-password" className="hiddenInput" />
          {/* 防止自动填充邮箱验证码 */}
          <input className="hiddenInput" />
          <Form.Item
            className={styles.email}
            name="email"
            validateTrigger="onBlur"
            rules={[
              {
                type: "email",
                message: messageEmail
              },
              {
                required: true,
                message: messageEmail
              }
            ]}>
            <div>
              <label className={styles.label + " " + (accountName ? styles.active : "")}>
                {intl["login.email"]}
              </label>
              <Input
                className={accountName ? styles.inputHover : ""}
                autoComplete="new-password"
                autoFocus={true}
                ref={emailInput}
                name="accountName"
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
                onFocus={(e) => {
                  form.setFields([
                    {
                      name: "email",
                      value: e.target.value,
                      errors: null
                    }
                  ]);
                  props.handleFocus(e);
                }}
                onBlur={handleEmailBlur}
              />
            </div>
          </Form.Item>
          <Form.Item rules={[{ required: true, message: intl["error.password.format"] }]}>
            <div>
              <label className={styles.label + " " + (password ? styles.active : "")}>
                {intl["login.password"]}
              </label>
              <Input
                className={password ? styles.inputHover : ""}
                name="password"
                type="password"
                maxLength={PASSWORD_MAX_LENGTH}
                defaultValue={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={props.handleFocus}
                onBlur={props.handleBlur}
              />
            </div>
          </Form.Item>
          {/* 邮箱验证 */}
          {validChannel === "email" && (
            <div className={styles.eamilGroup}>
              <Form.Item
                name="verifyCode"
                rules={[
                  {
                    required: true,
                    message: intl["customer.email.code.incorrect"]
                  }
                ]}>
                <div>
                  <label className={styles.label}> {intl["login.email.verify.code"]} </label>
                  <Input
                    className={verifyCode ? styles.inputHover : ""}
                    autoComplete="new-password"
                    value={verifyCode}
                    onChange={handleChangeVerifyCode}
                    onFocus={props.handleFocus}
                    onBlur={props.handleBlur}
                    maxLength={VerifyCode_MAX_LENGTH}
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <Button
                  disabled={
                    !accountName || (accountName && !isEmail(accountName)) || props.countdown != 60
                  }
                  loading={props.loading}
                  className={styles.sendEmail}
                  onClick={() => props.getEmailCode(17, accountName)}>
                  {props.sendMailBtn}
                </Button>
              </Form.Item>
            </div>
          )}
          {/* 谷歌验证 */}
          {validChannel === "google" && (
            <Form.Item
              name="verifyCode"
              rules={[
                {
                  required: true,
                  message: intl["customer.email.code.incorrect"]
                }
              ]}>
              <div>
                <label className={styles.label}>{intl["login.google.verify.code"]}</label>
                <Input
                  className={verifyCode ? styles.inputHover : ""}
                  autoComplete="new-password"
                  value={verifyCode}
                  onChange={handleChangeVerifyCode}
                  onFocus={props.handleFocus}
                  onBlur={props.handleBlur}
                  maxLength={VerifyCode_MAX_LENGTH}
                />
              </div>
            </Form.Item>
          )}
          {/* 滑动验证 */}
          <AlicloudNCCaptcha ref={NCRef} />
          <Button
            className={styles.submit}
            type="primary"
            htmlType="submit"
            disabled={buttonDisabled}
            loading={loading || validChannelLoading}>
            {intl["login"]}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default LoginLayoutCMS(WithFiatAndSpotTrade(EmailCodeLayout(AgentLogin)));
