import React, { ReactChild } from "react";
import { Modal, ModalProps } from "antd";
import "./index.module.less";

export interface ModalBaseProps extends ModalProps {
  buttonType?: "default" | "only";
  children?: ReactChild;
}

const ModalBase: React.FC<ModalBaseProps> = (props) => {
  const { buttonType = "default", className, width, children, ...restProps } = props;

  let modalClassName = "baseModal";

  if (buttonType === "only") {
    modalClassName += " only";
  }

  if (className) {
    modalClassName += ` ${className}`;
  }

  return (
    <Modal className={modalClassName} width={480 || width} destroyOnClose {...restProps}>
      {children && children}
    </Modal>
  );
};

export default ModalBase;
