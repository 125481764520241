import {
  IIntelligenceIResponse,
  IReqverifyInvitationCode,
  IReqRegister,
  IReqSendEmailCode,
  IReqVerifyEmailCode,
  IReqLogin,
  IReqLoginChannel,
  IReqManagerLogin,
  IGoogleV3,
  IReqForgetResetPassword,
  IReqResetPassword,
  IReqChangeEmail,
  IReqDeviceInfo,
  IReqIfExist,
  IRes,
  IReqBanks,
  IReqBanksAdd,
  IReqBanksDelete,
  IReqGoogleBind,
  IReqGoogleRemove,
  IReqMyInvitation,
  IReqAllowHelpOperate,
  IReqFlexibleProducts,
  IReqFixedProductsDetails,
  IReqSubscribeFixedProducts,
  IReqFixedTransferToFlexible,
  IReqFixedSwitchAutoRenewal,
  IReqSpotBalance,
  IReqSubscribe,
  IReqRedeem,
  IReqIntelligenceNews,
  IReqIntelligenceAnnouncements,
  IReqIntelligenceAnnouncementsRead,
  IReqIntelligenceDetails,
  IReqKycInstitutions,
  IReqKycInstitutionsModify,
  IReqKycIndividualsModify,
  IReqKycIndividuals,
  IReqEmailCode,
  ICreateOrder,
  IOrders,
  IResOrdersOpen,
  IResOrdersHistory,
  ICancelOrder,
  IReqRedeemAvailable,
  IDepth,
  IReqFlexibleHistory,
  IReqFixedHistory,
  IReqFixedProductsList,
  IReqFixedContractsList,
  IReqFixedContractsDetails,
  IReqFixedCancelSubscription,
  IReqStrategyList,
  IReqStrategyDetail,
  IReqStrategyApply,
  IReqStrategyRedeem,
  IReqStrategyOrderCancel,
  IReqStrategyOrderDetail,
  IReqAssetsSituational,
  IWalletDepositAddress,
  IWalletAccounts,
  IWalletDepositFiat,
  IWalletWithdrawFiat,
  IWalletWithdraw,
  IReqAccountHistory,
  IWalletHistory,
  IWalletFiatHistory,
  IWalletAddBankAccount,
  IWalletWithdrawCircle,
  IReqAutoSubscribe,
  IReqInterestTrading,
  IReqAutoProducts,
  IReqAddAutomaticInvestmentPlan,
  IReqCancelAutomaticInvestmentPlan,
  IReqAutoProductsHistory,
  IReqClientInfo,
  IReqCustomerList,
  IUploadQuery,
  IResClientApply,
  IReqAgentApply,
  IReqStopPartnerRole,
  IReqAddSub,
  IReqRegisterSub,
  IReqStopSub
} from "../model/index";
import axiosInstance from "./http";
import api from "./api";
import { strategiesUrl } from "@/config/index";

/* -------------------- 首页 -------------------- */
export const getAssetsSituational = (params: IReqAssetsSituational): Promise<IRes> => {
  return axiosInstance.get(api.assets.static, { params }).then((res) => {
    return res?.data;
  });
};

/* -------------------- end 首页 -------------------- */

/* --------------------交易相关-------------------- */
/**
 * @description: 获取订单id
 * @params {}
 * @return {Promise}
 */
export const tradeAcquireOrderId = (): Promise<IRes> => {
  return axiosInstance.get(api.trading.acquireOrderId).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 下单,暂时只支持现货
 * @params {ICreateOrder}
 * @return {Promise}
 */
export const createTradeOrder = (params: ICreateOrder): Promise<IRes> => {
  return axiosInstance
    .post(api.trading.orders, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 取消订单
 * @params {ICancelOrder}
 * @return {Promise}
 */
export const cancelTradeOrder = (params: ICancelOrder): Promise<IRes> => {
  return axiosInstance
    .delete(api.trading.cancelOrder.replace(/{orderId}/, params.orderId))
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 查询订单详情
 * @params {ICancelOrder}
 * @return {Promise}
 */
export const fetchOrderDetail = (params: ICancelOrder): Promise<IRes> => {
  return axiosInstance
    .get(api.trading.orderDetail.replace(/{orderId}/, params.orderId))
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 获取open order history
 * @params {IOrders}
 * @return {Promise}
 */
export const ordersOpen = (params: IOrders): Promise<IResOrdersOpen> => {
  return axiosInstance.get(api.trading.ordersOpen, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取trade order history
 * @params {IOrders}
 * @return {Promise}
 */
export const ordersHistory = (params: IOrders): Promise<IResOrdersHistory> => {
  return axiosInstance.get(api.trading.ordersHistory, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取指定盘口的深度数据
 * @params {}
 * @return {Promise}
 */
export const fecthDepthByCoin = (params): Promise<IRes> => {
  return axiosInstance.get(api.market.orderbooks.replace(/{symbol}/, params.symbol)).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取币种24小时最高/最低/交易量等
 * @params {}
 * @return {Promise}
 */
export const fecthSymbolList = (): Promise<IRes> => {
  return axiosInstance.get(api.market.symbol.list).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取离线盘口信息
 * @params {}
 * @return {Promise}
 */
export const fetchOfflineSymbolList = (): Promise<IRes> => {
  return axiosInstance.get(api.market.symbol.offline).then((res) => {
    return res?.data;
  });
};
/* -------------------- end 交易相关-------------------- */

/*-------------------- 新闻相关 -------------------- */
/**
 * @description: 通知已读上报
 * @params {IReqIntelligenceAnnouncementsRead}
 * @return {Promise}
 */
export const announcementsRead = (params: IReqIntelligenceAnnouncementsRead): Promise<IRes> => {
  return axiosInstance.post(api.intelligence.announcementsReaded, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 通知列表
 * @params {IReqIntelligenceAnnouncements}
 * @return {Promise}
 */
export const fecthIntelligenceAnnouncements = (
  params: IReqIntelligenceAnnouncements
): Promise<IRes> => {
  return axiosInstance.get(api.intelligence.announcements, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 通知详情
 * @params {IReqIntelligenceDetails}
 * @return {Promise}
 */
export const fecthIntelligenceAnnouncementsDetail = (
  params: IReqIntelligenceDetails
): Promise<IRes> => {
  return axiosInstance
    .get(api.intelligence.announcementDetail.replace(/{id}/, params.id))
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 新闻列表
 * @params {IReqIntelligenceNews}
 * @return {Promise}
 */
export const fecthIntelligenceNews = (
  params: IReqIntelligenceNews
): Promise<IIntelligenceIResponse> => {
  return axiosInstance.get(api.intelligence.news, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 新闻详情
 * @params {IReqIntelligenceDetails}
 * @return {Promise}
 */
export const fecthIntelligenceNewsDetail = (params: IReqIntelligenceDetails): Promise<IRes> => {
  return axiosInstance.get(api.intelligence.newsDetail.replace(/{id}/, params.id)).then((res) => {
    return res?.data;
  });
};
/*-------------------- end 新闻相关 -------------------- */

/* -------------------- 理财相关 -------------------- */

/**
 * @description: 赎回
 * @params {IReqAvailable}
 * @return {Promise}
 */
export const redeem = (params: IReqRedeem): Promise<IRes> => {
  return axiosInstance.post(api.savings.flexible.redeem, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 申购/追加
 * @params {IReqSubscribe}
 * @return {Promise}
 */
export const subscribe = (params: IReqSubscribe): Promise<IRes> => {
  return axiosInstance.post(api.savings.flexible.subscribe, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取现货账户的该币种可用数量
 * @params {IReqSpotBalance}
 * @return {Promise}
 */
export const availableSpot = (params: IReqSpotBalance): Promise<IRes> => {
  return axiosInstance.get(api.balance, { params: params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取现货账户的该币种可用数量, 可以一次传多个币种，按map返回
 * @params {IReqSpotBalance}
 * @return {Promise}
 */
export const availableSpots = (params: IReqSpotBalance): Promise<IRes> => {
  return axiosInstance.get(api.balances, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 活期理财申购前获取现货账户的该币种可用数量
 * @params {IReqRedeemAvailable}
 * @return {Promise}
 */
export const availableRedeem = (params: IReqRedeemAvailable): Promise<IRes> => {
  return axiosInstance.get(api.savings.flexible.available, { params: params }).then((res) => {
    return res?.data;
  });
};

// 我的理财余额（my savings balance）
export const yieldBalance = (): Promise<IRes> => {
  return axiosInstance.get(api.savings.flexible.yieldBanlance).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 活期理财产品列表
 * @params {IReqflexibleProducts}
 * @return {Promise}
 */
export const yieldProducts = (params: IReqFlexibleProducts): Promise<IRes> => {
  return axiosInstance.get(api.savings.flexible.products, { params: params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 申购/赎回历史记录
 * @params {IReqFlexibleHistory}
 * @return {Promise}
 */
export const yieldHistory = (params: IReqFlexibleHistory): Promise<IRes> => {
  return axiosInstance.get(api.savings.flexible.history, { params: params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 自动申购 开启/关闭
 * @params {IReqAutoSubscribe}
 * @return {Promise}
 */
export const autoSubscribe = (params: IReqAutoSubscribe): Promise<IRes> => {
  return axiosInstance.get(api.savings.flexible.autoSubscribe, { params: params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 利息买币 开启/关闭
 * @params {IReqInterestTrading}
 * @return {Promise}
 */
export const interestTrading = (params: IReqInterestTrading): Promise<IRes> => {
  return axiosInstance.get(api.savings.flexible.interestTrading, { params: params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取利息买币的最新一条信息
 * @params {productId}
 * @return {Promise}
 */
export const getInterestTradingInfo = (productId: string): Promise<IRes> => {
  return axiosInstance
    .get(api.savings.flexible.interestTradingInfo, { params: { productId } })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 定投产品列表
 * @params {IReqAutoProducts}
 * @return {Promise}
 */
export const autoInvestList = (params: IReqAutoProducts): Promise<IRes> => {
  return axiosInstance.get(api.savings.autoInvestList, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 我的定投产品列表
 * @params {IReqAutoProducts}
 * @return {Promise}
 */
export const myAutoInvestList = (params: IReqAutoProducts): Promise<IRes> => {
  return axiosInstance.get(api.savings.myAutoInvestList, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 我的定投产品列表历史
 * @params {IReqAutoProductsHistory}
 * @return {Promise}
 */
export const myAutoInvestHistoryList = (params: IReqAutoProductsHistory): Promise<IRes> => {
  return axiosInstance.get(api.savings.myAutoInvestHistoryList, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 添加定投计划
 * @params {IReqAddAutomaticInvestmentPlan}
 * @return {Promise}
 */
export const addAutomaticInvestmentPlan = (
  params: IReqAddAutomaticInvestmentPlan
): Promise<IRes> => {
  return axiosInstance
    .post(api.savings.addAutomaticInvestmentPlan, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 终止定投计划
 * @params {IReqCancelAutomaticInvestmentPlan}
 * @return {Promise}
 */
export const cancelAutomaticInvestmentPlan = (
  params: IReqCancelAutomaticInvestmentPlan
): Promise<IRes> => {
  return axiosInstance.post(api.savings.cancelAutomaticInvestmentPlan, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 定期申购历史记录
 * @params {IReqFlexibleHistory}
 * @return {Promise}
 */
export const fixedYieldHistory = (params: IReqFixedHistory): Promise<IRes> => {
  return axiosInstance.get(api.savings.fixed.history, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 定期产品列表
 * @params {IReqFlexibleHistory}
 * @return {Promise}
 */
export const fixedProductsList = (params: IReqFixedProductsList): Promise<IRes> => {
  return axiosInstance.get(api.savings.fixed.productList, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 定期产品详情
 * @params {IReqFixedProductsDetails}
 * @return {Promise}
 */
export const getFixedProductsDetail = (params: IReqFixedProductsDetails): Promise<IRes> => {
  return axiosInstance
    .get(api.savings.fixed.productDetail.replace(/{id}/, params.id))
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 申购定期产品
 * @params {IReqSubscribeFixedProducts}
 * @return {Promise}
 */
export const subscribeFixedProducts = (params: IReqSubscribeFixedProducts): Promise<IRes> => {
  return axiosInstance.post(api.savings.fixed.subscribe, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 定期持仓合约列表
 * @params {IReqFixedContractsList}
 * @return {Promise}
 */
export const getFixedContractsList = (params: IReqFixedContractsList): Promise<IRes> => {
  return axiosInstance.get(api.wallet.yieldFixedContracts, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 定期持仓合约详情
 * @params {IReqFixedContractsDetails}
 * @return {Promise}
 */
export const getFixedContractsDetail = (params: IReqFixedContractsDetails): Promise<IRes> => {
  return axiosInstance
    .get(api.wallet.yieldFixedContractsDetail.replace(/{id}/, params.id))
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 撤销定期申购
 * @params {IReqFixedCancelSubscription}
 * @return {Promise}
 */
export const fixedCancelSubscription = (params: IReqFixedCancelSubscription): Promise<IRes> => {
  return axiosInstance
    .delete(api.wallet.yieldFixedCancelSubscription, { params: params })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 定期理财到期自动转活期
 * @params {IReqFixedTransferToFlexible}
 * @return {Promise}
 */
export const fixedTransferToFlexible = (params: IReqFixedTransferToFlexible): Promise<IRes> => {
  return axiosInstance.post(api.wallet.yieldFixedTransferToFlexible, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 定期理财到期自动购买下期产品
 * @params {IReqFixedSwitchAutoRenewal}
 * @return {Promise}
 */
export const fixedSwitchAutoRenewal = (params: IReqFixedSwitchAutoRenewal): Promise<IRes> => {
  return axiosInstance.post(api.wallet.yieldFixedSwitchAutoRenewal, params).then((res) => {
    return res?.data;
  });
};

/* -------------------- end 理财相关 -------------------- */

/* -------------------- 策略相关 -------------------- */
/**
 * @description: 策略列表
 * @params {IReqStrategyList}
 * @return {Promise}
 */
export const getStrategyList = (params: IReqStrategyList): Promise<IRes> => {
  return axiosInstance.get(api.strategies.strategyList, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 策略详情
 * @params {IReqStrategyDetail}
 * @return {Promise}
 */
export const getStrategyDetail = (params: IReqStrategyDetail): Promise<IRes> => {
  return axiosInstance.get(api.strategies.strategyDetail, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 策略申购
 * @params {IReqStrategyApply}
 * @return {Promise}
 */
export const subscribeStrategy = (params: IReqStrategyApply): Promise<IRes> => {
  return axiosInstance.post(api.strategies.strategyApply, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 策略赎回
 * @params {IReqStrategyRedeem}
 * @return {Promise}
 */
export const redeemStrategy = (params: IReqStrategyRedeem): Promise<IRes> => {
  return axiosInstance.post(api.strategies.strategyRedeem, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 撤销策略申赎
 * @params {IReqStrategyOrderCancel}
 * @return {Promise}
 */
export const cancelStrategy = (params: IReqStrategyOrderCancel): Promise<IRes> => {
  return axiosInstance
    .delete(api.strategies.strategyOrderCancel, { params: params })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 策略申购赎回历史列表
 * @params {IReqStrategyList}
 * @return {Promise}
 */
export const getStrategyOrderList = (params: IReqStrategyList): Promise<IRes> => {
  return axiosInstance.get(api.strategies.strategyOrderList, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 策略申购赎回历史详情
 * @params {IReqStrategyOrderDetail}
 * @return {Promise}
 */
export const getStrategyOrderDetail = (params: IReqStrategyOrderDetail): Promise<IRes> => {
  return axiosInstance
    .get(api.strategies.strategyOrderDetail.replace(/{orderId}/, params.orderId))
    .then((res) => {
      return res?.data;
    });
};

/* -------------------- end 策略相关 -------------------- */

/* -------------------- 钱包相关 -------------------- */

/**
 * @description: 获取钱包信息
 * @params
 * @return {Promise}
 */
export const getWalletInfo = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.walletInfo).then((res) => {
    return res?.data;
  });
};
/**

/**
 * @description: 现货账户
 * @params
 * @return {Promise}
 */
export const getWalletSpotAccounts = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.spot).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 理财账户
 * @params
 * @return {Promise}
 */
export const getWalletYileldAccounts = (params: IWalletAccounts): Promise<IRes> => {
  return axiosInstance.get(api.wallet.yield, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 策略账户
 * @params
 * @return {Promise}
 */
export const getWalletStrategiesAccounts = (params: IWalletAccounts): Promise<IRes> => {
  return axiosInstance.get(api.wallet.strategies, { params: params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 充值币种列表
 * @params
 * @return {Promise}
 */
export const getDepisitCoinList = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.depositCoinList).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取币种的多链列表
 * @params
 * @return {Promise}
 */
export const getAssetCurrencyConfig = (params: IWalletDepositAddress): Promise<IRes> => {
  return axiosInstance.get(api.wallet.assetCurrencyConfig, { params: params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取已存在电子货币充值地址
 * @params
 * @return {Promise}
 */
export const showDepositAddress = (params: IWalletDepositAddress): Promise<IRes> => {
  return axiosInstance.get(api.wallet.showDepositAddress, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 电子货币充值地址，没有则生成地址
 * @params
 * @return {Promise}
 */
export const getDepositAddress = (params: IWalletDepositAddress): Promise<IRes> => {
  return axiosInstance.get(api.wallet.depositAddress, { params: params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取法币充值参数
 * @params
 * @return {Promise}
 */
export const getOtcDepositParam = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.otcDepositParam).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 法币充值
 * @params {IWalletDepositFiat}
 * @return {Promise}
 */
export const applyOtcDeposit = (params: IWalletDepositFiat): Promise<IRes> => {
  return axiosInstance
    .post(api.wallet.applyOtcDeposit, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 电子货币充值地址，没有则生成地址
 * @params
 * @return {Promise}
 */
export const getWithdrawLimit = (params: IWalletDepositAddress): Promise<IRes> => {
  return axiosInstance
    .get(api.wallet.withdrawLimit.replace(/{currency}/, params.currency))
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 获取法币提币交易订单id
 * @params
 * @return {Promise}
 */
export const getWithdrawOtcOrder = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.withdrawOtcOrder).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 法币提取
 * @params {IWalletWithdrawFiat}
 * @return {Promise}
 */
export const applyOtcWithdraw = (params: IWalletWithdrawFiat): Promise<IRes> => {
  return axiosInstance
    .post(api.wallet.applyOtcWithdraw, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 电子货币提取
 * @params {IWalletWithdraw}
 * @return {Promise}
 */
export const applyWithdraw = (params: IWalletWithdraw): Promise<IRes> => {
  return axiosInstance
    .post(api.wallet.applyWithdraw, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 判断是否可以出金
 * @return {Promise}
 */
export const preWithdraw = (): Promise<IRes> => {
  return axiosInstance.post(api.wallet.preWithdraw).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 电子货币充提历史
 * @params {IWalletWithdraw}
 * @return {Promise}
 */
export const depositWithdrawHistory = (params: IWalletHistory): Promise<IRes> => {
  return axiosInstance.get(api.wallet.depositWithdrawHistory, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 电子货币充提历史
 * @params {IWalletWithdraw}
 * @return {Promise}
 */
export const otcDepositWithdrawHistory = (params: IWalletFiatHistory): Promise<IRes> => {
  return axiosInstance.get(api.wallet.otcDepositWithdrawHistory, { params }).then((res) => {
    return res?.data;
  });
};

//circle
/**
 * @description: 获取银行账户信息
 * @params
 * @return {Promise}
 */
export const getBankAccount = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.bankAccount).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取circle支持银行列表
 * @params
 * @return {Promise}
 */
export const getBankList = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.bankList).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 绑定circle银行账户
 * @params {IWalletWithdraw}
 * @return {Promise}
 */
export const addBankAccount = (params: IWalletAddBankAccount): Promise<IRes> => {
  return axiosInstance
    .post(api.wallet.addBankAccount, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 修改circle银行账户绑定
 * @params {IWalletWithdraw}
 * @return {Promise}
 */
export const refillBankAccount = (params: IWalletAddBankAccount): Promise<IRes> => {
  return axiosInstance
    .post(api.wallet.refillBankAccount, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 获取circle入金信息
 * @params
 * @return {Promise}
 */
export const getCircleDepositInfo = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.circleDepositInfo).then((res) => {
    return res?.data;
  });
};

/**
 * @description: circle usdc提取
 * @params {IWalletWithdrawCircle}
 * @return {Promise}
 */
export const withdrawCircle = (params: IWalletWithdrawCircle): Promise<IRes> => {
  return axiosInstance
    .post(api.wallet.circleWithdraw, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 获取circle历史
 * @params {IWalletFiatHistory}
 * @return {Promise}
 */
export const getCircleHistory = (params: IWalletFiatHistory): Promise<IRes> => {
  return axiosInstance.get(api.wallet.circleHistory, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取circle入金信息
 * @params
 * @return {Promise}
 */
export const getCircleLimit = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.circleLimit).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 判断资产管理费是否可以withdraw
 * @params {}
 * @return {Promise}
 */
export const amfIsEnough = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.amfIsEnough).then((res) => {
    return res?.data;
  });
};
/**
 * @description: withdraw手动扣除资产管理费
 * @params {}
 * @return {Promise}
 */
export const amfDeduct = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.amfDeduct).then((res) => {
    return res?.data;
  });
};
/* -------------------- end 钱包相关 -------------------- */

/* -------------------- 个人中心相关 -------------------- */

/**
 * @description: 获取kyc
 * @params {}
 * @return {Promise}
 */
export const fecthKycInfo = (): Promise<IRes> => {
  return axiosInstance.get(api.kyc.fetch).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 修改机构/个人kyc
 * @params {IReqKycInstitutionsModify | IReqKycIndividualsModify}
 * @return {Promise}
 */
export const modifyKyc = (
  params: IReqKycInstitutionsModify | IReqKycIndividualsModify
): Promise<IRes> => {
  return axiosInstance
    .put(api.kyc.edit, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 添加机构/个人kyc
 * @params {IReqKycInstitutions ｜ IReqKycIndividuals}
 * @return {Promise}
 */
export const addKyc = (params: IReqKycInstitutions | IReqKycIndividuals): Promise<IRes> => {
  return axiosInstance
    .post(api.kyc.add, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 获取谷歌验证器状态
 * @params {}
 * @return {Promise}
 */
export const googleVerifyStatus = (): Promise<IRes> => {
  return axiosInstance.get(api.customer.googleVerify).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 谷歌解绑
 * @params {IReqGoogleRemove}
 * @return {Promise}
 */
export const googleRemove = (params: IReqGoogleRemove): Promise<IRes> => {
  return axiosInstance.delete(api.customer.removeBind, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 绑定谷歌
 * @params {IReqGoogleBind}
 * @return {Promise}
 */
export const googleBind = (params: IReqGoogleBind): Promise<IRes> => {
  return axiosInstance.post(api.customer.googlebind, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取googlekey
 * @return {Promise}
 */
export const googleGenerateKey = (params: IReqEmailCode): Promise<IRes> => {
  return axiosInstance.post(api.customer.generateKey, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 删除银行卡
 * @params {IReqBanksDelete}
 * @return {Promise}
 */
export const deleteBank = (params: IReqBanksDelete): Promise<IRes> => {
  return axiosInstance.delete(`${api.customer.banksDelete}${params}`).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 添加银行卡
 * @params {IReqBanksAdd}
 * @return {Promise}
 */
export const addBank = (params: IReqBanksAdd): Promise<IRes> => {
  return axiosInstance.post(api.customer.banksAdd, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取银行卡列表
 * @params {IReqBanks}
 * @return {Promise}
 */
export const getBanks = (params: IReqBanks): Promise<IRes> => {
  return axiosInstance.get(api.customer.banks, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取googlekey
 * @return {Promise}
 */
export const isRegistered = (params: IReqIfExist): Promise<IRes> => {
  return axiosInstance.get(api.customer.ifExist, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 查询代理商代客操作授权信息
 * @return {Promise}
 */
export const applyCustomerRole = (): Promise<IRes> => {
  return axiosInstance.get(api.customer.applyRole).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 终止代理商权限
 * @params {IReqStopPartnerRole}
 * @return {Promise}
 */
export const terminatePartnerRole = (params: IReqStopPartnerRole): Promise<IRes> => {
  return axiosInstance.delete(api.customer.terminatePartnerRole, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 查询子账号信息
 * @return {Promise}
 */
export const getSubInfo = (): Promise<IRes> => {
  return axiosInstance.get(api.customer.subInfo).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 添加子账号
 * @params {IReqAddSub}
 * @return {Promise}
 */
export const inviteSub = (params: IReqAddSub): Promise<IRes> => {
  return axiosInstance.post(api.customer.inviteSub, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 注册子账号
 * @params {IReqRegisterSub}
 * @return {Promise}
 */
export const registerSub = (params: IReqRegisterSub): Promise<IRes> => {
  return axiosInstance.post(api.customer.registerSub, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 终止子账号
 * @params {IReqStopSub}
 * @return {Promise}
 */
export const stopSub = (params: IReqStopSub): Promise<IRes> => {
  return axiosInstance.delete(api.customer.stopSub, { params: params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取我的邀请列表
 * @params {IReqMyInvitation}
 * @return {Promise}
 */
export const getMyInvitationList = (params: IReqMyInvitation): Promise<IRes> => {
  return axiosInstance.get(api.customer.myInvitation, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 允许帮客操作开关
 * @params {IReqAllowHelpOperate}
 * @return {Promise}
 */
export const updateAllowHelpOperate = (params: IReqAllowHelpOperate): Promise<IRes> => {
  return axiosInstance.post(api.customer.allowHelpOperate, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 查询允许帮客操作开关信息
 * @return {Promise}
 */
export const getAllowHelpOperateInfo = (): Promise<IRes> => {
  return axiosInstance.get(api.customer.allowHelpOperate).then((res) => {
    return res?.data;
  });
};

/* -------------------- end 个人中心相关 -------------------- */

/* -------------------- 客户代理商/管理员相关 --------------------*/

/**
 * @description: 获取代理商邀请码
 * @params {}
 * @return {Promise}
 */
export const fecthInviterInfo = (): Promise<IRes> => {
  return axiosInstance.get(api.customer.inviterInfo).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取客户代理商信息
 * @params {IReqClientInfo}
 * @return {Promise}
 */
export const getClientInfo = (params: IReqClientInfo): Promise<IRes> => {
  return axiosInstance.get(api.client.info, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取客户信息
 * @params {IReqCustomerList}
 * @return {Promise}
 */
export const getCustomerList = (params: IReqCustomerList): Promise<IRes> => {
  return axiosInstance.get(api.client.customerList, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商资产
 * @params {}
 * @return {Promise}
 */
export const getCommissionSummary = (): Promise<IRes> => {
  return axiosInstance.get(api.client.commissionSummary).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商 客户列表信息
 * @params {IReqCustomerList}
 * @return {Promise}
 */
export const getCommissionList = (params: IReqCustomerList): Promise<IRes> => {
  return axiosInstance.get(api.client.commissionList, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取客户资产管理费
 * @params {}
 * @return {Promise}
 */
export const getAMFSummary = (): Promise<IRes> => {
  return axiosInstance.get(api.client.AMFSummary).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取客户资产管理发列表历史
 * @params {IReqCustomerList}
 * @return {Promise}
 */
export const getAMFList = (params: IReqCustomerList): Promise<IRes> => {
  return axiosInstance.get(api.client.AMFList, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 代理商登陆
 * @params {IReqLogin}
 * @return {Promise}
 */
export const agentLogin = (params: IReqLogin, headers?: any): Promise<IRes> => {
  return axiosInstance.post(api.client.agentLogin, params, { headers }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 代理商操作客户
 * @params {IReqClientInfo}
 * @return {Promise}
 */
export const operateCustomer = (params: IReqClientInfo): Promise<IRes> => {
  return axiosInstance.post(api.client.operateCustomer, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 退出代理商操作客户
 * @params {}
 * @return {Promise}
 */
export const cancelOperateCustomer = (): Promise<IRes> => {
  return axiosInstance.delete(api.client.operateCustomer).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 申请代客操作
 * @params {IResClientApply}
 * @return {Promise}
 */
export const applyPartner = (params: IResClientApply): Promise<IRes> => {
  return axiosInstance
    .post(api.client.applyRole, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description:
 * @params {IReqAgentApply}
 * @return {Promise}
 */
export const processApplyRole = (params: IReqAgentApply): Promise<IRes> => {
  return axiosInstance
    .post(api.customer.processApplyRole, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 管理员登陆
 * @params {IReqManagerLogin}
 * @return {Promise}
 */
export const managerLogin = (params: IReqManagerLogin): Promise<IRes> => {
  return axiosInstance.post(api.client.managerLogin, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 管理员操作客户
 * @params {IReqClientInfo}
 * @return {Promise}
 */
export const managerOperate = (params: IReqClientInfo): Promise<IRes> => {
  return axiosInstance.post(api.client.managerOperate, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 退出代理操作客户
 * @params {}
 * @return {Promise}
 */
export const cancelManagerOperate = (): Promise<IRes> => {
  return axiosInstance.delete(api.client.managerOperate).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获管理用户
 * @params {IReqClientInfo}
 * @return {Promise}
 */
export const getManageCustomerInfo = (params: IReqClientInfo): Promise<IRes> => {
  return axiosInstance.get(api.customer.manageInfo, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 修改管理员密码
 * @params {IReqResetPassword}
 * @return {Promise}
 */
export const resetManagerPassword = (params: IReqResetPassword): Promise<IRes> => {
  return axiosInstance.post(api.client.changePassword, params).then((res) => {
    return res?.data;
  });
};
/* -------------------- end 客户代理商相关 -------------------- */

/* -------------------- 用户相关 --------------------*/
/**
 * @description: 注册
 * @params {IReqRegister}
 * @return {Promise}
 */
export const register = (params: IReqRegister): Promise<IRes> => {
  return axiosInstance
    .post(api.customer.register, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 登陆
 * @params {IReqLogin}
 * @return {Promise}
 */
export const login = (params: IReqLogin, headers?: any): Promise<IRes> => {
  return axiosInstance.post(api.customer.login, params, { headers }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 谷歌v3
 * @params {IGoogleV3}
 * @return {Promise}
 */
export const googleV3Record = (param: IGoogleV3): Promise<IRes> => {
  return axiosInstance.post(api.customer.googleV3, param).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 登出
 * @params
 * @return {Promise}
 */
export const logout = (): Promise<IRes> => {
  return axiosInstance.get(api.customer.logout).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 忘记密码重置密码
 * @params {IReqForgetResetPassword}
 * @return {Promise}
 */
export const forgetResetPassword = (params: IReqForgetResetPassword): Promise<IRes> => {
  return axiosInstance
    .post(api.customer.forgetResetPassword, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 直接修改密码
 * @params {IReqResetPassword}
 * @return {Promise}
 */
export const resetPassword = (params: IReqResetPassword): Promise<IRes> => {
  return axiosInstance.post(api.customer.resetPassword, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 修改邮箱
 * @params {IReqChangeEmail}
 * @return {Promise}
 */
export const changeEmail = (params: IReqChangeEmail): Promise<IRes> => {
  return axiosInstance.post(api.customer.changeEmail, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: oss上传
 * @params {IUploadQuery}
 * @return {Promise}
 */
export const getOssData = async (params: IUploadQuery): Promise<IRes> => {
  return axiosInstance.post(api.customer.ossData, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取用户配置
 * @params
 * @return {Promise}
 */
export const getConfigInfo = (): Promise<IRes> => {
  return axiosInstance.get(api.customer.config).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 上报设备信息
 * @params {IReqDeviceInfo}
 * @return {Promise}
 */
export const reportDeviceInfo = (params: IReqDeviceInfo): Promise<IRes> => {
  return axiosInstance
    .post(api.customer.device, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 获取登录验证渠道
 * @params {}
 * @return {Promise}
 */
export const fecthCustomerSetting = (params: IReqLoginChannel): Promise<IRes> => {
  return axiosInstance.get(api.customer.customerSetting, { params }).then((res) => res.data);
};

/* -------------------- end 用户相关 --------------------*/

/* --------------------  账务历史 -------------------- */

/**
 * @description: 获取账务历史信息
 * @params {IReqAccountHistory}
 * @return {Promise}
 */
export const accountHistory = (params: IReqAccountHistory): Promise<IRes> => {
  return axiosInstance.get(api.accountHistory, { params }).then((res) => {
    return res?.data;
  });
};

/* -------------------- end 账务历史 --------------------*/

/* -------------------- 通用 -------------------- */

/*
 * @description: 获取所有币种、名称、icon等, 待开发
 * @params {}
 * @return {Promise}
 */
export const fetchCoinList = (): Promise<IRes> => {
  return axiosInstance.get(api.market.currency.coinList).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 验证邀请码
 * @params {IReqverifyInvitationCode}
 * @return {Promise}
 */
export const verifyInvitationCode = (params: IReqverifyInvitationCode): Promise<IRes> => {
  return axiosInstance.get(api.customer.verifyInvitationCode, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 发送邮箱验证码
 * @params {IReqSendEmailCode}
 * @return {Promise}
 */
export const sendEmailCode = (params: IReqSendEmailCode): Promise<IRes> => {
  return axiosInstance.get(api.customer.sendEmailCode, { params }).then((res) => {
    return res?.data ?? null;
  });
};
/**
 * @description: 登陆后发送邮箱验证码
 * @params {IReqSendEmailCode}
 * @return {Promise}
 */
export const sendAuthEmailCode = (params: IReqSendEmailCode): Promise<IRes> => {
  return axiosInstance.get(api.customer.sendAuthEmailCode, { params }).then((res) => {
    return res?.data ?? null;
  });
};

/**
 * @description: 校验邮箱验证码
 * @params {IReqVerifyEmailCode}
 * @return {Promise}
 */
export const verifyEmailCode = (params: IReqVerifyEmailCode): Promise<IRes> => {
  return axiosInstance.post(api.customer.verifyEmailCode, params).then((res) => {
    return res?.data;
  });
};
/* -------------------- end 通用 --------------------*/
