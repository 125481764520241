import { subscribe } from "./request";

// api统一配置
const devMarket = "/devMarket/";
const api = {
  customer: {
    verifyInvitationCode: "auth/verify/invitationCode",
    register: "auth/register",
    sendEmailCode: "auth/email/getCode",
    sendAuthEmailCode: "auth/email/getAuthCode", //登陆后的发送邮箱验证码
    verifyEmailCode: "customer/email/verifyCode",
    login: "auth/login",
    googleV3: "google/photo",
    logout: "auth/logout",
    resetPassword: "auth/resetPassword",
    forgetResetPassword: "auth/forget/resetPassword",
    banks: "bank/banks", // 获取银行卡列表
    banksAdd: "bank/banks", // 添加银行卡
    banksDelete: "bank/banks/",
    generateKey: "google/generateKey/",
    googlebind: "google/bind/",
    removeBind: "google/bind",
    googleVerify: "google/verify", // 获取谷歌验证器状态
    ifExist: "auth/ifExist", // 验证是否邮箱已被注册
    myInvitation: "/customer/myInvitation", // 我的邀请列表
    inviterInfo: "customer/inviter/info", // 代理商信息
    changeEmail: "customer/update/email", // 修改邮箱
    ossData: "auth/ossToken", // 获取host信息
    config: "auth/config", // 获取配置
    applyRole: "customer/apply-role", //查询代理商代客操作授权信息
    processApplyRole: "customer/process-apply-role", //审批是否允许通过代理
    terminatePartnerRole: "customer/terminate-role", //终止代理商权限
    subInfo: "sub-customer/info",
    inviteSub: "sub-customer/invite",
    registerSub: "sub-customer/register",
    stopSub: "sub-customer/terminate-authorization",
    manageInfo: "customer/info", //管理员查询客户信息
    allowHelpOperate: "customer/allow-help-operate", // 允许帮客操作开关
    device: "/customer/device", // 获取设备信息上报后端
    customerSetting: "/customer/setting"
  },
  kyc: {
    add: "customer/kyc", //添加kyc
    fetch: "customer/kyc", //  获取kyc
    edit: "customer/kyc" //  编辑kyc
  },
  // 现货币种可以用余额
  balance: "account/balance",
  balances: "account/balances",
  accountHistory: "/account/log/history",
  savings: {
    flexible: {
      products: "savings/flexible/products",
      subscribe: "savings/flexible/subscribe", // 申购/追加
      available: "savings/flexible/available", // 可赎回对应币种的数量
      redeem: "savings/flexible/redeem", //赎回
      accounts: "savings/flexible/accounts", // 理财账户明细
      yieldBanlance: "savings/flexible/account",
      history: "savings/flexible/history", // 申购/赎回历史记录
      autoSubscribe: "savings/flexible/auto/subscribe", // 开启/关闭 自动申购
      interestTrading: "savings/flexible/interest/trading", // 开启/关闭 利息买币
      interestTradingInfo: "savings/flexible/trading/info" // 获取利息买币的最新一条信息
    },
    fixed: {
      history: "fixed-deposit/logs", // 定期申购历史
      productList: "fixed-deposit/products", // 定期产品列表
      productDetail: "fixed-deposit/products/{id}", // 定期产品详情
      subscribe: "fixed-deposit/subscribe" // 定期产品申购
    },
    autoInvestList: "savings/investment/products", //定投产品列表
    myAutoInvestList: "savings/investment/contracts", //我的定投产品列表
    myAutoInvestHistoryList: "savings/investment/history", //我的定投产品历史
    addAutomaticInvestmentPlan: "savings/investment/subscribe", //添加定投产品
    cancelAutomaticInvestmentPlan: "savings/investment/stop" //终止定投产品
  },
  strategies: {
    //策略线上化
    strategyList: "v1/strategy/strategies", // 策略列表
    strategyDetail: "v1/strategy/info", // 策略详情
    strategyApply: "v1/strategy/apply", // 策略申购意向
    strategyRedeem: "v1/strategy/redeem", // 策略赎回意向
    strategyOrderList: "v1/strategy/orders", // 查询策略订单历史列表
    strategyOrderCancel: "v1/strategy/order", // delete 取消策略意向
    strategyOrderDetail: "v1/strategy/{orderId}" // 查询策略订单流转信息
  },
  intelligence: {
    news: "intelligence/news", // 新闻列表
    announcements: "intelligence/announcements", // 通知列表
    announcementsReaded: "intelligence/announcements/read", // 通知已读上报
    newsDetail: "intelligence/news/{id}",
    announcementDetail: "intelligence/announcements/{id}"
  },
  wallet: {
    walletInfo: "wallet/walletInfo", //获取钱包信息
    spot: "wallet/spot/accounts", //获取现货账户信息
    yield: "wallet/yield/accounts", //获取理财账户信息
    yieldFixedContracts: "fixed-deposit/contracts", // 获取定期持仓合约列表
    yieldFixedContractsDetail: "fixed-deposit/contracts/{id}", // 获取定期持仓合约详情
    yieldFixedCancelSubscription: "fixed-deposit/revoke", // 撤销申购
    yieldFixedTransferToFlexible: "/fixed-deposit/switchAutoFinancing", // 定期到期自动转活期开关
    yieldFixedSwitchAutoRenewal: "/fixed-deposit/switchAutoRenewal", // 定期续期开关
    strategies: "wallet/strategy/accounts", //获取策略账户信息
    // depositCoinList: "wallet/list/currencyConfig", //获取充值币种 （多链版本后暂时不用了 @后端）
    depositCoinList: "wallet/list/allAssetCurrencyConfig", //获取充值币种 （多链）
    depositAddress: "wallet/get/depositAddress", //获取充币地址 没有则新增
    showDepositAddress: "wallet/show/depositAddress", //获取已存在的充币地址
    otcDepositParam: "wallet/otcDepositParam", //获取法币充值参数
    applyOtcDeposit: "wallet/applyOtcDeposit", //法币充值
    withdrawLimit: "market/currencies/{currency}", //获取提币限额
    withdrawOtcOrder: "wallet/getOtcOrder", //获取法币提币交易订单id
    applyOtcWithdraw: "wallet/applyOtcWithdraw", //法币提币申请
    applyWithdraw: "wallet/applyWithdraw", //电子货币提币
    preWithdraw: "wallet/preWithdraw", //判断是否可以出金
    depositWithdrawHistory: "wallet/depositWithdrawHistory", //电子货币充提历史
    otcDepositWithdrawHistory: "wallet/otcDepositWithdrawHistory", //法币充提历史
    //circle
    bankAccount: "bank/circle/bankaccount", //获取银行账户信息
    bankList: "bank/circle/banks", //circle支持的银行列表
    addBankAccount: "bank/add/bankaccount", //添加银行账户
    refillBankAccount: "bank/resubmit/bankaccount", //重新添加银行账户
    circleDepositInfo: "wallet/circle/getDepositInstructions", //入金信息
    circleWithdraw: "wallet/circle/applyOtcWithdraw", //出金
    circleHistory: "wallet/circle/getOrders", //获取circle交易历史
    circleLimit: "wallet/circle/limit", //获取出金周限额、月限额
    amfIsEnough: "wallet/isenough/amf", //判断是否欠资产管理费
    amfDeduct: "wallet/deduct/amf", //手动扣除资产管理费
    assetCurrencyConfig: "wallet/list/assetCurrencyConfig" //获取币种的多链列表
  },
  trading: {
    acquireOrderId: "trade/orders/id", // 获取订单id
    orders: "trade/orders", //下单
    cancelOrder: "trade/orders/{orderId}", // 取消订单
    ordersOpen: "trade/orders", // 获取open order history
    ordersHistory: "trade/trades", // trade order history
    orderDetail: "trade/trades/{orderId}" // trade order history

    // marginBalance: 'trading/balance',
    // tradingInfo: 'trading/info',
    // changeMargin: 'trading/margin',
    // orderDetailFull: 'trading/orders/{orderId}/detail',
    // tradingTrades: '/trading/trades',
    // tradingTrade: 'trading/trades/{tradeId}',
    // zoneList: 'market/zones',
    // zoneDetail: 'market/zones/{key}/{currency}',
    // marginInterests: 'trading/margin/interests',
    // marginSettleOrders: 'trading/margin/settle/orders'
  },
  market: {
    // https://stackedit.io/app#providerId=googleDriveWorkspace&folderId=1TZZduUcByn8Wrn5gCDFBj_XTzMW8CWIF
    symbol: {
      list: "market/symbols", // 盘口数据列表
      offline: "market/symbols/offline", // 离线盘口数据列表
      names: `${devMarket}market/symbols/names` // 盘口名称列表, 暂时没有用
    },

    // https://stackedit.io/app#providerId=googleDriveWorkspace&folderId=1TZZduUcByn8Wrn5gCDFBj_XTzMW8CWIF
    currency: {
      coinList: `market/currencies`, // 所有币种、名称、icon等
      name: `${devMarket}market/currencies/names` //返回币种的名称列表，
    },

    // https://stackedit.io/app#providerId=googleDriveWorkspace&folderId=1TZZduUcByn8Wrn5gCDFBj_XTzMW8CWIF
    orderbooks: `market/orderbooks/{symbol}`
  },
  assets: {
    static: "asset/statics"
  },
  client: {
    customerList: "client/listCustomers",
    info: "client/info",
    commissionSummary: "client/commissionSummary",
    commissionList: "client/listCommissionDetail",
    AMFSummary: "customer/AMFSummary",
    AMFList: "customer/listAMF",
    agentLogin: "client/agent/login",
    operateCustomer: "client/operating",
    applyRole: "client/apply-role",
    managerLogin: "v1/manager/login",
    managerOperate: "/v1/manager/operating",
    changePassword: "/v1/manager/changePassword"
  }
};
export default api;
